import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const IconButton = props => {
  return (
    <Button disabled={!!props.disabled} onClick={props.onClick}>
      <Image src={props.src} alt={props.alt} />
    </Button>
  );
};

IconButton.propTypes = {
  alt: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
};

const Button = styled.button`
  margin: 2px;
  border-radius: 4px;
  outline: none;
`;

const Image = styled.img`
  margin: 6px;
  width: 20px;
  height: 20px;
`;
