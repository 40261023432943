import React
 from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  hasManageLink: PropTypes.bool,
  isActive: PropTypes.bool
};

function LineItemPlaceHolder({ hasManageLink }) {
  return (
    <li className="c-line-item c-line-item--placeholder">
      <div className="c-line-item__label">
        <h3>&nbsp;</h3>
        <p>&nbsp;</p>
      </div>
      {hasManageLink &&
        <div className="line-item__management">
          <b>&nbsp;</b>
        </div>}
    </li>
  );
}

LineItemPlaceHolder.propTypes = propTypes;

export default LineItemPlaceHolder;
