import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * for Routes
 * HOC that handles whether or not the user is allowed to see the page.
 * see: https://medium.com/better-programming/role-based-authorization-in-react-c70bb7641db4
 * also see: Can.js for Components
 *
 * @param {array} allowedRoles - roles that are allowed to see the page.
 * @returns {function(*)}
 * @constructor
 */
const Authorization = allowedRoles => WrappedComponent => {
  const WithAuthorization = props => {
    const role = props.auth.get('role');

    if (allowedRoles.includes(role)) {
      return <WrappedComponent {...props} />;
    } else {
      return <Redirect to={{ pathname: '/403' }} />;
    }
  };

  const mapState = state => ({ auth: state.authentication });
  const mapDispatch = {};

  return connect(mapState, mapDispatch)(WithAuthorization);
};

export default Authorization;
