import apiService from './api';
import {
  responseErrorMsgIs,
  responseStatusIs
} from '../utils/formErrorHelpers';

function deleteInvite(invitationId) {
  return apiService.delete(`/invitations/${invitationId}`);
}

function invite({ email, adminOrganizations, userOrganizations }) {
  return apiService
    .post('/invitations', { email, adminOrganizations, userOrganizations })
    .then((response) => {
      return {
        response: response.data
      };
    })
    .catch((err) => {
      let errorMessage = 'Please contact your administrator.';

      if (responseStatusIs(err.response, 422) &&
          responseErrorMsgIs(err.response, 'A user with that email already exists')) {
        errorMessage = `User ${email} already exists.`;
      }

      throw new Error(errorMessage);
    });
}

export default {
  deleteInvite,
  invite
};
