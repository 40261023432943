import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { wrapWithAuthenticatedApp } from '../../containers/AuthenticatedApp';
import Files from './Files';
import ProjectDetailHeader from './Header';
import ProjectInfo from '../../containers/ProjectInfo.jsx';

const propTypes = {
  actions: PropTypes.object,
  detailsError: PropTypes.object,
  details: ImmutablePropTypes.contains({
    boxId: PropTypes.string,
  }).isRequired,
  files: ImmutablePropTypes.list,
  filesError: PropTypes.object,
  isDetailsActive: PropTypes.bool,
  isFilesActive: PropTypes.bool,
  isRenderingActive: PropTypes.bool,
  renderingEmbedUrl: PropTypes.string,
  renderingError: PropTypes.object,
};

function ProjectDetail(props) {
  const {
    actions,
    details,
    detailsError,
    files,
    filesError,
    isDetailsActive,
    isFilesActive,
    isRenderingActive,
    renderingEmbedUrl,
    renderingError,
  } = props;
  return (
    <div className="l-project-detail">
      <ProjectInfo />

      {!detailsError && (
        <Files
          actions={actions}
          boxId={details.get('boxId')}
          error={filesError}
          files={files}
          isActive={isFilesActive}
        />
      )}
    </div>
  );
}

ProjectDetail.propTypes = propTypes;

export default wrapWithAuthenticatedApp({ headerContent: <ProjectDetailHeader /> })(ProjectDetail);
