import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parse } from 'query-string';

import * as authenticationActionCreators from '../actions/authentication';
import ConditionsOfAccess from '../components/ConditionsOfAccess';
import * as userDetailsActionCreators from '../actions/userDetails';

const propTypes = {
  actions: PropTypes.shape({
    authentication: PropTypes.shape({
      signOut: PropTypes.func,
    }).isRequired,
    userDetails: PropTypes.shape({
      acceptConditionsOfAccess: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    // nonexistent in ReactRouter v4+
    // query: PropTypes.shape({
    //   next: PropTypes.string,
    // }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  user: ImmutablePropTypes.contains({
    _id: PropTypes.string.isRequired,
    isAgreeingToConditionsOfAccess: PropTypes.bool,
  }).isRequired,
};

class ConnectedConditionsOfAccess extends Component {
  constructor(props) {
    super(props);

    this.acceptConditionsOfAccess = this.acceptConditionsOfAccess.bind(this);
  }

  acceptConditionsOfAccess() {
    const { from } = this.props.location?.state || { from: { pathname: '/' } };
    return this.props.actions.userDetails
      .acceptConditionsOfAccess(this.props.user.get('_id'))
      .then(() => this.props.history.replace(from));
  }

  render() {
    return (
      <ConditionsOfAccess
        acceptConditionsOfAccess={this.acceptConditionsOfAccess}
        cancelConditionsOfAccess={this.props.actions.authentication.signOut}
        cancelPath={{ pathname: '/sign-in', search: this.props.location.search }}
        isActive={this.props.user.get('isAgreeingToConditionsOfAccess')}
      />
    );
  }
}

ConnectedConditionsOfAccess.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    user: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      authentication: bindActionCreators(authenticationActionCreators, dispatch),
      userDetails: bindActionCreators(userDetailsActionCreators, dispatch),
    },
  };
}

export { ConnectedConditionsOfAccess as ConditionsOfAccess, mapDispatchToProps, mapStateToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedConditionsOfAccess);
