import React from 'react';

function PlaceholderProjectCard() {
  return (
    <div className="c-card c-card--placeholder">
      <div className="c-card__image" />
      <div className="c-card__metadata c-metadata c-metadata--centered">
        <h5>&nbsp;</h5>
        <h6>&nbsp;</h6>
        <p className="c-metadata__last-updated">&nbsp;</p>
      </div>
    </div>
  );
}

export default PlaceholderProjectCard;
