/**
 * Checks if a string is a valid URL
 * Reference: https://stackoverflow.com/a/5717133/8674390
 * @param {string} str
 */
export const isValidUrl = str => {
  console.log('evaluating url...');
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  console.log('url evaluated! -', pattern.test(str));
  return !!pattern.test(str);
};

/**
 * Evaluates if [str] is a valid URL
 * Reference: https://stackoverflow.com/a/43467144/8674390
 * Note: created this one because there are times where
 * [isValidUrl] would evaluate a string extremely slow
 * @param {string} str
 */
export const isValidHttpUrl = str => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

/**
 * Appends prefix "https" to raw link if necessary
 * @param {string} rawLink
 */
export const getLink = (rawLink = '') => {
  const [protocol] = rawLink.split(':');
  const hasProtocol = ['http', 'https'].includes(protocol);
  return hasProtocol ? rawLink : `https://${rawLink}`;
};
