import React from 'react';
import PropTypes from 'prop-types';
import { useScreenClass } from 'react-grid-system';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import ProtectedImage from '../../base/ProtectedImage';
import projectService from '../../../services/projects';

const Container = styled.div`
  margin-bottom: 2rem;
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: 475px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  padding-left: 10px;
  background-color: ${({ striped }) => (striped ? 'whitesmoke' : 'transparent')};
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    background-color: lightgrey;
  }
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
`;

const Title = styled.div`
  margin-right: 8px;
  flex-grow: 1;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Meta = styled.div`
  white-space: nowrap;
`;

const FileThumbnail = styled(ProtectedImage)`
  height: 50px;
  width: 50px;
  object-fit: cover;
  background-size: cover;
`;

/**
 * A simple table that shows a list of files used within a Project
 *
 * @param {Array} files - an array of files
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectFilesTable = ({ files }) => {
  const screenClass = useScreenClass();

  // same implementation in FileCard.jsx
  const getPreviewUrl = async boxId => {
    const previewWindow = window.open('', '_blank');
    previewWindow.location.href = await projectService.getProjectFilePreviewURL(boxId);
  };

  const isMobile = _.includes(['xs', 'sm'], screenClass);

  return (
    <Container>
      {files &&
        files.map((file, index) => {
          return (
            <FileItem
              key={index}
              striped={index % 2 === 1}
              onClick={async () => {
                await getPreviewUrl(file.get('boxId'));
              }}>
              <ThumbnailContainer>
                <FileThumbnail url={`/files/${file.get('boxId')}/image`} />
              </ThumbnailContainer>

              <Title>{file.get('name')}</Title>

              <Meta>
                {!isMobile && 'Last updated: '}
                {moment(file.get('lastUpdated')).format('MMM DD, YYYY')}
              </Meta>
            </FileItem>
          );
        })}
    </Container>
  );
};

ProjectFilesTable.propTypes = {
  files: PropTypes.array.isRequired,
};

export default ProjectFilesTable;
