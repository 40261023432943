import immutableTransform from 'redux-persist-transform-immutable';
import reduxThunkMiddleware from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import reducer from '../reducers';

const persistedReducer = persistReducer(
  {
    key: 'root',
    transforms: [immutableTransform()],
    whitelist: ['authentication'],
    storage,
  },
  reducer
);

const configureAppStore = preloadedState => {
  const middlewareEnhancer = applyMiddleware(reduxThunkMiddleware);
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);
  const store = createStore(persistedReducer, preloadedState, composedEnhancers);
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(reducer));
  }
  return store;
};

export const store = configureAppStore();
export const persistor = persistStore(store);
