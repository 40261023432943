import PropTypes from 'prop-types';
import React from 'react';

import Modal from './Modal';
import XIcon from './icons/XIcon';
import { BeatLoader } from 'react-spinners';
import { CenteredContent } from '../AccountPanel/styles';
import { ModalFooter } from './styled';

export const Dialog = props => {
  return (
    <Modal handleClose={props.handleClose}>
      <div className="c-island">
        <button className="c-island__exit" onClick={props.handleClose}>
          <XIcon />
        </button>

        <div className="c-island__header">
          <h3>{props.title}</h3>
          <p>{props.subtitle}</p>
          <p>{props.body}</p>
        </div>

        <div className="c-island__body">
          <ModalFooter className="c-button-row c-button-row--modal">
            <button
              className="c-button c-button--small c-button--hollow"
              onClick={props.handleCancel}>
              {props.cancelText}
            </button>
            <button
              className="c-button c-button--small"
              disabled={props.confirmDisabled}
              onClick={props.handleConfirm}>
              {props.confirmLoading ? (
                <CenteredContent>
                  <BeatLoader color="#fff" size={8} />
                </CenteredContent>
              ) : (
                props.confirmText
              )}
            </button>
          </ModalFooter>
        </div>
      </div>
    </Modal>
  );
};

Dialog.propTypes = {
  /** Header title of the modal */
  title: PropTypes.node.isRequired,
  /** Header subtitle of the modal */
  subtitle: PropTypes.node.isRequired,
  /** Header body text of the modal */
  body: PropTypes.node,
  /** Cancel button text, default: 'Cancel' */
  cancelText: PropTypes.string,
  /** Confirm button text, default: 'Confirm' */
  confirmText: PropTypes.string,
  /** Executed when user clicks 'cancel' button */
  handleCancel: PropTypes.func.isRequired,
  /** Executed when user exists the modal */
  handleClose: PropTypes.func.isRequired,
  /** Executed when user clicks 'confirm' button */
  handleConfirm: PropTypes.func.isRequired,
  /** Sets confirm button to disabled */
  confirmDisabled: PropTypes.bool,
  /** Sets confirm button to loading state */
  confirmLoading: PropTypes.bool,
};

Dialog.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};
