import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import accounts from './accounts';
import attributes from './attributes';
import authentication from './authentication';
import fileExclusionRules from './fileExclusionRules';
import filters from './filters';
import invitation from './invitation';
import projectDetails from './projectDetails';
import projectFlags from './projectFlags';
import projects from './projects';
import userDetails from './userDetails';
import users from './users';

export default combineReducers({
  accounts,
  attributes,
  authentication,
  fileExclusionRules,
  filters,
  invitation,
  form,
  projectDetails,
  projectFlags,
  projects,
  userDetails,
  users,
});
