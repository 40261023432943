import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import { ProjectInformationList } from '../components/ProjectDetail/ProjectInformationList';
import * as projectsActionCreators from '../actions/projects';

export const projectInformationFields = [
  { key: 'displayclassification', title: 'Display Classification' },
  { key: 'typeofdisplay', title: 'Display Type' },
  { key: 'productcategory', title: 'Product Category' },
  { key: 'retailchannel', title: 'Retail Channel' },
  { key: 'retailer', title: 'Retailer' },
  { dataTransform: budget => `$${budget}`, key: 'budget', title: 'Budget' },
  { key: 'duedate', title: 'Due Date' },
  { key: 'customerprojectnumber', title: 'Customer Project Number' },
];

const ConnectedForm = reduxForm({
  form: 'editProjectInformation',
  enableReinitialize: true,
})(ProjectInformationList);

class ConnectedComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: false,
    };

    this.updateProject = this.updateProject.bind(this);
  }

  updateProject(value) {
    const { details, actions } = this.props;
    const boxId = details.get('boxId');

    actions.projects.updateProjectById(boxId, value);
  }

  render() {
    return <ConnectedForm {...this.props} updateProject={this.updateProject} />;
  }
}

ConnectedComponent.propTypes = {
  details: ImmutablePropTypes.contains({
    boxId: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    projects: PropTypes.shape({
      updateProjectById: PropTypes.func.isRequired,
    }),
  }),
};

const _getInitialValues = details => {
  const values = {};

  projectInformationFields.forEach(field => {
    const value = details.getIn(['metadata', field.key]);

    if (field.key === 'duedate') {
      values[field.key] = value ? new Date(value) : value;
    } else {
      values[field.key] = value;
    }
  });

  return values;
};

const mapStateToProps = (state, ownProps) => ({
  initialValues: _getInitialValues(ownProps.details),
  role: state.authentication.get('role'),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    projects: bindActionCreators(projectsActionCreators, dispatch),
  },
});

export { ConnectedComponent as ProjectInformationList, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent);
