import PropTypes from 'prop-types';
import React from 'react';
import { PuffLoader } from 'react-spinners';
import { Link, Route, Switch } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { ruleTypes } from '../../../constants/rbac-rules';
import {
  customStyles,
  getActionCell,
  getColorCell,
  getEnabledCell,
  getTooltipCell,
} from './table-contents.js';
import { Can } from '../../base/Can';
import { Dialog } from '../../base/Dialog';
import { ButtonRow, Loader } from '../styles';
import { DataTableContainer } from './styles';
import PlusIcon from '../../base/icons/PlusIcon';
import AddProjectFlag from '../../../containers/ProjectFlags/AddProjectFlag';
import EditProjectFlag from '../../../containers/ProjectFlags/EditProjectFlag';
import Authorization from '../../base/Authorization';

const SuperAdmin = Authorization(['superAdmin']);

const parentRoute = '/admin/project-flags';

const ProjectFlags = props => {
  // create a library for orgs (e.g. orgsLib = { 1234ID: 'foo bar' }
  const orgsLib = React.useMemo(() => {
    const lib = { default: 'default' };
    props.orgs.forEach(org => {
      lib[org.get('id')] = org.get('name');
    });
    return lib;
  }, [props.orgs]);

  // compute flags with replaced customer (replace id with name)
  const data = React.useMemo(
    () => props.flags.toJS().map(flag => ({ ...flag, customer: orgsLib[flag.customer] })),
    [orgsLib, props.flags]
  );

  const columns = React.useMemo(() => {
    const isAdmin = props.role === 'superAdmin';
    const initialColumns = [
      {
        name: 'Customer',
        selector: 'customer',
        id: 'customer',
        cell: (row, index, _, id) => getTooltipCell(row.customer, index, id),
        sortable: true,
        minWidth: '150px',
        maxWidth: '200px',
      },
      {
        name: 'Code',
        selector: 'code',
        id: 'code',
        cell: (row, index, _, id) => getTooltipCell(row.code, index, id),
        sortable: true,
        minWidth: '150px',
        maxWidth: '240px',
      },
      {
        name: 'Label',
        selector: 'label',
        id: 'label',
        cell: (row, index, _, id) => getTooltipCell(row.label, index, id),
        minWidth: '150px',
        maxWidth: '240px',
      },
      { name: 'Color', cell: getColorCell, minWidth: '120px', maxWidth: '140px' },
      { name: 'Enabled', center: true, cell: getEnabledCell, width: '80px' },
      { name: 'Usage', center: true, selector: 'total', width: '80px' },
    ];
    const actionsColumn = {
      name: 'Actions',
      center: true,
      cell: flag => getActionCell(flag, props),
      width: '100px',
    };
    return isAdmin ? [...initialColumns, actionsColumn] : initialColumns;
  }, [props]);

  return (
    <>
      <div className="c-permissions-header">
        <h2 className="c-permissions-header__title">Project Flags</h2>
        <p className="c-permissions-header__description">
          Manage project flags. Below is a list of current project flags. Use the add new flag
          button to add a new project flag.
        </p>
      </div>

      <ButtonRow className="c-button-row c-button-row--notabtop" active={props.loading}>
        {props.loading && (
          <Loader>
            <PuffLoader color="#3d7cbf" size={24} />
            <div>Loading...</div>
          </Loader>
        )}
        <Can
          role={props.role}
          perform={ruleTypes.PROJECT_FLAGS__ADD}
          yes={() => (
            <Link to={`${parentRoute}/add`} className="c-button c-button--hollow c-button--small">
              <PlusIcon className="c-button__icon" />
              <span>Add New Flag</span>
            </Link>
          )}
        />
      </ButtonRow>

      <DataTableContainer>
        <DataTable noHeader columns={columns} data={data} customStyles={customStyles} />
      </DataTableContainer>

      {/* Confirm dialog for deleting a project flag */}
      {props.isDeleteDialogOpen && (
        <Dialog
          title="Delete a Project Flag"
          subtitle={
            <span>
              Project flag with code: <b>{props.projectFlagToDelete?.get('code')}</b>
            </span>
          }
          body="Are you sure you want to delete this project flag?"
          handleClose={props.cancelDelete}
          handleCancel={props.cancelDelete}
          handleConfirm={props.handleDelete}
          confirmDisabled={props.deleting}
          confirmLoading={props.deleting}
        />
      )}

      {/* Routes within /project-flags */}
      <Switch>
        <Route path={`${props.match.url}/add`} component={SuperAdmin(AddProjectFlag)} />
        <Route path={`${props.match.url}/:id/edit`} component={SuperAdmin(EditProjectFlag)} />
      </Switch>
    </>
  );
};

ProjectFlags.propTypes = {
  cancelDelete: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  flags: ImmutablePropTypes.list.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isDeleteDialogOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  projectFlagToDelete: PropTypes.object,
  role: PropTypes.string.isRequired,
};

export default ProjectFlags;
