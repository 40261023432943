import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { InnerCollapsible } from '../../components/Projects/CollapsibleFilters/InnerCollapsible';

const propTypes = {
  /** Whether or not the collapsible is open */
  open: PropTypes.bool.isRequired,
};

const ConnectedInnerCollapsible = props => {
  // { attributes: [], metadata: [], organization: [] }
  const fields = useSelector(({ filters }) => filters.get('fields'));

  // exclude 3 special filters
  const filters = useMemo(() => {
    const excluded = ['metadata.retailchannel', 'metadata.typeofdisplay', 'organization.name'];

    const all = [
      ...(fields.get('metadata') || []),
      ...(fields.get('attributes') || []),
      ...(fields.get('organization') || []),
    ];

    const filtered = all.filter(filter => {
      const code = filter.get('code');
      return !_.includes(excluded, code);
    });

    return filtered.sort((a, b) => {
      const labelA = a.get('label').toLowerCase();
      const labelB = b.get('label').toLowerCase();
      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
      return 0;
    });
  }, [fields]);

  return <InnerCollapsible {...props} filters={filters} />;
};

ConnectedInnerCollapsible.propTypes = propTypes;

export default ConnectedInnerCollapsible;
