import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';

import { FileExclusionForm } from '../../components/AccountPanel/FileExclusionRules/FileExclusionForm';
import { states } from '../../constants/route-states';
import service from '../../services/file-exclusion-rules';

const ConnectedEditFileExclusionForm = props => {
  const [currentRule, setCurrentRule] = useState({});
  const [loadingRule, setLoadingRule] = useState(false);
  const [patchingRule, setPatchingRule] = useState(false);

  // store selectors
  const users = useSelector(({ users }) => users.get('users'));
  const loadingUsers = useSelector(({ users }) => users.get('isActive'));

  const match = useRouteMatch();

  const userOptions = useMemo(() => {
    return users
      .filter(user => user.get('boxId'))
      .map(user => ({
        boxId: user.get('boxId'),
        email: user.get('email'),
        name: user.get('name'),
      }))
      .toJS();
  }, [users]);

  const usersToBan = useMemo(() => {
    const dictionary = {};
    currentRule.bannedUsers?.forEach(user => {
      if (!user.boxId) return;
      dictionary[user.boxId] = user;
    });
    return dictionary;
  }, [currentRule]);

  const getFileExclusionRule = useCallback(id => {
    setLoadingRule(true);
    service
      .getSingleFileExclusionRule(id)
      .then(fileExclusionRule => {
        setCurrentRule(fileExclusionRule);
        setLoadingRule(false);
      })
      .catch(error => {
        toast.error(error.message);
        setLoadingRule(false);
      });
  }, []);

  useEffect(() => {
    getFileExclusionRule(match.params.id);
  }, [getFileExclusionRule, match.params.id]);

  const handleSubmit = values => {
    setPatchingRule(true);
    const payload = {
      term: values.term.trim(),
      bannedUserIds: Object.keys(values.usersToBan),
    };
    service
      .updateFileExclusionRule(match.params.id, payload)
      .then(fileExclusionRule => {
        props.history.push('/admin/file-exclusion-rules', {
          status: states.TRIGGER_REFRESH,
        });
        toast.success('File exclusion rule updated');
        setPatchingRule(false);
      })
      .catch(error => {
        toast.error(error.message);
        setPatchingRule(false);
      });
  };

  return (
    <FileExclusionForm
      title="Edit a File Exclusion Rule"
      subtitle="Set a term to be identified in the document titles and select users who should not see documents with that term"
      submitText="Save"
      initialValues={{ term: currentRule.term, usersToBan }}
      handleSubmit={handleSubmit}
      loading={loadingUsers || loadingRule || patchingRule}
      userOptions={userOptions}
    />
  );
};

export default ConnectedEditFileExclusionForm;
