import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse } from 'query-string';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { CollapsibleFilters } from '../../components/Projects/CollapsibleFilters';
import { applyFilters, clearFilters } from '../../utils/filterHelpers';

const propTypes = {
  /** Previous search params that came from the store */
  previousSearch: PropTypes.string.isRequired,
  /** If currently calling API for filter */
  isActive: PropTypes.bool.isRequired,
  /** The error thrown by some request */
  error: PropTypes.any.isRequired,
  /** The total number of projects found by current filter */
  totalProjects: PropTypes.number.isRequired,
  /** The location object from react-router-dom */
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
};

const ConnectedCollapsibleFilters = props => {
  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { previousSearch } = props;
  const params = parse(location.search);
  const previousParams = parse(previousSearch);

  const toggleAdvancedFilters = () => {
    setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen);
  };

  const clear = () => clearFilters({ location, history, dispatch, previousSearch });
  const apply = () => applyFilters({ location, dispatch });

  const isClearDisabled = _.isEmpty(params) && _.isEmpty(previousParams);
  const isApplyDisabled = _.isEqual(params, previousParams);

  return (
    <CollapsibleFilters
      {...props}
      isAdvancedFiltersOpen={isAdvancedFiltersOpen}
      isClearDisabled={isClearDisabled}
      isApplyDisabled={isApplyDisabled}
      toggleAdvancedFilters={toggleAdvancedFilters}
      clear={clear}
      apply={apply}
    />
  );
};

ConnectedCollapsibleFilters.propTypes = propTypes;

export default ConnectedCollapsibleFilters;
