import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';

import ProjectsPaginationItem from './ProjectsPaginationItem';

const propTypes = {
  children: PropTypes.node,
};

function ProjectsPagination(props) {
  return (
    <div className="c-projects-pagination">
      {Children.map(props.children, child => {
        return cloneElement(child);
      })}
    </div>
  );
}

ProjectsPagination.propTypes = propTypes;

export default createUltimatePagination({
  itemTypeToComponent: {
    [ITEM_TYPES.ELLIPSIS]: ProjectsPaginationItem,
    [ITEM_TYPES.FIRST_PAGE_LINK]: () => null,
    [ITEM_TYPES.LAST_PAGE_LINK]: () => null,
    [ITEM_TYPES.NEXT_PAGE_LINK]: ProjectsPaginationItem,
    [ITEM_TYPES.PAGE]: ProjectsPaginationItem,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: ProjectsPaginationItem,
  },
  WrapperComponent: withRouter(ProjectsPagination),
});
