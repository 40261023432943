import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';

import ProjectFlagsSummary from '../../../containers/ProjectFlags/ProjectFlagsSummary';
// import ProjectsTable from '../../../containers/ProjectsTable';

const CollapsibleOverview = props => {
  const [open, setOpen] = useState(false);

  const togglePanel = () => setOpen(!open);

  return (
    <Container>
      <Collapsible
        trigger={<Trigger>Overview</Trigger>}
        tabIndex={0}
        transitionTime={100}
        open={true}
        onTriggerOpening={togglePanel}
        onTriggerClosing={togglePanel}>
        <ContentContainer>
          <ProjectFlagsSummary />
          {/*<ProjectsTable />*/}
        </ContentContainer>
      </Collapsible>
    </Container>
  );
};

const Trigger = styled.div`
  font-size: 16px;
`;

const Container = styled.div`
  margin-bottom: 1rem;
  margin-left: 24px;
  margin-right: 24px;

  @media (min-width: 475px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .Collapsible__trigger {
    background-color: #fff;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default CollapsibleOverview;
