import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import styled from 'styled-components';

import FilterTags from '../../../containers/CollapsibleFilters/FilterTags';
import InnerCollapsible from '../../../containers/CollapsibleFilters/InnerCollapsible';
import MainFilters from '../../../containers/CollapsibleFilters/MainFilters';
import { colors } from '../../base/styles';
import ProjectsInfo from './ProjectsInfo';

const propTypes = {
  /** Function to apply filters */
  apply: PropTypes.func.isRequired,
  /** Function to clear filters */
  clear: PropTypes.func.isRequired,
  /** The error thrown by some request */
  error: PropTypes.any,
  /** List of filters currently available */
  filters: PropTypes.array.isRequired,
  /** If currently calling API for filter */
  isActive: PropTypes.bool.isRequired,
  /** If the advanced filters collapsible panel is open/close */
  isAdvancedFiltersOpen: PropTypes.bool.isRequired,
  /** If the apply button is currently disabled or not  */
  isApplyDisabled: PropTypes.bool.isRequired,
  /** If the clear button is currently disabled or not */
  isClearDisabled: PropTypes.bool.isRequired,
  /** The location object from react-router-dom */
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
  /** Toggle collapsible filter panel visiblity */
  toggleAdvancedFilters: PropTypes.func.isRequired,
  /** The total number of projects found by current filter */
  totalProjects: PropTypes.number.isRequired,
};

/**
 * Presentational component for collapsible filter panel
 * @param {*} props
 */
export const CollapsibleFilters = props => {
  return (
    <ProjectsHeader>
      <Bordered>
        <Container fluid style={styles.panelHeader}>
          <Row align="center" gutterWidth={0}>
            {/* project count, project found */}
            <Col md={2}>
              <ProjectsInfo
                isVisible={!(props.isActive || props.error)}
                projectCount={props.totalProjects}
                searchQuery={parse(props.location.search).q}
                loading={props.isActive}
              />
            </Col>
            {/* the 3 main filters */}
            <Col md={8}>
              <MainFilters />
            </Col>
            {/* collapse/retract button */}
            <CollapsibleButtonContainer md={2}>
              <CollapsibleButton onClick={props.toggleAdvancedFilters}>
                {props.isAdvancedFiltersOpen ? <BiCaretUp size={17} /> : <BiCaretDown size={17} />}
              </CollapsibleButton>
            </CollapsibleButtonContainer>
          </Row>
        </Container>
      </Bordered>
      {/* collapsible panel for adv filters */}
      <InnerCollapsible open={props.isAdvancedFiltersOpen} />
      {/* list of filter tags */}
      <TagContainer>
        <Row justify="space-between">
          <Col>
            <FilterTags />
          </Col>
          <Col sm="content">
            <FilterButtons>
              <Button
                className="c-button c-button--hollow c-button--block"
                onClick={props.clear}
                disabled={props.isClearDisabled}>
                Clear
              </Button>
              <PrimaryButton
                className="c-button c-button--small c-button--block"
                onClick={props.apply}
                disabled={props.isApplyDisabled}>
                Apply
              </PrimaryButton>
            </FilterButtons>
          </Col>
        </Row>
      </TagContainer>
    </ProjectsHeader>
  );
};

CollapsibleFilters.propTypes = propTypes;

const styles = {
  panelHeader: { padding: '0px 7px 0 10px' },
};

const ProjectsHeader = styled.div`
  margin-bottom: 0.65rem;
  margin-left: 24px;
  margin-right: 24px;

  @media (min-width: 475px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  @media (max-width: 767.98px) {
    margin-bottom: 1.25rem;
  }
`;

const Bordered = styled.div`
  border: 1px solid lightgrey;
`;

const CollapsibleButtonContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767.98px) {
    justify-content: center;
  }
`;

const CollapsibleButton = styled.button`
  all: unset;
  cursor: pointer;
  padding: 10px 0;

  &:hover {
    color: ${colors.accent};
  }
`;

const TagContainer = styled.div`
  margin-top: 1rem;

  @media (max-width: 577.98px) {
    padding-bottom: 2px;
  }
`;

const FilterButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 0.5rem;

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }

  @media (max-width: 577.98px) {
    margin-top: 0.65rem;
  }
`;

const Button = styled.div`
  outline: none;
  padding: 8px;
  font-size: 12px;
`;

const PrimaryButton = styled.div`
  outline: none;
  padding: 9px;
  font-size: 12px;
`;
