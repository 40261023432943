import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutableProptypes from 'react-immutable-proptypes';
import { Link, Route, Switch } from 'react-router-dom';

import LineItemError from './LineItemError.jsx';
import LineItemPlaceholder from './LineItemPlaceholder';
import PlusIcon from '../../base/icons/PlusIcon';
import UserLineItem from './UserLineItem';
import Invitation from '../../../containers/Invitation';
import DeleteUser from '../../../containers/DeleteUser';
import ManageUserAccounts from '../../../containers/ManageUserAccounts';
import { Can } from '../../base/Can';
import { ruleTypes } from '../../../constants/rbac-rules.js';

const propTypes = {
  actions: PropTypes.shape({
    userDetails: PropTypes.shape({
      setSelectedUser: PropTypes.func.isRequired,
    }),
  }),
  currentUserRole: PropTypes.string,
  isActive: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  users: ImmutableProptypes.list.isRequired,
};

function Users(props) {
  const {
    actions: {
      userDetails: { setSelectedUser },
    },
    currentUserRole,
    isActive,
    match,
    location: { pathname },
    history,
    users,
  } = props;

  return (
    <div>
      <Can
        role={currentUserRole}
        perform={ruleTypes.USERS__ADD}
        yes={() => (
          <div className="c-button-row">
            <Link
              to="/admin/permissions/users/invitation"
              className="c-button c-button--hollow c-button--small">
              <PlusIcon className="c-button__icon" />
              <span>Add New User</span>
            </Link>
          </div>
        )}
      />

      <ul>
        {(() => {
          if (isActive) {
            return _.times(12, i => <LineItemPlaceholder hasManageLink key={i} />);
          } else if (!users.size) {
            return (
              <LineItemError subtitle="Have you tried adding a new user?" title="No one here" />
            );
          }

          return (
            <ul>
              {users.map((user, i) => {
                return (
                  <UserLineItem
                    currentUserRole={currentUserRole}
                    key={i}
                    location={pathname}
                    pushRoute={history.push}
                    setSelectedUser={setSelectedUser}
                    user={user}
                  />
                );
              })}
            </ul>
          );
        })()}
      </ul>

      <Switch>
        <Route
          exact
          path={`${match.url}/invitation`}
          render={props => <Invitation {...props} label="User" />}
        />
        <Route
          path={`${match.url}/invitation/:id/delete`}
          render={props => <DeleteUser {...props} label="User" />}
        />
        <Route
          exact
          path={`${match.url}/user/:id`}
          render={props => <ManageUserAccounts {...props} label="User" />}
        />
        <Route
          path={`${match.url}/user/:id/delete`}
          render={props => <DeleteUser {...props} label="User" />}
        />
      </Switch>

      {/* old react-router config */}
      {/*{children &&*/}
      {/*  Children.map(children, child => {*/}
      {/*    return cloneElement(child, { label: 'User' });*/}
      {/*  })}*/}
    </div>
  );
}

Users.propTypes = propTypes;

export default Users;
