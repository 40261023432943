import React from 'react';
import styled from 'styled-components';

export const CheckboxItem = ({ name, checked, label, count, onChange }) => {
  return (
    <div className="c-projects-filters__item">
      <input
        id={name}
        type="checkbox"
        className="c-projects-filters__checkbox"
        checked={checked}
        value={checked}
        onChange={onChange}
      />
      <label htmlFor={name} className="c-projects-filters__label">
        {label} {count && <Count>({count})</Count>}
      </label>
    </div>
  );
};

const Count = styled.span`
  font-weight: normal;
  color: #71848f;
`;
