import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ImmutableProptypes from 'react-immutable-proptypes';
import OrgCheckboxRow from '../OrgCheckboxRow';
import ManageUserAccountsError from './ManageUserAccountsError';
import MessageCard from '../../../base/MessageCard';
import Modal from '../../../base/Modal';
import XIcon from '../../../base/icons/XIcon';
import { ModalFooter } from '../../../base/styled';

const propTypes = {
  actions: PropTypes.shape({
    userDetails: PropTypes.shape({
      addAdminOrg: PropTypes.func.isRequired,
      addAllAdminOrgs: PropTypes.func.isRequired,
      addAllUserOrgs: PropTypes.func.isRequired,
      addUserOrg: PropTypes.func.isRequired,
      clearSelectedUser: PropTypes.func.isRequired,
      removeAdminOrg: PropTypes.func.isRequired,
      removeAllAdminOrgs: PropTypes.func.isRequired,
      removeAllUserOrgs: PropTypes.func.isRequired,
      removeUserOrg: PropTypes.func.isRequired,
      setSelectedUser: PropTypes.func.isRequired,
      updateUserOrganizations: PropTypes.func.isRequired,
    }),
  }),
  adminOrganizations: ImmutableProptypes.list,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  organizations: ImmutableProptypes.list,
  role: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  selectedUser: ImmutableProptypes.map.isRequired,
};

function createManagedOrgs(role, organizations, adminOrganizations) {
  return role === 'superAdmin' ? organizations : adminOrganizations;
}

function getParentRoute(pathname) {
  const stringLoc = pathname.search('/user/');
  return pathname.slice(0, stringLoc);
}

class ManageUserAccounts extends Component {
  constructor(props) {
    super(props);

    this.handleAdminSelectAll = this.handleAdminSelectAll.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserSelectAll = this.handleUserSelectAll.bind(this);

    this.state = {
      parentRoute: getParentRoute(props.location.pathname),
      updateFinished: false,
    };
  }

  componentWillUnmount() {
    this.props.actions.userDetails.clearSelectedUser();
  }

  handleAdminSelectAll(allSelected, managedOrgs) {
    return () => {
      allSelected
        ? this.props.actions.userDetails.removeAllAdminOrgs(managedOrgs)
        : this.props.actions.userDetails.addAllAdminOrgs(managedOrgs);
    };
  }

  handleClose() {
    const { location, history } = this.props;
    if (location.pathname.indexOf('/user/') > -1) {
      history.push(this.state.parentRoute);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const user = this.props.selectedUser.toJS();

    return this.props.actions.userDetails
      .updateUserOrganizations(user)
      .then(() => {
        this.setState({ updateFinished: true });
      })
      .catch(error => {
        this.setState({
          error,
        });
      });
  }

  handleUserSelectAll(allSelected, managedOrgs) {
    return () => {
      allSelected
        ? this.props.actions.userDetails.removeAllUserOrgs()
        : this.props.actions.userDetails.addAllUserOrgs(managedOrgs);
    };
  }

  render() {
    if (this.state.error) {
      return (
        <ManageUserAccountsError cancelLocation={this.state.parentRoute} error={this.state.error} />
      );
    }

    const {
      actions: {
        userDetails: { addAdminOrg, addUserOrg, removeAdminOrg, removeUserOrg },
      },
      adminOrganizations,
      organizations,
      role,
      selectedUser,
    } = this.props;

    const adminOrgs = selectedUser.get('adminOrganizations');
    const userOrgs = selectedUser.get('userOrganizations');

    const adminProps = {
      addOrg: addAdminOrg,
      removeOrg: removeAdminOrg,
      orgs: adminOrgs,
    };
    const userProps = {
      addOrg: addUserOrg,
      removeOrg: removeUserOrg,
      orgs: userOrgs,
    };

    const managedOrgs = createManagedOrgs(role, organizations, adminOrganizations);
    const allAdminSelected = adminOrgs.size === managedOrgs.size;
    const allUserSelected = userOrgs.size === managedOrgs.size;

    return (
      <Modal handleClose={this.handleClose}>
        <div className="c-island">
          <Link to={this.state.parentRoute} className="c-island__exit">
            <XIcon />
          </Link>

          <div className="c-island__header">
            <h3 id="invite-title">Manage User Organizations</h3>
            <span id="invite-subtitle">
              Access for: <b>{selectedUser.get('email')}</b>
            </span>
          </div>

          <div className="c-island__body">
            {this.state.updateFinished ? (
              <MessageCard>
                This user has been updated successfully and the changes are now being made on Box.
                This process may take a few minutes.
                <hr />
                <Link to={this.state.parentRoute} onClick={this.handleClose}>
                  Go Back to Permissions
                </Link>
              </MessageCard>
            ) : (
              <form className="c-form c-form--with-sticky-footer" onSubmit={this.handleSubmit}>
                <div className="c-checkbox-row c-checkbox-row--heading">
                  <label className="c-checkbox-row__label">Organizations</label>
                  <div className="c-checkbox-row__check">Admin</div>
                  <div className="c-checkbox-row__check">User</div>
                </div>

                <div className="c-checkbox-row c-checkbox-row--fixed">
                  <label className="c-checkbox-row__label">All</label>
                  <div className="c-checkbox-row__check">
                    <input
                      checked={allAdminSelected}
                      onChange={this.handleAdminSelectAll(allAdminSelected, managedOrgs)}
                      type="checkbox"
                    />
                  </div>

                  <div className="c-checkbox-row__check">
                    <input
                      checked={allUserSelected}
                      onChange={this.handleUserSelectAll(allUserSelected, managedOrgs)}
                      type="checkbox"
                    />
                  </div>
                </div>

                <div className="c-modal__manage-overflow">
                  {selectedUser.get('isActive') ? (
                    <div className="c-checkbox-row c-checkbox-row--placeholder" />
                  ) : (
                    <ul>
                      {managedOrgs.map((org, i) => {
                        return (
                          <li key={i}>
                            <OrgCheckboxRow org={org} admin={adminProps} user={userProps} />
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>

                <ModalFooter className="c-button-row c-button-row--modal">
                  <Link
                    to={this.state.parentRoute}
                    className="c-button c-button--small c-button--hollow">
                    Cancel
                  </Link>
                  <button
                    className="c-button c-button--small"
                    disabled={!selectedUser.get('changed')}
                    type="submit">
                    Update User
                  </button>
                </ModalFooter>
              </form>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

ManageUserAccounts.propTypes = propTypes;

export default ManageUserAccounts;
