import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectInfo } from '../components/ProjectDetail/ProjectInfo.jsx';
import { getAttributes } from '../actions/attributes';
import { updateProjectById } from '../actions/projects';
import { getProjectFlags } from '../actions/projectFlags';

const ConnectedProjectInfo = props => {
  const details = useSelector(({ projectDetails }) => projectDetails.getIn(['details', 'values']));
  const rendering = useSelector(({ projectDetails }) => projectDetails.get('rendering'));
  const role = useSelector(({ authentication }) => authentication.get('role'));
  const isLoadingAttributes = useSelector(({ attributes }) => attributes.get('isActive'));
  const isLoadingDetails = useSelector(({ projectDetails: d }) => d.getIn(['details', 'isActive']));

  const dispatch = useDispatch();

  // load attributes
  useEffect(() => {
    dispatch(getAttributes());
    dispatch(getProjectFlags());
  }, [dispatch]);

  function updateProject(id, values) {
    const existingTitle = details.getIn(['metadata', 'projectname']);
    const currentTitle = values.metadata?.projectname;
    const shouldUpdate = existingTitle !== currentTitle;

    if (shouldUpdate) {
      dispatch(updateProjectById(id, values, { confirm: true }));
    }
  }

  return (
    <ProjectInfo
      role={role}
      rendering={rendering}
      details={details}
      loading={isLoadingDetails || isLoadingAttributes}
      updateProject={updateProject}
    />
  );
};

export default ConnectedProjectInfo;
