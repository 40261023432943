import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ModalFooter } from '../../../base/styled';

const propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  error: PropTypes.string,
};

const defaultProps = {
  error: '',
};

function DeleteError({ cancelLocation, error }) {
  return (
    <div>
      <div className="h-pad h-center">
        <h3>{error.length && error}</h3>
        <h4 className="h-pad">There has been an error deleting this user.</h4>
      </div>
      <ModalFooter className="c-button-row c-button-row--modal">
        <Link to={cancelLocation} className="c-button c-button--small c-button">
          Close
        </Link>
      </ModalFooter>
    </div>
  );
}

DeleteError.propTypes = propTypes;
DeleteError.defaultProps = defaultProps;

export default DeleteError;
