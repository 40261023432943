import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { setErrors } from '../../../../utils/formErrorHelpers';
import formValidationService from '../../../../services/formValidation';
import TextInput from '../../../base/TextInput';
import FlashErrors from '../../../base/FlashErrors';
import { ModalFooter } from '../../../base/styled';

function validateFields(values) {
  return new formValidationService.Validation().email(values.email).getErrors();
}

const propTypes = {
  cancelLocation: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

function InvitationEmail(props) {
  const { handleSubmit, cancelLocation } = props;

  return (
    <form className="c-form c-form--with-sticky-footer" onSubmit={handleSubmit}>
      <div className="c-well">
        <Field component={TextInput} label="Email" name="email" />
        <FlashErrors errors={setErrors(props)} />
      </div>
      <ModalFooter className="c-button-row c-button-row--modal">
        <Link to={cancelLocation} className="c-button c-button--small c-button--hollow">
          Cancel
        </Link>
        <button className="c-button c-button--small" disabled={props.isActive} type="submit">
          Next
        </button>
      </ModalFooter>
    </form>
  );
}

InvitationEmail.propTypes = propTypes;

export default reduxForm({
  form: 'newUserEmail',
  validate: validateFields,
})(InvitationEmail);
