import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  projectFlags: new Immutable.List(),
});

function projectFlags(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_PROJECT_FLAGS_START:
      return state.withMutations(map => {
        map.delete('error');
        map.set('isActive', true);
      });

    case actionTypes.GET_PROJECT_FLAGS_SUCCESS:
      return state.withMutations(map => {
        map.set('isActive', false);
        map.set('projectFlags', Immutable.fromJS(action.payload.projectFlags));
      });

    case actionTypes.GET_PROJECT_FLAGS_FAILURE:
      return state.withMutations(map => {
        map.set('error', action.payload);
        map.set('isActive', false);
      });

    case actionTypes.SIGN_IN_START:
    case actionTypes.SIGN_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default projectFlags;
