import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #d8d8d8;
  height: 30px;
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  position: absolute;
  left: 0;

  @media (max-width: 575.98px) {
    position: unset;
    left: unset;
    margin-top: 1rem;
  }
`;

const SwitchButton = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  border-radius: 3px;
  height: 30px;
  background-color: ${({ active }) => (active ? 'white' : 'transparent')};
  box-shadow: ${({ active }) => (active ? '0px 0px 5px 2px rgba(216, 216, 216, 1)' : '')};
  &:hover {
    cursor: pointer;
  }
`;

/**
 * A simple custom switch that accepts multiple items.
 *
 * @param {Array} items - and array of objects that have two properties:
 * [label] and [callback]. The label is displayed as the button title,
 * while the callback function is called whenever an item becomes active
 * @returns {JSX.Element}
 * @constructor
 */
const Switch = ({ items }) => {
  // ephemeral state for showing UI/UX which is the active selection
  const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <Container>
      {items &&
        items.map((item, index) => {
          return (
            <SwitchButton
              key={`switch-key-${index}`}
              active={activeIndex === index}
              onClick={() => {
                setActiveIndex(index);
                item.callback();
              }}>
              {item.label}
            </SwitchButton>
          );
        })}
    </Container>
  );
};

Switch.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Switch;
