import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { regex } from '../../../constants/regex';
import { getErrors, isAnyTouched } from '../../../utils/formikHelpers';
import { CenteredContent } from '../styles';
import { ColorPicker } from '../../base/ColorPicker';
import { VanillaDropdown } from '../../base/fields/VanilaDropdown';
import XIcon from '../../base/icons/XIcon';
import Modal from '../../base/Modal';
import TextInput from '../../base/TextInput';
import FlashErrors from '../../base/FlashErrors';
import { ModalFooter } from '../../base/styled';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  organizations: ImmutablePropTypes.list.isRequired,
  submitText: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['add', 'edit']).isRequired,
};

const ProjectFlagValidationSchema = Yup.object().shape({
  customer: Yup.string().required(),
  code: Yup.string()
    .trim()
    .max(50, 'Code must not exceed 50 characters')
    .matches(regex.CODE, 'Code must be uppercase in snake case (e.g. - HELLO_WORLD)')
    .required('Code is required'),
  label: Yup.string()
    .trim()
    .max(50, 'Label must not exceed 50 characters')
    .required('Label is required'),
  colorCode: Yup.string()
    .trim()
    .length(7, 'Color hex must be 7 characters, including # prefix')
    .required('Color is required'),
});

const ProjectFlagForm = props => {
  const parentRoute = '/admin/project-flags';

  const organizations = useMemo(() => {
    return props.organizations?.map(org => ({
      label: org.get('name'),
      value: org.get('id'),
    }));
  }, [props.organizations]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.initialValues,
    onSubmit: props.handleSubmit,
    validationSchema: ProjectFlagValidationSchema,
  });

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="c-island">
        <Link to={parentRoute} className="c-island__exit">
          <XIcon />
        </Link>

        <div className="c-island__header">
          <h3 id="invite-title">{props.title}</h3>
          <span id="invite-subtitle">{props.subtitle}</span>
        </div>

        <div className="c-island__body">
          <form className="c-form c-form--with-sticky-footer" onSubmit={formik.handleSubmit}>
            <div className="c-well">
              <div className="c-form__group">
                <label className="c-label"> Customer</label>
                <StyledDropdown
                  mode="unset"
                  disabled={props.type === 'edit' || props.loading}
                  options={organizations.toJS()}
                  value={formik.values.customer}
                  onChangeText={organizationId => {
                    formik.setFieldValue('customer', organizationId);
                  }}
                />
              </div>

              {[
                { disabled: props.type === 'edit', name: 'code', label: 'Code' },
                { disabled: false, name: 'label', label: 'Label' },
              ].map(({ name, label, disabled }) => (
                <TextInput
                  key={name}
                  label={label}
                  disabled={disabled || props.loading}
                  meta={{ error: formik.errors[name], touched: formik.touched[name] }}
                  input={{
                    name,
                    value: formik.values[name],
                    onChange: formik.handleChange,
                  }}
                />
              ))}

              <PickerContainer>
                <TextInput
                  key="colorCode"
                  label="Color"
                  disabled={props.loading}
                  style={{ width: '100%' }}
                  meta={{ error: formik.errors.colorCode, touched: formik.touched.colorCode }}
                  input={{
                    name: 'colorCode',
                    value: formik.values.colorCode,
                    onChange: formik.handleChange,
                  }}
                />
                <ColorPicker
                  style={{ marginLeft: 8, marginBottom: '1rem' }}
                  value={formik.values.colorCode}
                  disabled={props.loading}
                  handleChangeComplete={color => {
                    formik.setFieldValue('colorCode', color.hex.toUpperCase());
                  }}
                />
              </PickerContainer>

              <div className="c-attrib-checkbox-row">
                <input
                  className="c-attrib-checkbox-row__check"
                  id="enabled"
                  type="checkbox"
                  name="isEnabled"
                  checked={formik.values.isEnabled}
                  onChange={formik.handleChange}
                />
                <label className="c-label c-attrib-checkbox-row__label" htmlFor="enabled">
                  Do you want to enable this project flag?
                </label>
              </div>

              {/* form errors */}
              <FlashErrors errors={isAnyTouched(formik.touched) ? getErrors(formik.errors) : []} />
            </div>

            <ModalFooter className="c-button-row c-button-row--modal">
              <Link to={parentRoute} className="c-button c-button--small c-button--hollow">
                Cancel
              </Link>
              <button
                className="c-button c-button--small"
                type="submit"
                disabled={
                  // disable if mode is `edit` and there are no initial values
                  (props.type === 'edit' && !props.initialValues.code) || props.loading
                }>
                {props.loading ? (
                  <CenteredContent>
                    <BeatLoader color="#fff" size={8} />
                  </CenteredContent>
                ) : (
                  props.submitText
                )}
              </button>
            </ModalFooter>
          </form>
        </div>
      </div>
    </Modal>
  );
};

ProjectFlagForm.propTypes = propTypes;

const PickerContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledDropdown = styled(VanillaDropdown)`
  font-size: 1rem;
  .Dropdown-control {
    padding: 16px;
    border-radius: 5px;
  }
`;

export default ProjectFlagForm;
