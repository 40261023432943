import { toast } from 'react-toastify';

import actionTypes from '../constants/actionTypes';
import filterService from '../services/filters';
import { getProjectsFailure, getProjectsStart, getProjectsSuccess } from './projects';

const _filterStart = () => ({
  type: actionTypes.GET_FILTERS_START,
});
const _filterSuccess = fields => ({
  type: actionTypes.GET_FILTERS_SUCCESS,
  payload: { fields },
});
const _filterFailure = error => ({
  type: actionTypes.GET_FILTERS_FAILURE,
  payload: error,
  error: true,
});

function filter(payload, { onlyGetFilters } = { onlyGetFilters: false }) {
  return function (dispatch) {
    dispatch(_filterStart());
    if (!onlyGetFilters) dispatch(getProjectsStart());

    return filterService
      .filter(payload)
      .then(result => {
        // { filters: { attributes: [], metadata: [], organization: [] } }
        const { filters, projects, total, totalPages, page, limit } = result;
        dispatch(_filterSuccess(filters));

        if (!onlyGetFilters) {
          const args = { page, pages: totalPages, projects, total };
          dispatch(getProjectsSuccess(args));
        }
      })
      .catch(error => {
        toast.error(error.message);

        dispatch(_filterFailure(error));
        if (!onlyGetFilters) dispatch(getProjectsFailure(error));
      });
  };
}

function setAppliedFilters(searchParamsString) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.SET_APPLIED_FILTERS_SUCCESS,
      payload: { searchParamsString },
    });
  };
}

export { filter, setAppliedFilters };
