import apiService from './api';

function deleteUser(_id) {
  return apiService.delete(`accounts/users/${_id}`);
}

function findUser(params) {
  return apiService
    .get('/accounts/user', { params })
    .then(({ data }) => {
      const adminOrganizations = data.user.adminOrganizations || [];
      const userOrganizations = data.user.userOrganizations || [];

      return {
        ...data.user,
        adminOrganizations: adminOrganizations.map((org) => org.id),
        userOrganizations: userOrganizations.map((org) => org.id)
      };
    });
}

function findUserByEmail(email) {
  return findUser({ email });
}

function findUserById(_id) {
  return findUser({ _id });
}

function getCurrentUser() {
  return findUser({ _id: 'current' });
}

function getUsers() {
  return apiService
    .get('/accounts/users')
    .then(({ data }) => data.users);
}

function updateUser(update) {
  return apiService
    .patch(`/accounts/users/${update._id}`, update)
    .then(({ data }) => {
      return { ...data.user };
    })
    .catch((err) => {
      const errorMessage = (err && err.message) || 'Please contact your administrator.';
      throw new Error(errorMessage);
    });
}

export default {
  deleteUser,
  findUserByEmail,
  findUserById,
  getCurrentUser,
  getUsers,
  updateUser
};
