import keyMirror from 'keymirror';

export default keyMirror({
  GET_PROJECT_DETAILS_FAILURE: null,
  GET_PROJECT_DETAILS_START: null,
  GET_PROJECT_DETAILS_SUCCESS: null,
  GET_PROJECT_FILES_FAILURE: null,
  GET_PROJECT_FILES_START: null,
  GET_PROJECT_FILES_SUCCESS: null,
  GET_PROJECT_FILTERS_START: null,
  GET_PROJECT_FILTERS_SUCCESS: null,
  GET_PROJECT_RENDERING_FAILURE: null,
  GET_PROJECT_RENDERING_START: null,
  GET_PROJECT_RENDERING_SUCCESS: null,
  GET_PROJECTS_FAILURE: null,
  GET_PROJECTS_START: null,
  GET_PROJECTS_SUCCESS: null,
  RESET_PROJECT_DETAILS: null,
  RESET_PROJECT_FILES: null,
  RESET_PROJECT_RENDERING: null,
  UPDATE_PROJECT_START: null,
  UPDATE_PROJECT_SUCCESS: null,
  UPDATE_PROJECT__FAILURE: null,

  GET_ORGANIZATIONS_FAILURE: null,
  GET_ORGANIZATIONS_START: null,
  GET_ORGANIZATIONS_SUCCESS: null,

  CLEAR_USERS: null,
  GET_USERS_FAILURE: null,
  GET_USERS_START: null,
  GET_USERS_SUCCESS: null,

  ADD_ALL_INVITE_ADMIN_ORGS: null,
  ADD_ALL_INVITE_USER_ORGS: null,
  ADD_INVITE_ADMIN_ORG: null,
  ADD_INVITE_USER_ORG: null,
  CLEAR_INVITE: null,
  DELETE_INVITE_FAILURE: null,
  DELETE_INVITE_START: null,
  DELETE_INVITE_SUCCESS: null,
  REMOVE_ALL_INVITE_ADMIN_ORGS: null,
  REMOVE_INVITE_ADMIN_ORG: null,
  REMOVE_INVITE_USER_ORG: null,

  ADD_ALL_ADMIN_ORGS: null,
  ADD_ALL_USER_ORGS: null,
  ADD_ADMIN_ORG: null,
  ADD_USER_ORG: null,
  CLEAR_SELECTED_USER: null,
  DELETE_USER_FAILURE: null,
  DELETE_USER_START: null,
  DELETE_USER_SUCCESS: null,
  FIND_USER_BY_ID_FAILURE: null,
  FIND_USER_BY_ID_START: null,
  FIND_USER_BY_ID_SUCCESS: null,
  REMOVE_ALL_ADMIN_ORGS: null,
  REMOVE_ALL_USER_ORGS: null,
  REMOVE_ADMIN_ORG: null,
  REMOVE_USER_ORG: null,
  SET_SELECTED_USER: null,
  UPDATE_USER_ORGANIZATIONS_FAILURE: null,
  UPDATE_USER_ORGANIZATIONS_START: null,
  UPDATE_USER_ORGANIZATIONS_SUCCESS: null,

  ACCEPT_CONDITIONS_OF_ACCESS_START: null,
  ACCEPT_CONDITIONS_OF_ACCESS_SUCCESS: null,
  ACCEPT_CONDITIONS_OF_ACCESS_FAILURE: null,
  REGISTER_FAILURE: null,
  REGISTER_START: null,
  REGISTER_SUCCESS: null,
  REQUEST_RESET_TOKEN_FAILURE: null,
  REQUEST_RESET_TOKEN_START: null,
  REQUEST_RESET_TOKEN_SUCCESS: null,
  RESET_PASSWORD_FAILURE: null,
  RESET_PASSWORD_START: null,
  RESET_PASSWORD_SUCCESS: null,
  SIGN_IN: null,
  SIGN_IN_FAILURE: null,
  SIGN_IN_START: null,
  SIGN_OUT: null,
  UPDATE_CURRENT_USER: null,

  GET_ATTRIBUTES_START: null,
  GET_ATTRIBUTES_SUCCESS: null,
  GET_ATTRIBUTES_FAILURE: null,
  // The following cases currently don't have reducers
  DELETE_ATTRIBUTES_START: null,
  DELETE_ATTRIBUTES_SUCCESS: null,
  DELETE_ATTRIBUTES_FAILURE: null,

  GET_PROJECT_FLAGS_START: null,
  GET_PROJECT_FLAGS_SUCCESS: null,
  GET_PROJECT_FLAGS_FAILURE: null,

  GET_FILTERS_START: null,
  GET_FILTERS_SUCCESS: null,
  GET_FILTERS_FAILURE: null,
  SET_APPLIED_FILTERS_START: null,
  SET_APPLIED_FILTERS_SUCCESS: null,
  SET_APPLIED_FILTERS_FAILURE: null,

  GET_FILE_EXCLUSION_RULES_START: null,
  GET_FILE_EXCLUSION_RULES_SUCCESS: null,
  GET_FILE_EXCLUSION_RULES_FAILURE: null,
});
