import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  fields: new Immutable.Map(), // { attributes: [], metadata: [] }
  appliedFilters: '', // location.search
});

function filters(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_FILTERS_START:
      return state.withMutations(map => {
        map.delete('error');
        map.set('isActive', true);
      });

    case actionTypes.GET_FILTERS_SUCCESS:
      return state.withMutations(map => {
        map.set('isActive', false);
        map.set('fields', Immutable.fromJS(action.payload.fields));
      });

    case actionTypes.GET_FILTERS_FAILURE:
      return state.withMutations(map => {
        map.set('error', action.payload);
        map.set('isActive', false);
      });

    case actionTypes.SET_APPLIED_FILTERS_SUCCESS:
      return state.withMutations(map => {
        map.set('appliedFilters', action.payload.searchParamsString);
      });

    case actionTypes.SIGN_IN_START:
    case actionTypes.SIGN_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default filters;
