import { getRequestError } from '../utils/errorHelpers';
import apiService from './api';

function getProjectDetailsById(id) {
  return apiService.get(`/projects/${id}`).then(({ data }) => data.project);
}

function getProjectFilePreviewURL(id) {
  return apiService.get(`/files/${id}/preview`).then(({ data }) => data);
}

function getProjectFiles(id) {
  return apiService.get(`/projects/${id}/files`).then(({ data }) => data.files);
}

function getProjectFilters() {
  return apiService.get('/projects/filters').then(({ data }) => data.filters);
}

function getProjectRenderingById(id) {
  return apiService.get(`/projects/${id}/rendering`).then(({ data }) => data.embedUrl);
}

function getProjects({ page = 1, ...filters }) {
  const params = {
    ...filters,
    page,
  };

  return apiService.get('/projects', { params }).then(({ data }) => data);
}

function updateProjectById(id, payload) {
  return apiService
    .patch(`/projects/${id}`, payload)
    .then(({ data }) => data.project)
    .catch(error => {
      const args = { error, type: 'delete', service: 'project flag' };
      const err = getRequestError(args);
      throw new Error(err);
    });
}

export default {
  getProjectDetailsById,
  getProjectFilePreviewURL,
  getProjectFiles,
  getProjectFilters,
  getProjectRenderingById,
  getProjects,
  updateProjectById,
};
