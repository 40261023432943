import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import * as reduxFormActionCreators from 'redux-form/lib/actions';
import * as authenticationActionCreators from '../actions/authentication';
import SignIn from '../components/SignIn';

function mapStateToProps(state) {
  return {
    hasAgreedToConditionsOfAccess: state.authentication.get('hasAgreedToConditionsOfAccess'),
    headers: state.authentication.get('headers'),
    isActive: state.authentication.get('isActive')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      authentication: bindActionCreators(authenticationActionCreators, dispatch),
      form: bindActionCreators(reduxFormActionCreators, dispatch)
    }
  };
}

export {
  mapStateToProps,
  mapDispatchToProps
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
