import axios from 'axios';
import { signOut } from '../actions/authentication';
import { store } from './store';

function addAuthenticationHeaders(config) {
  const state = store.getState();
  const headers = state.authentication.get('headers');

  if (headers && headers.has('Authorization')) {
    config.headers.common.Authorization = headers.get('Authorization');
  }

  return config;
}

function invalidateHeaders(error) {
  if (error.response?.status === 401) {
    store.dispatch(signOut());
  }

  throw error;
}

function init() {
  const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  api.interceptors.request.use(addAuthenticationHeaders);
  api.interceptors.response.use(res => res, invalidateHeaders);

  return api;
}

export { addAuthenticationHeaders, init, invalidateHeaders };
export default init();
