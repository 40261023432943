import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Header from './base/Header';

const propTypes = {
  acceptConditionsOfAccess: PropTypes.func.isRequired,
  cancelConditionsOfAccess: PropTypes.func.isRequired,
  cancelPath: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    // nonexistent in ReactRouter v4+
    // query: PropTypes.obj
  }).isRequired,
  isActive: PropTypes.bool,
};

function ConditionsOfAccess(props) {
  return (
    <div className="l-sign-in l-sign-in--wide">
      <Header />
      <div className="c-card c-conditions-of-access">
        <h2 className="c-card__title">Conditions of Access</h2>
        <p>
          I am being granted access to the information contained on this site in order to review
          certain information of SONOCO or their affiliates. I understand that my access to this
          site is subject to the following conditions:
        </p>
        <ul className="c-conditions-of-access__list">
          <li className="c-conditions-of-access__list-item">
            All of the information contained on this site is considered confidential and proprietary
            to Sonoco, and is subject to the Confidentiality Agreement entered into between SONOCO
            and the company that I represent. I will maintain the information in confidence and will
            not disclose any of the information to others except as expressly permitted by the
            Confidentiality Agreement.
          </li>
          <li className="c-conditions-of-access__list-item">
            I will not attempt to download, scan, copy, print or otherwise capture any of the
            information contained on the site, except that I may view, print or download information
            for which the view, print or download capability has been enabled as indicated by the
            site index. I will not attempt to circumvent any of the security features of the site,
            and will not enable or allow others to access the site using my authorization to the
            site.
          </li>
          <li className="c-conditions-of-access__list-item">
            I understand that SONOCO is making no representations or warranties, express or implied,
            as to the accuracy or completeness of the information, and that SONOCO will have no
            liability with respect to any use or reliance upon any of the information.
          </li>
        </ul>
        <p>
          By clicking on the &ldquo;I Accept&rdquo; button below, I acknowledge that I have read,
          understand, and agree to the above conditions.
        </p>
        <div className="c-conditions-of-access__actions c-button-row">
          <Link
            className="c-button c-button--hollow"
            onClick={props.cancelConditionsOfAccess}
            to={props.cancelPath}>
            Cancel
          </Link>
          <button
            className="c-button"
            disabled={props.isActive}
            onClick={props.acceptConditionsOfAccess}>
            I Accept
          </button>
        </div>
      </div>
    </div>
  );
}

ConditionsOfAccess.propTypes = propTypes;

export default ConditionsOfAccess;
