import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import DynamicAttributeForm from '../components/ProjectDetail/DynamicAttributes/DynamicAttributeForm.jsx';
import formValidationService from '../services/formValidation';
import { updateProjectById } from '../actions/projects';

function validate(values) {
  return new formValidationService.Validation()
    .required('label', values.label)
    .required('value', values.value)
    .getErrors();
}

const formName = 'newDynamicAttribute';
const ConnectedForm = reduxForm({
  form: formName,
  initialValues: { label: '', value: '' },
  validate,
})(DynamicAttributeForm);

const propTypes = {
  /** if getting attribute options */
  isActive: PropTypes.bool.isRequired,
  /** attribute options */
  attributes: ImmutablePropTypes.list.isRequired,
  /** the current project */
  project: ImmutablePropTypes.contains({
    boxId: PropTypes.string.isRequired,
  }).isRequired,
  /** a function used to reset the form */
  resetForm: PropTypes.func.isRequired,
  /** refreshes project after successful add */
  updateProject: PropTypes.func.isRequired,
};

class ConnectedDynamicAttributeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formShown: false,
    };

    this.userToggledForm = this.userToggledForm.bind(this);
    this.userClickedSave = this.userClickedSave.bind(this);
  }

  // User toggles the new attribute form visibility
  userToggledForm() {
    this.setState({ formShown: !this.state.formShown });
  }

  // User wants to add a new attribute to the project
  async userClickedSave(payload) {
    try {
      const boxId = this.props.project.get('boxId');
      const { label, value } = payload;

      await this.props.updateProject(boxId, {
        attributes: { [label]: value },
      });

      toast('Attribute added!', { type: 'success' });
      this.props.resetForm();
    } catch (e) {
      toast(e.message, { type: 'error' });
    }
  }

  render() {
    return (
      <ConnectedForm
        {...this.props}
        {...this.state}
        userClickedSave={this.userClickedSave}
        userToggledForm={this.userToggledForm}
      />
    );
  }
}

ConnectedDynamicAttributeForm.propTypes = propTypes;

const mapStateToProps = state => ({
  isActive: state.attributes.get('isActive'),
  attributes: state.attributes.get('attributes'),
  project: state.projectDetails.getIn(['details', 'values']),
  role: state.authentication.get('role'),
});

const mapDispatchToProps = dispatch => ({
  resetForm: () => dispatch(reset(formName)),
  updateProject: (boxId, payload) => dispatch(updateProjectById(boxId, payload)),
});

export {
  ConnectedDynamicAttributeForm as DynamicAttributeForm,
  mapStateToProps,
  mapDispatchToProps,
};
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedDynamicAttributeForm);
