import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  errors: PropTypes.array.isRequired,
};

function FlashErrors(props) {
  if (props.errors.length === 0) {
    return null;
  }

  return (
    <div className="c-form-error__container">
      {props.errors.map(error => {
        return (
          <p className="c-form__error" key={error}>
            {error}
          </p>
        );
      })}
    </div>
  );
}

FlashErrors.propTypes = propTypes;

export default FlashErrors;
