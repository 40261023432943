import React
 from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string
};

function PlusIcon({ className }) {
  const classes = classNames('c-icon c-icon--plus', { [className]: className });

  return (
    <svg className={classes} viewBox="0 0 12 12">
      <rect height="2" width="12" x="0" y="5" />
      <rect height="12" width="2" x="5" y="0" />
    </svg>
  );
}

PlusIcon.propTypes = propTypes;

export default PlusIcon;
