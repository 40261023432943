import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FiX } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { removeFilterTag } from '../../../../utils/filterHelpers';
import { IconButton } from '../../../base/fields/styles';

/**
 * A single tag item for filter tag list
 * @example appearance: [ flag: foo x ]
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const FilterTag = props => {
  const history = useHistory();
  const location = useLocation();
  const fields = useSelector(({ filters }) => filters.get('fields'));

  const userRemovedTag = () => {
    const { field, value: keyword } = props;
    removeFilterTag({ location, history, field, keyword });
  };

  // for project flags only
  const getOverrideLabel = originalLabel => {
    const allFlags = fields.get('projectFlags')?.toJS() || [];
    if (_.isEmpty(allFlags)) {
      return originalLabel;
    } else {
      const labelIndex = _.findIndex(allFlags, o => o._id === originalLabel);
      if (labelIndex === -1) return originalLabel;
      return allFlags[labelIndex].label;
    }
  };

  return (
    <Tag>
      <div>
        {props.label}: {getOverrideLabel(props.value)}
      </div>
      <IconButton onClick={userRemovedTag}>
        <FiX />
      </IconButton>
    </Tag>
  );
};

FilterTag.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #144a8e14;
  padding: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  @media (max-width: 577.98px) {
    flex-grow: 1;
    margin-right: 0.5rem;
  }
`;
