import React from 'react';
import { Field } from 'redux-form';
import { Col, Row, useScreenClass } from 'react-grid-system';
import _ from 'lodash';

import { Can } from '../../base/Can';
import { ruleTypes } from '../../../constants/rbac-rules';
import { ConnectedJInput } from '../../base/fields/connected-forms';
import { FieldItem, HiddenGap, MetadataContainer, Regular } from '../styles';

const DynamicAttributeList = props => {
  const screenClass = useScreenClass();

  const attributes = props.project.get('attributes');
  const keys = attributes?.keys() || []; // attribute keys
  const codes = [...keys].sort(); // sorted attribute keys

  const isScreenSmall = _.includes(['xs', 'sm', 'md'], screenClass);
  const isAdmin = _.includes(['superAdmin', 'admin'], props.role);

  return (
    <MetadataContainer isAdmin={isAdmin}>
      {codes.map((code, index) => {
        const value = attributes.get(code);
        const isLast = index === codes.length - 1;

        return (
          <Row key={code} align="center">
            <Col lg={6}>
              <Regular bold>{props.dictionary[code]}</Regular>
              <HiddenGap height={4} />
            </Col>
            <Col lg={6}>
              <Can
                role={props.role}
                perform={ruleTypes.DYNAMIC_ATTRIBUTES__EDIT}
                yes={() => (
                  <FieldItem isScreenSmall={isScreenSmall}>
                    <Field
                      name={code}
                      component={ConnectedJInput}
                      full={isScreenSmall}
                      initialValue={value}
                      onFieldSubmit={data => {
                        props.userUpdatedAttribute({ [code]: data });
                      }}
                    />
                  </FieldItem>
                )}
                no={() => <Regular>{value || '-'}</Regular>}
              />
              {!isLast && <HiddenGap height={12} />}
            </Col>
          </Row>
        );
      })}
    </MetadataContainer>
  );
};

export default DynamicAttributeList;
