import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import LineItemError from './LineItemError.jsx';
import LineItemPlaceholder from './LineItemPlaceholder';

const propTypes = {
  isActive: PropTypes.bool,
  organizations: ImmutablePropTypes.list.isRequired,
};

function Label({ name, projectCount }) {
  return (
    <div className="c-line-item__label">
      <h3>{name}</h3>
      <p>Contains {projectCount} projects</p>
    </div>
  );
}

Label.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.string,
  projectCount: PropTypes.number,
};

function Accounts({ isActive, organizations }) {
  return (
    <ul>
      {(() => {
        if (isActive) {
          return _.times(12, i => <LineItemPlaceholder key={i} />);
        } else if (!organizations.size) {
          return (
            <LineItemError
              subtitle="If refreshing your page doesn't fix it, please contact your super admin"
              title="You're not connected to any accounts!"
            />
          );
        }

        return (
          <ul>
            {organizations.map(organization => {
              return (
                <li className="c-line-item" key={organization.get('id')}>
                  <Label
                    isActive={isActive}
                    name={organization.get('name')}
                    projectCount={organization.get('projectCount')}
                  />
                </li>
              );
            })}
          </ul>
        );
      })()}
    </ul>
  );
}

Accounts.propTypes = propTypes;

export default Accounts;
