import React from 'react';
import PropTypes from 'prop-types';
import ImmutableProptypes from 'react-immutable-proptypes';

import LineItemInviteManagement from './LineItemInviteManagement';
import LineItemUserManagement from './LineItemUserManagement';

const propTypes = {
  location: PropTypes.string.isRequired,
  pushRoute: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  user: ImmutableProptypes.contains({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
};

function UserLineItem(props) {
  const { user } = props;
  const name = user.get('name');

  return (
    <li className="c-line-item">
      <div className="c-line-item__label">
        {name && <h3>{name}</h3>}
        <p>{user.get('email')}</p>
      </div>
      {(() => {
        switch (user.get('type')) {
          case 'invitation':
            return <LineItemInviteManagement {...props} />;
          case 'user':
            return <LineItemUserManagement {...props} />;
          default:
            return null;
        }
      })()}
    </li>
  );
}

UserLineItem.propTypes = propTypes;

export default UserLineItem;
