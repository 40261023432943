import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Map } from 'immutable';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

import { Ellipsis, HexBlock, HexContainer } from './styles';
import { colors } from '../../base/styles';
import { Can } from '../../base/Can';
import { ruleTypes } from '../../../constants/rbac-rules';
import { IconButton } from '../../base/IconButton';
import { editIcon, trashIcon } from '../../../assets/images';

export function getTooltipCell(text, index, id) {
  const dataFor = `${id}-${index}`;
  return (
    <>
      <Ellipsis data-tip data-for={dataFor}>
        {text}
      </Ellipsis>
      <ReactTooltip id={dataFor} effect="solid">
        <span>{text}</span>
      </ReactTooltip>
    </>
  );
}

export function getColorCell(row, index, column, id) {
  return (
    <HexContainer>
      <HexBlock hex={row.colorCode} /> {row.colorCode}
    </HexContainer>
  );
}

export function getEnabledCell(row, index, column, id) {
  return row.isEnabled ? (
    <FiCheckCircle size={20} color={colors.green} />
  ) : (
    <FiXCircle size={20} color={colors.red} />
  );
}

export function getActionCell(flag, props) {
  const editUrl = `/admin/project-flags/${flag._id}/edit`;
  const deleteTipId = 'delete-flag-tip';
  const deleteId = props.projectFlagToDelete?.get('_id');
  const deleteDisabled = flag.total > 0 || deleteId === flag._id || props.loading;
  // if attr is in use, set delete for to tipId to show tooltip
  const deleteFor = flag.total > 0 ? deleteTipId : '';
  const tip = 'Project flag currently in use';

  return (
    <>
      <Can
        role={props.role}
        perform={ruleTypes.PROJECT_FLAGS__EDIT}
        yes={() => (
          <IconButton alt="Edit" src={editIcon} onClick={() => props.history.push(editUrl)} />
        )}
      />
      <Can
        role={props.role}
        perform={ruleTypes.PROJECT_FLAGS__DELETE}
        yes={() => (
          <>
            <span data-tip data-for={deleteFor}>
              <IconButton
                alt="Delete"
                src={trashIcon}
                disabled={deleteDisabled}
                onClick={() => props.confirmDelete(Map(flag))}
              />
            </span>
            <ReactTooltip id={deleteTipId} effect="solid">
              <span>{tip}</span>
            </ReactTooltip>
          </>
        )}
      />
    </>
  );
}

const _border = '2px solid #D7D7D7';
const _fontConfig = { fontSize: 14, fontWeight: 700, color: colors.gray };
export const customStyles = {
  table: {
    style: {
      border: _border,
    },
  },
  headRow: {
    style: {
      borderBottom: _border,
    },
  },
  headCells: {
    style: {
      ..._fontConfig,
      '&:not(:last-of-type)': {
        borderRight: _border,
      },
    },
    activeSortStyle: {
      color: colors.gray,
    },
  },
  rows: {
    style: {
      ..._fontConfig,
      '&:not(:last-of-type)': {
        borderBottom: _border,
      },
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRight: _border,
      },
    },
  },
};
