import actionTypes from '../constants/actionTypes';
import inviteService from '../services/invitations';

function addAllInviteAdminOrgs(orgs) {
  return {
    type: actionTypes.ADD_ALL_INVITE_ADMIN_ORGS,
    payload: { orgs }
  };
}

function addAllInviteUserOrgs(orgs) {
  return {
    type: actionTypes.ADD_ALL_INVITE_USER_ORGS,
    payload: { orgs }
  };
}

function addInviteAdminOrg(id) {
  return {
    type: actionTypes.ADD_INVITE_ADMIN_ORG,
    payload: { id }
  };
}

function addInviteUserOrg(id) {
  return {
    type: actionTypes.ADD_INVITE_USER_ORG,
    payload: { id }
  };
}

function clearInvite() {
  return {
    type: actionTypes.CLEAR_INVITE,
    payload: {}
  };
}

function deleteInviteFailure(err) {
  return {
    type: actionTypes.DELETE_INVITE_FAILURE,
    error: true,
    payload: err
  };
}

function deleteInviteStart() {
  return {
    type: actionTypes.DELETE_INVITE_START
  };
}

function deleteInviteSuccess(id) {
  return {
    type: actionTypes.DELETE_INVITE_SUCCESS,
    payload: { id }
  };
}

function deleteInvite(id) {
  return function(dispatch) {
    dispatch(deleteInviteStart());

    return inviteService
      .deleteInvite(id)
      .then((deletedInvite) => dispatch(deleteInviteSuccess(deletedInvite)))
      .catch((err) => dispatch(deleteInviteFailure(err)));
  };
}

function removeAllInviteAdminOrgs(orgs) {
  return {
    type: actionTypes.REMOVE_ALL_INVITE_ADMIN_ORGS,
    payload: { orgs }
  };
}

function removeInviteAdminOrg(id) {
  return {
    type: actionTypes.REMOVE_INVITE_ADMIN_ORG,
    payload: { id }
  };
}

function removeInviteUserOrg(id) {
  return {
    type: actionTypes.REMOVE_INVITE_USER_ORG,
    payload: { id }
  };
}

export {
  addAllInviteAdminOrgs,
  addAllInviteUserOrgs,
  addInviteAdminOrg,
  addInviteUserOrg,
  clearInvite,
  deleteInvite,
  removeAllInviteAdminOrgs,
  removeInviteAdminOrg,
  removeInviteUserOrg
};
