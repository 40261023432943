import _, { initial } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row, useScreenClass } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { updateProjectById } from '../../actions/projects';
import { isValidHttpUrl } from '../../utils/url';
import { JiraInput } from '../base/fields/JiraInput';
import { FieldItem, HiddenGap, MetadataContainer, Regular } from './styles';

const fields = [
  { name: 'vrLink', label: 'VR Link' },
  { name: 'isArEnabled', label: 'Enable AR' },
  // { name: 'arLink', label: 'AR Link' },
  // { name: 'threeDLink', label: '3D Link' },
];

// TODO: soc
export const ProjectLinks = props => {
  // state selectors
  const role = useSelector(({ authentication }) => authentication.get('role'));
  const details = useSelector(({ projectDetails }) => projectDetails.getIn(['details', 'values']));

  const [isArChecked, setIsArChecked] = useState(details.get('isArEnabled'));

  // external hooks
  const dispatch = useDispatch();
  const params = useParams();
  const screenClass = useScreenClass();

  /**
   * A function to update project links that requires boxID
   * @param {{*}} payload
   */
  const updateProject = (field, value) => {
    if (details.get(field) === value) return;
    const boxId = params.boxId;
    dispatch(updateProjectById(boxId, { [field]: value }, { confirm: true, showError: true }));
  };

  const isAdmin = _.includes(['superAdmin', 'admin'], role);
  const isScreenSmall = _.includes(['xs', 'sm', 'md'], screenClass);

  return (
    <MetadataContainer isAdmin={isAdmin}>
      {fields.map((field, index) => {
        const initialValue = details.get(field.name);
        const isLast = index === fields.length - 1;

        return (
          <Row key={field.name} align="center">
            <Col lg={6}>
              <Regular bold>{field.label}</Regular>
              <HiddenGap height={4} />
            </Col>
            <Col lg={6}>
              {field.name !== 'isArEnabled' ? (
                <FieldItem isScreenSmall={isScreenSmall}>
                  <JiraInput
                    full={isScreenSmall}
                    initialValue={initialValue}
                    maxLength={256}
                    placeholder="-"
                    onFieldSubmit={value => {
                      if (!isValidHttpUrl(value)) {
                        toast.error('Failed to save link, URL is invalid!');
                      } else {
                        updateProject(field.name, value);
                      }
                    }}
                  />
                </FieldItem>
              ) : (
                <InputContainer>
                  <input
                    className="c-attrib-checkbox-row__check"
                    type="checkbox"
                    name="isArEnabled"
                    checked={isArChecked}
                    onChange={e => {
                      e.preventDefault();
                      setIsArChecked(e.target.checked);
                      updateProject(field.name, e.target.checked);
                    }}
                  />
                </InputContainer>
              )}
              {!isLast && <HiddenGap height={12} />}
            </Col>
          </Row>
        );
      })}
    </MetadataContainer>
  );
};

const InputContainer = styled.div`
  padding: 0 8px;
`;
