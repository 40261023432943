import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { JiraField } from './JiraField';
import { formatLastUpdated } from '../../../utils/dates';
import { Picker } from './styles';

export const jiraDatePickerProps = {
  type: PropTypes.oneOf(['controlled', 'uncontrolled']),
  placeholder: PropTypes.string,
  initialValue: PropTypes.instanceOf(Date),
  onFieldSubmit: PropTypes.func,
  // if form is 'controlled', the following should be passed:
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChangeText: PropTypes.func,
  full: PropTypes.bool,
};

/**
 * Rewrite of HoverableDatePicker - without dependency to redux form
 * HoverableDatePicker is a date picker component for jira-style editable fields.
 * @param props
 * @example
 * ```
 * // uncontrolled
 * <JiraDatePicker
 *  initialValue={new Date()}
 *  onFieldSubmit={date => console.log('date -', date)}
 * />
 *
 * // controlled
 * const [value, setValue] = React.useState(new Date());
 * ...
 * <JiraDatePicker
 *  type="controlled"
 *  initialValue={new Date()}
 *  value={value}
 *  onChangeText={setValue}
 *  onFieldSubmit={date => console.log('date -', date)}
 * />
 * ```
 * @returns {JSX.Element}
 * @constructor
 */
export const JiraDatePicker = props => {
  const [uncontrolledValue, setUncontrolledValue] = useState(props.initialValue);

  const value = props.type === 'controlled' ? props.value : uncontrolledValue;
  const setValue = props.type === 'controlled' ? props.onChangeText : setUncontrolledValue;

  return (
    <JiraField
      full={props.full}
      value={value}
      setValue={setValue}
      placeholder={props.placeholder}
      title={value && formatLastUpdated(value)}
      initialValue={props.initialValue}
      onFieldSubmit={props.onFieldSubmit}>
      <Picker dateFormat="MMM dd yyyy" selected={value} onChange={date => setValue(date)} />
    </JiraField>
  );
};

JiraDatePicker.propTypes = jiraDatePickerProps;

JiraDatePicker.defaultProps = {
  type: 'uncontrolled',
  value: new Date(),
  onChangeText: () => {},
};
