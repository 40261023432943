import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ProjectFlags from '../../components/AccountPanel/ProjectFlags';
import projectFlagsService from '../../services/project-flags';
import { getProjectFlags } from '../../actions/projectFlags';
import { states } from '../../constants/route-states';
import { getOrganizations } from '../../actions/accounts';

const ConnectedProjectFlags = props => {
  const [projectFlagToDelete, setProjectFlagToDelete] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();
  const flags = useSelector(state => state.projectFlags.get('projectFlags'));
  const loading = useSelector(state => state.projectFlags.get('isActive'));
  const role = useSelector(state => state.authentication.get('role'));
  const organizations = useSelector(({ accounts }) => accounts.get('organizations'));
  const loadingOrganizations = useSelector(({ accounts }) => accounts.get('isActive'));

  // load project flags
  useEffect(() => {
    dispatch(getProjectFlags());
  }, []);

  // load customers/organizations
  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  // trigger a refresh of project flag list
  useEffect(() => {
    if (props.location.state?.status === states.TRIGGER_REFRESH) {
      dispatch(getProjectFlags());
      props.history.replace();
    }
  }, [props.location.state?.status]);

  function confirmDelete(flag) {
    setProjectFlagToDelete(flag);
    setIsDeleteDialogOpen(true);
  }

  function cancelDelete() {
    setProjectFlagToDelete(null);
    setIsDeleteDialogOpen(false);
  }

  function handleDelete() {
    const id = projectFlagToDelete?.get('_id');
    const usage = projectFlagToDelete?.get('total');

    if (usage > 0) {
      toast.error("You can't delete a project flag that is being used");
      return;
    }

    setDeleting(true);

    projectFlagsService
      .deleteProjectFlag(id)
      .then(() => {
        setDeleting(false);
        cancelDelete();
        toast('Project flag deleted!', { type: 'success' });
        dispatch(getProjectFlags());
      })
      .catch(error => {
        setDeleting(false);
        cancelDelete();
        toast(error.message, { type: 'error' });
      });
  }

  return (
    <ProjectFlags
      {...props}
      role={role}
      flags={flags}
      orgs={organizations}
      loading={loading || loadingOrganizations}
      deleting={deleting}
      projectFlagToDelete={projectFlagToDelete}
      isDeleteDialogOpen={isDeleteDialogOpen}
      confirmDelete={confirmDelete}
      cancelDelete={cancelDelete}
      handleDelete={handleDelete}
    />
  );
};

export default ConnectedProjectFlags;
