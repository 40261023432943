import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import ProjectFlagForm from '../../components/AccountPanel/ProjectFlags/ProjectFlagForm';
import flagsService from '../../services/project-flags';
import { states } from '../../constants/route-states';
import { getOrganizations } from '../../actions/accounts';

const parentRoute = '/admin/project-flags';
const initialValues = {
  customer: 'default',
  code: '',
  label: '',
  colorCode: '#3D7CBF',
  isEnabled: true,
};

const ConnectedAddProjectFlag = props => {
  const [loading, setLoading] = useState(false);

  const organizations = useSelector(({ accounts }) => accounts.get('organizations'));
  const isLoadingOrganizations = useSelector(({ accounts }) => accounts.get('isActive'));

  function handleClose(state) {
    props.history.push(parentRoute, state);
  }

  function handleSubmit(values) {
    setLoading(true);
    flagsService
      .addProjectFlag(values)
      .then(() => {
        setLoading(false);
        toast('Project flag added!', { type: 'success' });
        handleClose({ status: states.TRIGGER_REFRESH });
      })
      .catch(error => {
        setLoading(false);
        toast(error.message, { type: 'error' });
      });
  }

  return (
    <ProjectFlagForm
      {...props}
      type="add"
      title="Add New Project Flag"
      subtitle="Use fields below to add a new project flag."
      submitText="Create"
      initialValues={initialValues}
      organizations={organizations}
      loading={loading || isLoadingOrganizations}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};

export default ConnectedAddProjectFlag;
