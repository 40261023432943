import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import Dropdown from './Dropdown';
import SearchBar from './SearchBar';

const propTypes = {
  actions: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  userInfo: PropTypes.object,
};

function Actions({ actions, isAuthenticated, userInfo }) {
  const history = useHistory();
  const location = useLocation();

  const router = {
    location: { query: parse(location.search) },
    push: history.push,
  };

  if (!isAuthenticated) {
    return null;
  }
  return (
    <div className="c-header-actions">
      <SearchBar actions={actions} router={router} />
      <Dropdown actions={actions} router={router} userInfo={userInfo} />
    </div>
  );
}

Actions.propTypes = propTypes;

export default Actions;
