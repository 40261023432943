import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { fieldicons, JiraField } from './JiraField';
import { VanillaInput } from './VanillaInput';

export const jiraInputProps = {
  type: PropTypes.oneOf(['controlled', 'uncontrolled']),
  maxLength: PropTypes.number,
  name: PropTypes.string,
  disallowEmptyValues: PropTypes.bool,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
  shownIcons: PropTypes.arrayOf(PropTypes.string),
  onFieldSubmit: PropTypes.func,
  // if form is 'controlled', the following should be passed:
  value: PropTypes.string,
  onChangeText: PropTypes.func, // callback argument is already text
  buttonTextStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  full: PropTypes.bool,
};

/**
 * Rewrite of HoverableInput - without dependency to redux form
 * HoverableInput is a text input component for jira-style editable fields.
 * @param props
 * @example
 * ```
 * // uncontrolled example:
 * <JiraInput
 *  initialValue="Initial value"
 *  onFieldSubmit={value => console.log('value -', value)}
 * />
 *
 * // controlled example:
 * const [value, setValue] = React.useState('');
 * ...
 * <JiraInput
 *  type="controlled"
 *  name="fieldName"
 *  maxLength={10}
 *  disallowEmptyValues={true}
 *  initialValue="initial"
 *  value={value}
 *  onChangeText={setValue}
 *  onFieldSubmit={value => console.log('value -', value)}
 * />
 * ```
 * @returns {JSX.Element}
 * @constructor
 */
export const JiraInput = props => {
  const [uncontrolledValue, setUncontrolledValue] = useState(props.initialValue);

  const value = props.type === 'controlled' ? props.value : uncontrolledValue;
  const setValue = props.type === 'controlled' ? props.onChangeText : setUncontrolledValue;

  return (
    <JiraField
      full={props.full}
      buttonTextStyle={props.buttonTextStyle}
      value={value}
      setValue={setValue}
      placeholder={props.placeholder}
      shownIcons={props.shownIcons}
      initialValue={props.initialValue}
      name={props.name}
      disallowEmptyValues={props.disallowEmptyValues}
      onFieldSubmit={value => {
        // trim the value of trailing whitespace and show it to the user
        setValue(value.trim());
        props.onFieldSubmit?.(value.trim());
      }}>
      <VanillaInput
        full={props.full}
        inputStyle={props.inputStyle}
        maxLength={props.maxLength}
        onChangeText={setValue}
        value={value}
      />
    </JiraField>
  );
};

JiraInput.propTypes = jiraInputProps;

JiraInput.defaultProps = {
  type: 'uncontrolled',
  shownIcons: [fieldicons.SUBMIT, fieldicons.CANCEL],
  onChangeText: () => {},
  value: '',
  maxLength: 50,
};
