import actionTypes from '../constants/actionTypes';
import accountsService from '../services/accounts';

function getOrganizationsStart() {
  return {
    type: actionTypes.GET_ORGANIZATIONS_START
  };
}

function getOrganizationsSuccess(organizations) {
  const adminOrganizations = organizations.filter((org) => org.hasAdminAccess);

  return {
    type: actionTypes.GET_ORGANIZATIONS_SUCCESS,
    payload: { organizations, adminOrganizations }
  };
}

function getOrganizationsFailure(err) {
  return {
    type: actionTypes.GET_ORGANIZATIONS_FAILURE,
    error: true,
    payload: err
  };
}

function getExpandedOrganizations() {
  return function(dispatch) {
    dispatch(getOrganizationsStart());

    return accountsService
      .getExpandedOrganizations()
      .then((organizations) => dispatch(getOrganizationsSuccess(organizations)))
      .catch((err) => dispatch(getOrganizationsFailure(err)));
  };
}

function getOrganizations() {
  return function(dispatch) {
    dispatch(getOrganizationsStart());

    return accountsService
      .getOrganizations()
      .then((organizations) => dispatch(getOrganizationsSuccess(organizations)))
      .catch((err) => dispatch(getOrganizationsFailure(err)));
  };
}

export {
  getExpandedOrganizations,
  getOrganizations
};
