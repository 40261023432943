import actionTypes from '../constants/actionTypes';
import usersService from '../services/users';

function addAllAdminOrgs(orgs) {
  return {
    type: actionTypes.ADD_ALL_ADMIN_ORGS,
    payload: { orgs }
  };
}

function addAllUserOrgs(orgs) {
  return {
    type: actionTypes.ADD_ALL_USER_ORGS,
    payload: { orgs }
  };
}

function addAdminOrg(id) {
  return {
    type: actionTypes.ADD_ADMIN_ORG,
    payload: { id }
  };
}

function addUserOrg(id) {
  return {
    type: actionTypes.ADD_USER_ORG,
    payload: { id }
  };
}

function clearSelectedUser() {
  return {
    type: actionTypes.CLEAR_SELECTED_USER,
    payload: {}
  };
}

function deleteUserFailure(err) {
  return {
    type: actionTypes.DELETE_USER_FAILURE,
    error: true,
    payload: err
  };
}

function deleteUserStart() {
  return {
    type: actionTypes.DELETE_USER_START
  };
}

function deleteUserSuccess(user) {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    payload: { user }
  };
}

function deleteUser(user) {
  return function(dispatch) {
    dispatch(deleteUserStart());

    return usersService
      .deleteUser(user)
      .then((deletedUser) => dispatch(deleteUserSuccess(deletedUser)))
      .catch((err) => dispatch(deleteUserFailure(err)));
  };
}

function findUserByIdFailure(err) {
  return {
    type: actionTypes.FIND_USER_BY_ID_FAILURE,
    error: true,
    payload: err
  };
}

function findUserByIdStart() {
  return {
    type: actionTypes.FIND_USER_BY_ID_START
  };
}

function findUserByIdSuccess(user) {
  return {
    type: actionTypes.FIND_USER_BY_ID_SUCCESS,
    payload: { user }
  };
}

function findUserById(user) {
  return function(dispatch) {
    dispatch(findUserByIdStart());

    return usersService
      .findUserById(user)
      .then((foundUser) => dispatch(findUserByIdSuccess(foundUser)))
      .catch((err) => dispatch(findUserByIdFailure(err)));
  };
}

function removeAllAdminOrgs(orgs) {
  return {
    type: actionTypes.REMOVE_ALL_ADMIN_ORGS,
    payload: { orgs }
  };
}

function removeAllUserOrgs() {
  return {
    type: actionTypes.REMOVE_ALL_USER_ORGS,
    payload: { }
  };
}

function removeAdminOrg(id) {
  return {
    type: actionTypes.REMOVE_ADMIN_ORG,
    payload: { id }
  };
}

function removeUserOrg(id) {
  return {
    type: actionTypes.REMOVE_USER_ORG,
    payload: { id }
  };
}

function setSelectedUser(user) {
  return {
    type: actionTypes.SET_SELECTED_USER,
    payload: { user }
  };
}

function acceptConditionsOfAccessStart() {
  return {
    type: actionTypes.ACCEPT_CONDITIONS_OF_ACCESS_START
  };
}

function acceptConditionsOfAccessSuccess(user) {
  return {
    type: actionTypes.ACCEPT_CONDITIONS_OF_ACCESS_SUCCESS,
    payload: { user }
  };
}

function acceptConditionsOfAccessFailure(err) {
  return {
    type: actionTypes.ACCEPT_CONDITIONS_OF_ACCESS_FAILURE,
    error: true,
    payload: err
  };
}


function acceptConditionsOfAccess(id) {
  return function(dispatch) {
    dispatch(acceptConditionsOfAccessStart());

    const update = {
      _id: id,
      hasAgreedToConditionsOfAccess: true
    };

    return usersService
      .updateUser(update)
      .then((updatedUser) => dispatch(acceptConditionsOfAccessSuccess(updatedUser)))
      .catch((err) => dispatch(acceptConditionsOfAccessFailure(err)));
  };
}

function updateUserOrganizationsFailure(err) {
  return {
    type: actionTypes.UPDATE_USER_ORGANIZATIONS_FAILURE,
    error: true,
    payload: err
  };
}

function updateUserOrganizationsStart() {
  return {
    type: actionTypes.UPDATE_USER_ORGANIZATIONS_START
  };
}

function updateUserOrganizationsSuccess(user) {
  return {
    type: actionTypes.UPDATE_USER_ORGANIZATIONS_SUCCESS,
    payload: { user }
  };
}

function updateUserOrganizations(user) {
  return function(dispatch) {
    dispatch(updateUserOrganizationsStart());

    const update = {
      _id: user._id,
      adminOrganizations: user.adminOrganizations,
      userOrganizations: user.userOrganizations
    };

    return usersService
      .updateUser(update)
      .then((updatedUser) => dispatch(updateUserOrganizationsSuccess(updatedUser)))
      .catch((err) => dispatch(updateUserOrganizationsFailure(err)));
  };
}

export {
  addAllAdminOrgs,
  addAllUserOrgs,
  addAdminOrg,
  addUserOrg,
  clearSelectedUser,
  deleteUser,
  findUserById,
  removeAllUserOrgs,
  removeAllAdminOrgs,
  removeAdminOrg,
  removeUserOrg,
  setSelectedUser,
  acceptConditionsOfAccess,
  updateUserOrganizations
};
