import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DeleteError from './DeleteError';
import ImmutableProptypes from 'react-immutable-proptypes';
import Modal from '../../../base/Modal';
import XIcon from '../../../base/icons/XIcon';
import { ModalFooter } from '../../../base/styled';

const propTypes = {
  actions: PropTypes.shape({
    invitations: PropTypes.shape({
      deleteInvite: PropTypes.func.isRequired,
    }),
    users: PropTypes.shape({
      getUsers: PropTypes.func.isRequired,
    }),
    userDetails: PropTypes.shape({
      clearSelectedUser: PropTypes.func.isRequired,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  selectedUser: ImmutableProptypes.map.isRequired,
};

function getParentRoute(pathname) {
  const stringLoc = pathname.search(/\/invitation\/\S+\/delete/);
  return pathname.slice(0, stringLoc);
}

class DeleteInvite extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      parentRoute: getParentRoute(props.location.pathname),
    };
  }

  componentWillUnmount() {
    this.props.actions.userDetails.clearSelectedUser();
  }

  handleClose() {
    if (this.props.location.pathname.indexOf('/invitation/') > -1) {
      this.props.history.push(this.state.parentRoute);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const inviteId = this.props.selectedUser.toJS()._id;

    return this.props.actions.invitations
      .deleteInvite(inviteId)
      .then(this.handleClose)
      .then(this.props.actions.users.getUsers)
      .catch(error => {
        this.setState({
          error,
        });
      });
  }

  render() {
    return (
      <Modal handleClose={this.handleClose}>
        <div className="c-island">
          <Link to={this.state.parentRoute} className="c-island__exit">
            <XIcon />
          </Link>

          <div className="c-island__header">
            <h3>Delete User Invite</h3>
            <p>
              Account: <b>{this.props.selectedUser.get('email')}</b>
            </p>
            {!this.state.error && <p>Are you sure you want to delete this invitation?</p>}
          </div>

          <div className="c-island__body">
            {this.state.error ? (
              <DeleteError cancelLocation={this.state.parentRoute} error={this.state.error} />
            ) : (
              <form className="c-form" onSubmit={this.handleSubmit}>
                <ModalFooter className="c-button-row c-button-row--modal">
                  <Link
                    to={this.state.parentRoute}
                    className="c-button c-button--small c-button--hollow">
                    Cancel
                  </Link>
                  <button
                    className="c-button c-button--small"
                    type="submit"
                    disabled={this.props.selectedUser.get('isActive')}>
                    Delete Invite
                  </button>
                </ModalFooter>
              </form>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

DeleteInvite.propTypes = propTypes;

export default DeleteInvite;
