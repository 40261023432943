import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { parse } from 'query-string';

import { setErrors } from '../utils/formErrorHelpers';
import formValidationService from '../services/formValidation';
import FlashErrors from './base/FlashErrors';
import Header from './base/Header';
import MessageCard from './base/MessageCard';
import TextInput from './base/TextInput';

const propTypes = {
  actions: PropTypes.shape({
    authentication: PropTypes.shape({
      resetPassword: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      change: PropTypes.func.isRequired,
      initialize: PropTypes.func.isRequired,
    }),
  }),
  anyTouched: PropTypes.bool,
  error: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
    // nonexistent in ReactRouter v4+
    // query: PropTypes.shape({
    //   token: PropTypes.string,
    // }).isRequired,
  }).isRequired,
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { resetFinished: false };
    this.resetPassword = this.resetPassword.bind(this);
  }

  resetPassword({ password }) {
    const resetToken = parse(this.props.location.search).token;

    return this.props.actions.authentication
      .resetPassword(resetToken, password)
      .then(() => {
        this.setState({ resetFinished: true });
      })
      .catch(err => {
        this.props.actions.form.change('resetPassword', 'passwordConfirmation', '');
        this.props.actions.form.initialize('resetPassword', null, true);

        throw new SubmissionError({
          _error: [err.message],
        });
      });
  }

  validateFields(values) {
    return new formValidationService.Validation()
      .password(values.password)
      .passwordMatches(values.password, values.passwordConfirmation)
      .getErrors();
  }

  render() {
    const query = parse(this.props.location.search);

    return (
      <div className="l-sign-in">
        <Header />
        {(() => {
          if (this.state.resetFinished) {
            return <MessageCard>Password Reset Complete!</MessageCard>;
          } else if (!query.token) {
            return (
              <MessageCard>
                This password reset link is no longer valid. To request a new link, visit{' '}
                <Link to="/forgot-password">Forgot Password</Link>.
              </MessageCard>
            );
          }

          return (
            <div className="c-card">
              <h2 className="c-card__title">Reset Password</h2>
              <form className="c-form" onSubmit={this.props.handleSubmit(this.resetPassword)}>
                <Field component={TextInput} label="Password" name="password" type="password" />
                <Field
                  component={TextInput}
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  type="password"
                />
                <input
                  className="c-button c-button--block"
                  disabled={this.props.isActive}
                  type="submit"
                  value="Reset Password"
                />
                <FlashErrors errors={setErrors(this.props)} />
              </form>
            </div>
          );
        })()}
      </div>
    );
  }
}

ResetPassword.propTypes = propTypes;

export { ResetPassword };

export default reduxForm({
  form: 'resetPassword',
  validate: ResetPassword.prototype.validateFields,
})(ResetPassword);
