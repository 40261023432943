import PropTypes from 'prop-types';
import React from 'react';
import { PuffLoader } from 'react-spinners';
import styled from 'styled-components';

import { formatNumberByThousands } from '../../../utils/numbers';

const propTypes = {
  isVisible: PropTypes.bool,
  projectCount: PropTypes.number,
  searchQuery: PropTypes.string,
  loading: PropTypes.bool,
};

/**
 * A component that shows the number of projects filter request has found
 * @param {boolean} props.isVisible
 * @param {boolean} props.projectCount
 * @param {boolean} props.searchQuery
 * @param {boolean} props.loading
 */
function ProjectsInfo({ isVisible, projectCount, searchQuery, loading }) {
  return (
    <div className="c-projects__info">
      <Flexed>
        {(() => {
          if (isVisible && searchQuery) {
            return (
              <span>
                {formatNumberByThousands(projectCount)} Projects That Match:{' '}
                <strong>{searchQuery}</strong>
              </span>
            );
          } else if (isVisible && projectCount) {
            return <span>{formatNumberByThousands(projectCount)} Projects Total</span>;
          }
          return null;
        })()}
        {loading && <PuffLoader color="#3d7cbf" size={24} />}
      </Flexed>
    </div>
  );
}

ProjectsInfo.propTypes = propTypes;

const Flexed = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  @media (max-width: 767.98px) {
    justify-content: center;
  }
`;

export default ProjectsInfo;
