import PropTypes from 'prop-types';
import React from 'react';
import DataTable from 'react-data-table-component';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link, Route, Switch } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import styled from 'styled-components';

import { ruleTypes } from '../../../constants/rbac-rules';
import AddFileExclusionForm from '../../../containers/FileExclusionRules/AddFileExclusion';
import EditFileExclusionForm from '../../../containers/FileExclusionRules/EditFileExclusion';
import Authorization from '../../base/Authorization';
import { Can } from '../../base/Can';
import { Dialog } from '../../base/Dialog';
import PlusIcon from '../../base/icons/PlusIcon';
import { DataTableContainer } from '../ProjectFlags/styles';
import { customStyles } from '../ProjectFlags/table-contents';
import { ButtonRow, Loader } from '../styles';

const Admin = Authorization(['admin', 'superAdmin']);
const parentRoute = '/admin/file-exclusion-rules';
const text = {
  title: 'File Exclusion Rules',
  sub: 'Prevent certain users from accessing project files based on a defined term.',
  add: 'Add New Rule',
};

const FileExclusionRules = props => {
  return (
    <>
      <Header className="c-permissions-header">
        <HeaderTitle className="c-permissions-header__title">{text.title}</HeaderTitle>
        <HeaderSubtitle className="c-permissions-header__description">{text.sub}</HeaderSubtitle>
      </Header>
      <ButtonRow className="c-button-row c-button-row--notabtop" active={props.loading}>
        {props.loading && (
          <Loader>
            <PuffLoader color="#3d7cbf" size={24} />
            <div>Loading...</div>
          </Loader>
        )}
        <Can
          role={props.role}
          perform={ruleTypes.FILE_EXCLUSIION_RULES__ADD}
          yes={() => (
            <Link to={`${parentRoute}/add`} className="c-button c-button--hollow c-button--small">
              <PlusIcon className="c-button__icon" />
              <span>{text.add}</span>
            </Link>
          )}
        />
      </ButtonRow>
      <DataTableContainer>
        <DataTable
          noHeader
          columns={props.columns}
          data={props.rules.toJS()}
          customStyles={styles.dataTable}
        />
      </DataTableContainer>
      {props.isDeleteDialogOpen && (
        <Dialog
          title="Delete a File Exclusion Rule"
          subtitle={`Rule with term: "${props.ruleToDelete?.term}"`}
          body="Are you sure you want to delete this rule?"
          handleClose={props.cancelDelete}
          handleCancel={props.cancelDelete}
          handleConfirm={props.handleDelete}
          confirmDisabled={props.deleting}
          confirmLoading={props.deleting}
        />
      )}
      <Switch>
        <Route path={`${props.match.url}/add`} component={Admin(AddFileExclusionForm)} />
        <Route path={`${props.match.url}/:id/edit`} component={Admin(EditFileExclusionForm)} />
      </Switch>
    </>
  );
};

FileExclusionRules.propTypes = {
  cancelDelete: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  deleting: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isDeleteDialogOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  role: PropTypes.string.isRequired,
  rules: ImmutablePropTypes.list.isRequired,
};

const Header = styled.div``;

const HeaderTitle = styled.h2``;

const HeaderSubtitle = styled.p``;

const styles = {
  dataTable: {
    ...customStyles,
    cells: {
      style: {
        ...customStyles.cells.style,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
};

export default FileExclusionRules;
