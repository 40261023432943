import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as accountsActionCreators from '../actions/accounts';
import Accounts from '../components/AccountPanel/Permissions/Accounts';

const propTypes = {
  actions: PropTypes.shape({
    accounts: PropTypes.shape({
      getExpandedOrganizations: PropTypes.func.isRequired,
    }),
  }),
};

class ConnectedAccounts extends Component {
  componentDidMount() {
    this.props.actions.accounts.getExpandedOrganizations();
  }

  render() {
    return <Accounts {...this.props} />;
  }
}

ConnectedAccounts.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    isActive: state.accounts.get('isActive'),
    organizations: state.accounts.get('organizations'),
    currentUserRole: state.authentication.get('role'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      accounts: bindActionCreators(accountsActionCreators, dispatch),
    },
  };
}

export { ConnectedAccounts as Accounts, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedAccounts);
