import PropTypes from 'prop-types';
import React from 'react';
import { UnmountClosed as Collapse } from 'react-collapse';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';

import { CollapsibleItem } from './components/CollapsibleItem';

const propTypes = {
  /** List of filters  */
  filters: ImmutablePropTypes.list.isRequired,
  /** Whether or not the collapsible is open */
  open: PropTypes.bool.isRequired,
};

/**
 * A presentational component for the inner collapsible of filters panel
 * Refer to: https://github.com/nkbt/react-collapse
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const InnerCollapsible = props => {
  return (
    <Container>
      <Collapse isOpened={props.open}>
        <InnerContainer>
          {props.filters.filter(item => item.get('values').size != 0).map(item => {
            const label = item.get('label');
            const code = item.get('code');
            const values = item.get('values');
              return (
                <CollapsibleItem key={`${label}_${code}`} title={label} code={code} values={values} />
              );
          })}
        </InnerContainer>
      </Collapse>
    </Container>
  );
};

InnerCollapsible.propTypes = propTypes;

// margin and breakpoints from: c-projects__header
const Container = styled.div`
  .ReactCollapse--collapse {
    border: 1px solid lightgrey;
    border-top: 1px solid #fff;
    transition: height 150ms;
  }
`;

const InnerContainer = styled.div`
  padding: 10px 10px 13px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 0.65rem;
`;
