/**
 * Gets error message from server
 * @param {Error} error - the error object caught by the request
 * @returns {string}
 */
export function getServerError(error) {
  return error.response.data?.error || error.response.data;
}

/**
 * Gets the generic error message
 * @param {string} type - "add"
 * @param {string} service - "attribute"
 * @returns {string}
 */
export function getGenericError(type, service) {
  return `Failed to ${type} ${service}`;
}

/**
 * Evaluates both server error and generic error
 * @param {Error} error - the error object caught by the request
 * @param {string} type - e.g. add, update, delete
 * @param {string} service - e.g. project flag, attribute
 * @returns {string}
 */
export function getRequestError({ error, type = 'request', service = 'service' }) {
  return getServerError(error) || getGenericError(type, service);
}
