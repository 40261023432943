import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parse } from 'query-string';
import * as authenticationActionCreators from '../actions/authentication';
import * as filtersActionCreators from '../actions/filters';
import Header from '../components/base/Header';

const propTypes = {
  actions: PropTypes.object,
  headers: ImmutablePropTypes.map,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  role: PropTypes.string,
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function mapStateToProps(state) {
  return {
    headers: state.authentication.get('headers'),
    name: state.authentication.get('name'),
    role: state.authentication.get('role'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  let actions = {};
  if (ownProps.actions) {
    actions = {
      ...ownProps.actions,
    };
  }
  return {
    actions: {
      authentication: bindActionCreators(authenticationActionCreators, dispatch),
      filters: bindActionCreators(filtersActionCreators, dispatch),
      ...actions,
    },
  };
}

function wrapWithAuthenticatedApp(options = {}) {
  return function (WrappedComponent) {
    class AuthenticatedApp extends Component {
      componentDidMount() {
        this.props.actions.authentication.updateCurrentUser();
      }

      componentWillReceiveProps(nextProps) {
        const hasNewHeaders = this.props.headers && !nextProps.headers;
        const hasNewPath = this.props.location.pathname !== nextProps.location.pathname;
        const hasNewQueryParams = this.props.location.search !== nextProps.location.search;

        if (hasNewHeaders) {
          this.props.location.push('/sign-in');
        }

        if (hasNewPath || hasNewQueryParams) {
          this.props.actions.authentication.updateCurrentUser();
        }
      }

      render() {
        return (
          <div>
            <Header
              actions={this.props.actions}
              router={{
                location: {
                  query: parse(this.props.location.search),
                },
                push: this.props.history.push,
              }}
              userInfo={{
                name: this.props.name,
                role: this.props.role,
              }}>
              {options.headerContent}
            </Header>
            <WrappedComponent {...this.props} />
          </div>
        );
      }
    }

    AuthenticatedApp.displayName = `AuthenticatedApp(${getDisplayName(WrappedComponent)})`;
    AuthenticatedApp.propTypes = propTypes;

    return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedApp);
  };
}

export { getDisplayName, mapDispatchToProps, mapStateToProps, wrapWithAuthenticatedApp };
