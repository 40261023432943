import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { searchIcon } from '../../../assets/images';

const propTypes = {
  actions: PropTypes.object,
  // Nonexistent in ReactRouter v4+, but we'll pass the prop in this shape
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        q: PropTypes.string,
      }).isRequired,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBarVisible: false,
    };

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleContainerOpenStatus = this.toggleContainerOpenStatus.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isBarVisible && this.state.isBarVisible) {
      window.addEventListener('click', this.handleOutsideClick, true);
    } else if (prevState.isBarVisible && !this.state.isBarVisible) {
      window.removeEventListener('click', this.handleOutsideClick, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleOutsideClick, true);
  }

  handleOutsideClick(event) {
    const isContainerClick = event.target.className.indexOf('c-search-bar__container') > -1;
    const isInputClick = event.target.className.indexOf('c-search-bar__input') > -1;
    const isSubmitClick = event.target.className.indexOf('c-search-bar__button') > -1;
    const isTriggerClick = event.target.className.indexOf('c-search-bar__trigger-image') > -1;

    if (isContainerClick || isInputClick || isSubmitClick || isTriggerClick) {
      return;
    }

    this.setState({ isBarVisible: false });
  }

  handleSubmit(event) {
    event.preventDefault();

    const searchValue = this.searchField.value && this.searchField.value.trim();

    if (searchValue) {
      this.setState({ isBarVisible: false });
      this.searchField.value = '';
      this.props.router.push({ pathname: '/', search: `?q=${searchValue}` });
      this.props.actions.filters.filter({ search: searchValue });
      this.props.actions.filters.setAppliedFilters(`?q=${searchValue}`);
    }
  }

  toggleContainerOpenStatus() {
    this.setState(prevState => {
      return { isBarVisible: !prevState.isBarVisible };
    });
  }

  render() {
    const searchBarContainerClasses = classNames({
      'c-search-bar__container': true,
      'c-search-bar__container--open': this.state.isBarVisible,
    });

    return (
      <form className="c-header-actions__action c-search-bar" onSubmit={this.handleSubmit}>
        <a className="c-search-bar__trigger" onClick={this.toggleContainerOpenStatus}>
          <img
            alt="Open/Close SearchBar"
            className="c-search-bar__trigger-image"
            src={searchIcon}
          />
        </a>
        <div className={searchBarContainerClasses}>
          <input
            className="c-search-bar__input"
            placeholder="Search Projects and Organizations"
            ref={searchField => (this.searchField = searchField)}
            type="search"
          />
          <img className="c-search-bar__button" onClick={this.handleSubmit} src={searchIcon} />
        </div>
      </form>
    );
  }
}

SearchBar.propTypes = propTypes;

export default SearchBar;
