import { getRequestError } from '../utils/errorHelpers';
import { responseStatusIs } from '../utils/formErrorHelpers';
import apiService from './api';

const service = 'project flag';

function getProjectFlags() {
  return apiService.get('/project-flags').then(({ data }) => data.projectFlags);
}

function addProjectFlag({ customer, colorCode, label, code, isEnabled }) {
  return apiService
    .post('/project-flags', { customer, colorCode, label, code, isEnabled })
    .then(({ data }) => data.projectFlag)
    .catch(error => {
      if (responseStatusIs(error.response, 500)) {
        if (error.response.data.includes('E11000')) {
          throw new Error(`Project flag already exists!`);
        }
      }
      const err = getRequestError({ error, type: 'add', service });
      throw new Error(err);
    });
}

function deleteProjectFlag(id) {
  return apiService.delete(`/project-flags/${id}`).catch(error => {
    const err = getRequestError({ error, type: 'delete', service });
    throw new Error(err);
  });
}

function updateProjectFlag(id, { label, colorCode, isEnabled }) {
  return apiService
    .patch(`/project-flags/${id}`, { label, colorCode, isEnabled })
    .then(({ data }) => data.projectFlag)
    .catch(error => {
      const err = getRequestError({ error, type: 'update', service });
      throw new Error(err);
    });
}

function getSingleProjectFlag(id) {
  return apiService
    .get(`/project-flags/${id}`)
    .then(({ data }) => data.projectFlag)
    .catch(error => {
      const err = getRequestError({ error, type: 'get', service });
      throw new Error(err);
    });
}

export default {
  getProjectFlags,
  addProjectFlag,
  deleteProjectFlag,
  updateProjectFlag,
  getSingleProjectFlag,
};
