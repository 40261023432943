import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ImmutableProptypes from 'react-immutable-proptypes';
import usersService from '../../../../services/users';

const propTypes = {
  currentUserRole: PropTypes.string,
  location: PropTypes.string.isRequired,
  pushRoute: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  user: ImmutableProptypes.contains({
    id: PropTypes.string
  })
};

class LineItemUserManagement extends Component {
  constructor() {
    super();

    this.handleLink = this.handleLink.bind(this);
  }

  handleLink(managementLink) {
    return (e) => {
      e.preventDefault();
      this.props.pushRoute(managementLink);

      return usersService
        .findUserById(this.props.user.get('_id'))
        .then((user) => {
          this.props.setSelectedUser(user);
        });
    };
  }

  render() {
    const { currentUserRole, location, user } = this.props;
    const managementLink = `${location}/user/${user.get('_id')}`;
    const deleteLink = `${location}/user/${user.get('_id')}/delete`;

    return (
      <div className="c-line-item__management">
        <Link to={managementLink} onClick={this.handleLink(managementLink)}>
          <b>Manage Accounts</b>
        </Link>
        {currentUserRole === 'superAdmin' &&
          <Link className="c-line-item__delete" to={deleteLink} onClick={this.handleLink(deleteLink)}>
            Delete
          </Link>}
      </div>
    );
  }
}

LineItemUserManagement.propTypes = propTypes;

export default LineItemUserManagement;
