import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = new Immutable.Map({
  adminOrganizations: new Immutable.Set(),
  userOrganizations: new Immutable.Set()
});

function invitation(state = INITIAL_STATE, action) {
  switch (action.type) {
  case actionTypes.ADD_ALL_INVITE_ADMIN_ORGS: {
    const actionOrgs = action.payload.orgs.map((org) => org.get('id'));
    return state.withMutations((map) => {
      map.set('adminOrganizations', new Immutable.Set(actionOrgs));
      map.set('userOrganizations', new Immutable.Set(actionOrgs));
    });
  }

  case actionTypes.ADD_ALL_INVITE_USER_ORGS:
    return state.set('userOrganizations', new Immutable.Set(action.payload.orgs.map((org) => org.get('id'))));

  case actionTypes.ADD_INVITE_ADMIN_ORG:
    return state.withMutations((map) => {
      map.update('adminOrganizations', (set) => set.add(action.payload.id));
      map.update('userOrganizations', (set) => set.add(action.payload.id));
    });

  case actionTypes.ADD_INVITE_USER_ORG:
    return state.update('userOrganizations', (set) => set.add(action.payload.id));

  case actionTypes.CLEAR_INVITE:
    return INITIAL_STATE;

  case actionTypes.REMOVE_INVITE_ADMIN_ORG:
    return state.update('adminOrganizations', (set) => set.delete(action.payload.id));

  case actionTypes.REMOVE_INVITE_USER_ORG:
    return state.withMutations((map) => {
      map.update('adminOrganizations', (set) => set.delete(action.payload.id));
      map.update('userOrganizations', (set) => set.delete(action.payload.id));
    });

  case actionTypes.REMOVE_ALL_INVITE_ADMIN_ORGS:
    return state.update('adminOrganizations', (set) => set.clear());

  case actionTypes.SIGN_IN_START:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}

export default invitation;
