/**
 * Returns true if one of the items in [touched] map is touched (or true);
 * @param {object} touched - example: { fistName: true, lastName: false }
 */
export function isAnyTouched(touched) {
  const keys = Object.keys(touched);
  const arr = keys.map(key => touched[key]);
  return arr.some(i => i);
}

/**
 * Returns an array of errors from [errors] object
 * @param {object} errors - example: { firstName: 'Error here' }
 */
export function getErrors(errors) {
  return Object.keys(errors).map(key => errors[key]);
}
