import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import AttributeForm from '../../components/AccountPanel/Attributes/AttributeForm.jsx';
import * as attributesActionCreators from '../../actions/attributes';
import attributeService from '../../services/attributes';
import { states } from '../../constants/route-states';

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  actions: PropTypes.shape({
    attributes: PropTypes.shape({
      getAttributes: PropTypes.func.isRequired,
    }),
  }),
};

class ConnectedAddAttribute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      parentRoute: '/admin/attributes',
    };

    this.userClickedClose = this.userClickedClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  userClickedClose(state) {
    const { parentRoute } = this.state;
    this.props.history.push(parentRoute, state);
  }

  handleSubmit(values) {
    this.setState({ isActive: true });

    attributeService
      .addAttribute(values)
      .then(() => {
        toast.success('Attribute added!');
        this.setState(
          () => ({ isActive: false }),
          () =>
            this.userClickedClose({
              status: states.TRIGGER_REFRESH,
            })
        );
      })
      .catch(error => {
        this.setState({ isActive: false });
        toast(error.message, { type: 'error' });
      });
  }

  render() {
    return (
      <AttributeForm
        {...this.props}
        state={this.state}
        userClickedClose={this.userClickedClose}
        handleSubmit={this.handleSubmit}
        initialValues={{ code: '', label: '', isEnabled: true }}
        subtitle="Use fields below to add an additional attribute."
      />
    );
  }
}

ConnectedAddAttribute.propTypes = propTypes;

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: {
    attributes: bindActionCreators(attributesActionCreators, dispatch),
  },
});

export { ConnectedAddAttribute as AddAttribute, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedAddAttribute);
