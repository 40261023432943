import actionTypes from '../constants/actionTypes';
import attributesService from '../services/attributes';

function getAttributesStart() {
  return {
    type: actionTypes.GET_ATTRIBUTES_START,
  };
}

function getAttributesSuccess(attributes) {
  return {
    type: actionTypes.GET_ATTRIBUTES_SUCCESS,
    payload: { attributes },
  };
}

function getAttributesFailure(err) {
  return {
    type: actionTypes.GET_ATTRIBUTES_FAILURE,
    error: true,
    payload: err,
  };
}

function deleteAttributesStart() {
  return {
    type: actionTypes.DELETE_ATTRIBUTES_START,
  };
}

function deleteAttributesSuccess(deletedAttribute) {
  return {
    type: actionTypes.DELETE_ATTRIBUTES_SUCCESS,
    payload: { deletedAttribute },
  };
}

function deleteAttributesFailure(err) {
  return {
    type: actionTypes.DELETE_ATTRIBUTES_FAILURE,
    error: true,
    payload: err,
  };
}

function getAttributes() {
  return function (dispatch) {
    dispatch(getAttributesStart());

    return attributesService
      .getAttributes()
      .then(attributes => dispatch(getAttributesSuccess(attributes)))
      .catch(err => dispatch(getAttributesFailure(err)));
  };
}

function deleteAttribute(id) {
  return function (dispatch) {
    dispatch(deleteAttributesStart());

    return attributesService
      .deleteAttribute(id)
      .then(deletedAttrib => dispatch(deleteAttributesSuccess(deletedAttrib)))
      .catch(err => dispatch(deleteAttributesFailure(err)));
  };
}

export { getAttributes, deleteAttribute };
