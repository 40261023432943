import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';

import { formatLastUpdated } from '../../utils/dates';
import ProtectedImage from '../base/ProtectedImage';
import ProjectFlagDropdown from '../../containers/ProjectFlags/ProjectFlagDropdown';

const propTypes = {
  project: ImmutablePropTypes.contains({
    boxId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    organization: ImmutablePropTypes.contains({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function ProjectCard({ project }) {
  return (
    <Container>
      <ReactTooltip place="top" type="dark" effect="solid" />
      <Link to={`/projects/${project.get('boxId')}`}>
        <ProjectImage url={`/projects/${project.get('boxId')}/image`} />
      </Link>
      <MetaContainer>
        <ProjectName to={`/projects/${project.get('boxId')}`}>
          {project.getIn(['metadata', 'projectname']) || '-'}
        </ProjectName>
        <Row style={styles.rowStyle}>
          <Col width="50%">
            <Organization>{project.getIn(['organization', 'name'])}</Organization>
            <Flexed>
              <CustomerProjectNumber>
                {project.getIn(['metadata', 'customerprojectnumber']) || '-'}
              </CustomerProjectNumber>
              <InfoIcon
                data-tip={`Customer Project Number: ${
                  project.getIn(['metadata', 'customerprojectnumber']) || '-'
                }`}>
                <BsFillInfoCircleFill size={12} />
              </InfoIcon>
            </Flexed>
          </Col>
          <Col width="50%">
            <ProjectFlagDropdown project={project} />
          </Col>
        </Row>
        <Footer>
          <LastUpdated>Last Updated: {formatLastUpdated(project.get('lastUpdated'))}</LastUpdated>
          <ProjectNumber>{project.get('name')}</ProjectNumber>
        </Footer>
      </MetaContainer>
    </Container>
  );
}

ProjectCard.propTypes = propTypes;

const styles = {
  rowStyle: { marginTop: 6 },
};

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
`;

const ProjectName = styled(Link)`
  font-size: 18px;
  color: black;
  letter-spacing: 0.2px;
  font-weight: bold;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 5px;
  &:hover {
    color: #3d7cbf;
  }
`;

const Organization = styled.div`
  color: black;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1px;
`;

const CustomerProjectNumber = styled.div`
  color: black;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
`;

const ProjectNumber = styled.div`
  font-size: 16px;
`;

const LastUpdated = styled.div`
  color: #0e0e0e;
  font-size: 14px;
`;

const InfoIcon = styled.div`
  margin-left: 4px;
  display: flex;
  margin-bottom: 0.825px; // bwisit
  opacity: 0.5;
`;

// override some of the styles from transpiled css
const Container = styled.div`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 8px;
  border-radius: 0;
  overflow: visible;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProjectImage = styled(ProtectedImage)`
  margin-bottom: 10px;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 -2.85rem 3rem -2.85rem rgba(0, 0, 0, 0.1);
  height: 300px;
  width: 100%;
  &:hover {
    opacity: 0.7;
  }
`;

const Footer = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
`;

const Flexed = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  height: 30px;
`;

export default ProjectCard;
