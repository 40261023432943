import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ImmutableProptypes from 'react-immutable-proptypes';
import invitationService from '../../../../services/invitations';
import InvitationEmail from './InvitationEmail';
import InvitationAccounts from './InvitationAccounts';
import InvitationError from './InvitationError';
import Modal from '../../../base/Modal';
import usersService from '../../../../services/users';
import XIcon from '../../../base/icons/XIcon';

const propTypes = {
  actions: PropTypes.shape({
    userDetails: PropTypes.shape({
      setSelectedUser: PropTypes.func.isRequired,
    }),
    users: PropTypes.shape({
      getUsers: PropTypes.func.isRequired,
    }),
  }),
  adminOrganizations: ImmutableProptypes.list,
  label: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  organizations: ImmutableProptypes.list,
  role: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const defaultProps = {
  label: 'User',
};

function createManagedOrgs(role, organizations, adminOrganizations) {
  return role === 'superAdmin' ? organizations : adminOrganizations;
}

function getParentRoute(pathname) {
  const stringLoc = pathname.search('/invitation');
  return pathname.slice(0, stringLoc);
}

const pages = {
  1: {
    title: label => `Invite New ${label}`,
    subtitle: () =>
      'Enter the email address of the person you would like to invite to the Sonoco Platform',
    component: InvitationEmail,
  },
  2: {
    title: () => 'Select Organizations',
    subtitle: email => (
      <span>
        Access for: <b>{email}</b>
      </span>
    ),
    component: InvitationAccounts,
  },
  3: {
    title: () => 'Duplicate Invite!',
    subtitle: email => (
      <span>
        Please encourage <b>{email}</b> to check their email and complete registration.
      </span>
    ),
    component: InvitationError,
  },
  4: {
    title: () => 'Invite Error!',
    subtitle: () => 'Something went wrong in the invite creation!',
    component: InvitationError,
  },
};

class Invitation extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    this.handleExistingUser = this.handleExistingUser.bind(this);
    this.handleOrganizationsSubmit = this.handleOrganizationsSubmit.bind(this);

    /*
     * PLEASE READ!
     * This multi-page form eventually outputs an email and organizations as one
     * object in this.handleOrganizationsSubmit(). Originally all of this was
     * handled here in component state, but once the organization (and adminOrgs)
     * handling got sufficiently complex, I moved the state to a reducer.
     * It would be a super refactor to move the email to match.
     * Either way, for now:
     * Page 1: Email Input => email saved here in state
     * Page 2: Organization Checkboxes => userOrgs and adminOrgs saved in
     *         invitation reducer
     * Pages 3 & 4: Errors
     */

    this.state = {
      email: '',
      error: '',
      isActive: false,
      page: 1,
      parentRoute: getParentRoute(props.location.pathname),
    };
  }

  handleClose() {
    if (this.props.location.pathname.indexOf('/invitation') > -1) {
      this.props.history.push(this.state.parentRoute);
    }
  }

  handleExistingUser(user) {
    this.props.actions.userDetails.setSelectedUser(user);
    const location = `${this.state.parentRoute}/user/${user._id}`;
    this.props.history.push(location);
  }

  handleEmailSubmit({ email }) {
    this.setState({ isActive: true });

    return usersService
      .findUserByEmail(email)
      .then(user => {
        switch (user.type) {
          case 'invitation':
            this.setState({
              email,
              isActive: false,
              page: 2,
            });
            break;
          case 'user':
            this.setState({ isActive: false });
            this.handleExistingUser(user);
            break;
          default:
            this.setState({
              email,
              isActive: false,
              page: 4,
            });
        }
      })
      .catch(() => {
        this.setState({
          email,
          isActive: false,
          page: 2,
        });
      });
  }

  handleOrganizationsSubmit(adminOrganizations = [], userOrganizations = []) {
    const newUser = {
      adminOrganizations,
      userOrganizations,
      email: this.state.email,
      role: 'user',
    };

    if (adminOrganizations.length > 0) {
      newUser.role = 'admin';
    }

    this.setState({ isActive: true });

    return invitationService
      .invite(newUser)
      .then(this.handleClose)
      .then(this.props.actions.users.getUsers)
      .catch(error => {
        this.setState({
          error: error.message,
          isActive: false,
          page: 4,
        });
      });
  }

  render() {
    const { adminOrganizations, label, organizations, role } = this.props;
    const { error, email, page } = this.state;
    const selected = pages[page];

    // Super Admins can manage all organizations
    const managedOrgs = createManagedOrgs(role, organizations, adminOrganizations);

    return (
      <Modal handleClose={this.handleClose}>
        <div className="c-island">
          <Link to={this.state.parentRoute} className="c-island__exit">
            <XIcon />
          </Link>
          <div className="c-island__header">
            <h3 id="invite-title">{selected.title(label)}</h3>
            <span id="invite-subtitle">{selected.subtitle(email)}</span>
          </div>
          <div className="c-island__body">
            <selected.component
              {...this.props}
              cancelLocation={this.state.parentRoute}
              error={error}
              isActive={this.state.isActive}
              managedOrgs={managedOrgs}
              onSubmit={page === 1 ? this.handleEmailSubmit : this.handleOrganizationsSubmit}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

Invitation.defaultProps = defaultProps;
Invitation.propTypes = propTypes;

export { pages };
export default Invitation;
