function responseErrorMsgIs(response, expectedErrorMsg) {
  return response && response.data && response.data.error === expectedErrorMsg;
}

function responseStatusIs(response, expectedStatus) {
  return response && response.status === expectedStatus;
}

function setErrors(props) {
  return props.anyTouched && props.error ? props.error : [];
}

export {
  responseErrorMsgIs,
  responseStatusIs,
  setErrors
};
