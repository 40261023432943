import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import ProjectFlagForm from '../../components/AccountPanel/ProjectFlags/ProjectFlagForm';
import service from '../../services/project-flags';
import { states } from '../../constants/route-states';

const parentRoute = '/admin/project-flags';
const init = {
  customer: 'default',
  code: '',
  label: '',
  colorCode: '#3D7CBF',
  isEnabled: true,
};

const ConnectedEditProjectFlag = props => {
  const [loading, setLoading] = useState(false);
  const [projectFlag, setProjectFlag] = useState(init);

  const organizations = useSelector(({ accounts }) => accounts.get('organizations'));
  const isLoadingOrganizations = useSelector(({ accounts }) => accounts.get('isActive'));
  const { id: flagId } = useParams();

  // Load project flag to edit
  useEffect(() => {
    (async () => {
      setLoading(true);
      service
        .getSingleProjectFlag(flagId)
        .then(flag => {
          setLoading(false);
          setProjectFlag(flag);
        })
        .catch(e => {
          setLoading(false);
          toast.error(e.message);
        });
    })();
  }, [flagId]);

  function handleClose(state) {
    props.history.push(parentRoute, state);
  }

  function handleSubmit(values) {
    setLoading(true);
    service
      .updateProjectFlag(flagId, values)
      .then(() => {
        setLoading(false);
        toast.success('Project flag updated!');
        handleClose({ status: states.TRIGGER_REFRESH });
      })
      .catch(error => {
        setLoading(false);
        toast.error(error.message);
      });
  }

  return (
    <ProjectFlagForm
      {...props}
      type="edit"
      title="Edit Project Flag"
      subtitle="Use fields below to edit project flag."
      submitText="Save"
      initialValues={projectFlag}
      organizations={organizations}
      loading={loading || isLoadingOrganizations}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};

export default ConnectedEditProjectFlag;
