import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Forbidden } from './components/403';
import ScrollToTop from './components/base/ScrollToTop';
import Authorization from './components/base/Authorization';
import PrivateRoute from './components/base/PrivateRouter';
import AccountPanel from './components/AccountPanel';
import ConditionsOfAccess from './containers/ConditionsOfAccess';
import ForgotPassword from './containers/ForgotPassword';
import ProjectDetail from './containers/ProjectDetail';
import Projects from './containers/Projects';
import Register from './containers/Register';
import RegistrationComplete from './components/RegistrationComplete';
import ResetPassword from './containers/ResetPassword';
import SignIn from './containers/SignIn';

// Route roles config
const Admin = Authorization(['superAdmin', 'admin']);

function routes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <PrivateRoute exact path="/" component={Projects} />
        <PrivateRoute path="/projects/:boxId" component={ProjectDetail} />
        <Route path="/sign-in" component={SignIn} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route exact path="/register" component={Register} />
        <PrivateRoute path="/register/complete" component={RegistrationComplete} />
        <PrivateRoute path="/conditions-of-access" component={ConditionsOfAccess} />
        {/* AccountPanel contains nested routes */}
        <PrivateRoute path="/admin" component={Admin(AccountPanel)} />

        <Route path="/403" component={Forbidden} />
      </Switch>
    </BrowserRouter>
  );
}

export default routes;
