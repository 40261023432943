import actionTypes from '../constants/actionTypes';
import usersService from '../services/users';

function getUsersStart() {
  return {
    type: actionTypes.GET_USERS_START,
  };
}

function getUsersSuccess(users) {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    payload: { users },
  };
}

function getUsersFailure(err) {
  return {
    type: actionTypes.GET_USERS_FAILURE,
    error: true,
    payload: err,
  };
}

function clearUsers() {
  return {
    type: actionTypes.CLEAR_USERS,
    payload: {},
  };
}

function getUsers() {
  return function (dispatch) {
    dispatch(getUsersStart());

    return usersService
      .getUsers()
      .then(users => dispatch(getUsersSuccess(users)))
      .catch(err => dispatch(getUsersFailure(err)));
  };
}

export { clearUsers, getUsers };
