import actionTypes from '../constants/actionTypes';
import projectService from '../services/projects';
import { toast } from 'react-toastify';

function getProjectDetailsByIdStart() {
  return {
    type: actionTypes.GET_PROJECT_DETAILS_START,
  };
}

function getProjectDetailsByIdSuccess(projectDetails) {
  return {
    type: actionTypes.GET_PROJECT_DETAILS_SUCCESS,
    payload: { projectDetails },
  };
}

function getProjectDetailsByIdFailure(err) {
  return {
    type: actionTypes.GET_PROJECT_DETAILS_FAILURE,
    error: true,
    payload: err,
  };
}

function updateProjectStart() {
  return {
    type: actionTypes.UPDATE_PROJECT_START,
  };
}

function updateProjectSuccess(projectDetails) {
  return {
    type: actionTypes.UPDATE_PROJECT_SUCCESS,
    payload: { projectDetails },
  };
}

function updateProjectFailure(err) {
  return {
    type: actionTypes.UPDATE_PROJECT__FAILURE,
    error: true,
    payload: err,
  };
}

function getProjectDetailsById(id) {
  return function (dispatch) {
    dispatch(getProjectDetailsByIdStart());

    return projectService
      .getProjectDetailsById(id)
      .then(project => dispatch(getProjectDetailsByIdSuccess(project)))
      .catch(err => dispatch(getProjectDetailsByIdFailure(err)));
  };
}

function updateProjectById(id, payload, options = { confirm: false, showError: false }) {
  return function (dispatch) {
    dispatch(updateProjectStart());

    return projectService
      .updateProjectById(id, payload)
      .then(project => {
        dispatch(updateProjectSuccess(project));
        if (options.confirm) toast.success('Project updated!');
      })
      .catch(err => {
        if (options.showError) toast.error(err.message);
        dispatch(updateProjectFailure(err));
      });
  };
}

function getProjectFilesStart() {
  return {
    type: actionTypes.GET_PROJECT_FILES_START,
  };
}

function getProjectFilesSuccess(files) {
  return {
    type: actionTypes.GET_PROJECT_FILES_SUCCESS,
    payload: { files },
  };
}

function getProjectFilesFailure(err) {
  return {
    type: actionTypes.GET_PROJECT_FILES_FAILURE,
    error: true,
    payload: err,
  };
}

function getProjectFiles(id) {
  return function (dispatch) {
    dispatch(getProjectFilesStart());

    return projectService
      .getProjectFiles(id)
      .then(files => dispatch(getProjectFilesSuccess(files)))
      .catch(err => dispatch(getProjectFilesFailure(err)));
  };
}

function getProjectFiltersStart() {
  return {
    type: actionTypes.GET_PROJECT_FILTERS_START,
  };
}

function getProjectFiltersSuccess(filters) {
  return {
    type: actionTypes.GET_PROJECT_FILTERS_SUCCESS,
    payload: { filters },
  };
}

function getProjectFilters() {
  return function (dispatch) {
    dispatch(getProjectFiltersStart());

    return projectService
      .getProjectFilters()
      .then(filters => dispatch(getProjectFiltersSuccess(filters)));
  };
}

function getProjectRenderingByIdStart() {
  return {
    type: actionTypes.GET_PROJECT_RENDERING_START,
  };
}

function getProjectRenderingByIdSuccess(embedUrl) {
  return {
    type: actionTypes.GET_PROJECT_RENDERING_SUCCESS,
    payload: { embedUrl },
  };
}

function getProjectRenderingByIdFailure(err) {
  return {
    type: actionTypes.GET_PROJECT_RENDERING_FAILURE,
    error: true,
    payload: err,
  };
}

function getProjectRenderingById(id) {
  return function (dispatch) {
    dispatch(getProjectRenderingByIdStart());

    return projectService
      .getProjectRenderingById(id)
      .then(embedUrl => dispatch(getProjectRenderingByIdSuccess(embedUrl)))
      .catch(err => dispatch(getProjectRenderingByIdFailure(err)));
  };
}

function getProjectsStart() {
  return {
    type: actionTypes.GET_PROJECTS_START,
  };
}

function getProjectsSuccess({ page, pages, projects, total }) {
  return {
    type: actionTypes.GET_PROJECTS_SUCCESS,
    payload: {
      page,
      pages,
      projects,
      total,
    },
  };
}

function getProjectsFailure(err) {
  return {
    type: actionTypes.GET_PROJECTS_FAILURE,
    error: true,
    payload: err.response?.data,
  };
}

function getProjects(options) {
  return function (dispatch) {
    dispatch(getProjectsStart());

    return projectService
      .getProjects(options)
      .then(projects => dispatch(getProjectsSuccess(projects)))
      .catch(err => dispatch(getProjectsFailure(err)));
  };
}

function resetProjectDetails() {
  return {
    type: actionTypes.RESET_PROJECT_DETAILS,
  };
}

function resetProjectFiles() {
  return {
    type: actionTypes.RESET_PROJECT_FILES,
  };
}

function resetProjectRendering() {
  return {
    type: actionTypes.RESET_PROJECT_RENDERING,
  };
}

export {
  getProjectDetailsById,
  getProjectFiles,
  getProjectFilters,
  getProjectRenderingById,
  getProjects,
  resetProjectDetails,
  resetProjectFiles,
  resetProjectRendering,
  updateProjectById,
  getProjectsStart,
  getProjectsSuccess,
  getProjectsFailure,
};
