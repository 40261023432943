import React from 'react';
import {
  Link
} from 'react-router-dom';
import Header from './base/Header';
import MessageCard from './base/MessageCard';

function RegistrationComplete() {
  return (
    <div className="l-sign-in">
      <Header />
      <MessageCard>
        Your account has been created and you are currently being to added to your organizations. This process may take a few minutes.
        <hr />
        <Link to="/">Sign in</Link>
      </MessageCard>
    </div>
  );
}

export default RegistrationComplete;
