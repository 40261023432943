import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { FilterTag } from './components/FilterTag';

const propTypes = {
  /** The complete list of filter tags extracted from search params */
  tags: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** The list of tags but limited based on screens size */
  filterTags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Whether or not more filter tags are shown */
  isMoreShown: PropTypes.bool.isRequired,
  /** Whether or not the screen is considered as small */
  isScreenSmall: PropTypes.bool.isRequired,
  /** A function toggle show more or show less */
  toggleShowMore: PropTypes.func.isRequired,
};

/**
 * List of filter tags based on search params
 * @param {*} props
 */
export const FilterTags = props => {
  return (
    <Container>
      {props.filterTags.map(tag => (
        <FilterTag key={tag.key} field={tag.field} label={tag.label} value={tag.value} />
      ))}
      {props.tags.length > 3 && props.isScreenSmall && (
        <ButtonContainer>
          <Button onClick={props.toggleShowMore}>
            {props.isMoreShown ? 'Show less' : 'Show more'}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

FilterTags.propTypes = propTypes;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 577.98px) {
    margin-bottom: 3px;
    margin-right: -8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Button = styled.button`
  outline: none;
  padding: 8px 0;
`;
