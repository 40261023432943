import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FiX } from 'react-icons/fi';
import { Col, Row } from 'react-grid-system';
import _ from 'lodash';

import { getFilteredOptions } from './utils';
import { setErrors } from '../../../utils/formErrorHelpers';
import { Button, ButtonGroup, FormErrors, HollowButtonText } from './styles.js';
import { ConnectedVanillaDropdown, ConnectedVanillaInput } from '../../base/fields/connected-forms';
import { ruleTypes } from '../../../constants/rbac-rules';
import { Can } from '../../base/Can';
import { HiddenGap, MetadataContainer } from '../styles';
import styled from 'styled-components';

const propTypes = {
  isActive: PropTypes.bool.isRequired,
  attributes: ImmutablePropTypes.list.isRequired,
  userClickedSave: PropTypes.func.isRequired,
};

/**
 * Dynamic attribute form
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DynamicAttributeForm = props => {
  const filteredOptions = getFilteredOptions(props.project, props.attributes);
  const formErrors = setErrors(props);
  const isAdmin = _.includes(['admin', 'superAdmin'], props.role);

  if (!filteredOptions.size) return null;
  return (
    <Can
      role={props.role}
      perform={ruleTypes.DYNAMIC_ATTRIBUTES__ADD}
      yes={() => {
        return props.formShown ? (
          <Form onSubmit={props.handleSubmit(props.userClickedSave)}>
            <MetadataContainer isAdmin={isAdmin}>
              <Row align="center">
                <Col lg={6}>
                  <Field
                    name="label"
                    component={ConnectedVanillaDropdown}
                    style={styles.derpdown}
                    options={filteredOptions.toJS()}
                  />
                  <HiddenGap height={8} />
                </Col>
                <Col lg={6}>
                  <Field
                    name="value"
                    component={ConnectedVanillaInput}
                    full={true}
                    bordered={true}
                  />
                </Col>
              </Row>
            </MetadataContainer>

            {/* Form errors */}
            {!!formErrors.length && (
              <FormErrors>
                {formErrors.map(error => (
                  <div key={error}>{error}</div>
                ))}
              </FormErrors>
            )}

            <ButtonGroup>
              <Button
                padding={10}
                className="c-button c-button--small c-button--hollow"
                onClick={() => {
                  props.reset();
                  props.userToggledForm();
                }}>
                <FiX size={16} />
              </Button>
              <Button
                className="c-button c-button--small"
                type="submit"
                disabled={props.pristine || props.submitting || props.invalid}>
                <HollowButtonText>Save</HollowButtonText>
              </Button>
            </ButtonGroup>
          </Form>
        ) : (
          <TriggerContainer>
            <Button
              className="c-button c-button--small c-button--hollow"
              onClick={props.userToggledForm}>
              Add Attribute
            </Button>
          </TriggerContainer>
        );
      }}
    />
  );
};

DynamicAttributeForm.propTypes = propTypes;

const styles = {
  derpdown: { fontWeight: 'bold', fontSize: 16 },
};

const Form = styled.form`
  margin-top: 4px;
  margin-bottom: 14px;
`;

const TriggerContainer = styled.div`
  margin-top: 4px;
  margin-bottom: 14px;
`;

export default DynamicAttributeForm;
