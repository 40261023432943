import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';
import _ from 'lodash';

import PlaceholderProjectCard from '../../Projects/PlaceholderProjectCard';
import ProjectFilesTable from './ProjectFilesTable';
import FilePreviewSwitch from '../../base/Switch';
import FileCard from './FileCard';
import { H1 } from '../styles';
import { filesPlaceholder } from '../../../assets/images';

const propTypes = {
  error: PropTypes.string,
  files: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      lastUpdated: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  isActive: PropTypes.bool,
};

export class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filesShowing: 6,
      activeDisplayType: 'thumbnail',
    };

    this.handleClick = this.handleClick.bind(this);
    this.switchActiveDisplayType = this.switchActiveDisplayType.bind(this);
  }

  handleClick() {
    this.setState({ ...this.state, filesShowing: this.props.files.count() });
  }

  switchActiveDisplayType(type) {
    this.setState({ ...this.state, activeDisplayType: type });
  }

  render() {
    const hasError = !this.props.isActive && this.props.error;
    const errorPointers =
      hasError && this.props.error.response?.data
        ? this.props.error.response.data.error.pointers.map(pointer => pointer.param)
        : [];
    const hasNoFiles =
      hasError && errorPointers.length > 0
        ? errorPointers.indexOf('id') > -1 || errorPointers.indexOf('communicationsFolderId') > -1
        : !this.props.isActive &&
          !this.props.error &&
          this.props.files &&
          this.props.files.size === 0;
    const items = [
      { label: 'Thumbnail', callback: () => this.switchActiveDisplayType('thumbnail') },
      { label: 'List', callback: () => this.switchActiveDisplayType('list') },
    ];

    return (
      <div className="c-associated-files">
        <HeaderContainer>
          {!hasError && !hasNoFiles && <FilePreviewSwitch items={items} />}
          <HeadingContainer>
            <H1>ASSOCIATED FILES</H1>
          </HeadingContainer>
        </HeaderContainer>

        {hasError || hasNoFiles ? (
          <HasErrorContainer>
            {hasNoFiles ? (
              <FilesPlaceholder src={filesPlaceholder} alt="no documents" />
            ) : (
              <h6 className="c-associated-files__error">
                Sorry, there was an error loading this content. Please try again.
              </h6>
            )}
          </HasErrorContainer>
        ) : this.state.activeDisplayType === 'thumbnail' ? (
          <div className="l-card-grid">
            {this.props.isActive
              ? _.times(6, i => <PlaceholderProjectCard key={i} />)
              : this.props.files.map((file, i) => {
                  if (i >= this.state.filesShowing) {
                    return null;
                  }
                  return (
                    <FileCard key={file.get('boxId')} file={file}>
                      <h5>{file.get('name')}</h5>
                    </FileCard>
                  );
                })}
          </div>
        ) : (
          <ProjectFilesTable files={this.props.files} />
        )}

        {this.props.files.count() > this.state.filesShowing &&
          this.state.activeDisplayType === 'thumbnail' && (
            <ButtonContainer>
              <button
                className="c-button c-button--small c-button--hollow c-button--block c-associated-files__link"
                onClick={this.handleClick}>
                View All Files
              </button>
            </ButtonContainer>
          )}
      </div>
    );
  }
}

Files.propTypes = propTypes;

const HeaderContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  position: relative;
  flex: 1;

  margin-bottom: 2rem;
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: 475px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  @media (max-width: 575.98px) {
    position: unset;
    flex-direction: column-reverse;
  }
`;

const HeadingContainer = styled.div`
  flex: 1;
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin-bottom: 2rem;
`;

const HasErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 72px;
`;

const FilesPlaceholder = styled.img`
  width: 20rem;
  align-self: center;
`;

export default Files;
