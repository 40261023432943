import actionTypes from '../constants/actionTypes';
import projectFlagsService from '../services/project-flags';

function _getFlagsStart() {
  return {
    type: actionTypes.GET_PROJECT_FLAGS_START,
  };
}

function _getFlagsSuccess(projectFlags) {
  return {
    type: actionTypes.GET_PROJECT_FLAGS_SUCCESS,
    payload: { projectFlags },
  };
}

function _getFlagsFailure(error) {
  return {
    type: actionTypes.GET_PROJECT_FLAGS_FAILURE,
    error: true,
    payload: error,
  };
}

export function addProjectFlag(values) {
  return function (dispatch) {
    return projectFlagsService.addProjectFlag(values);
  };
}

export function getProjectFlags() {
  return function (dispatch) {
    dispatch(_getFlagsStart());

    return projectFlagsService
      .getProjectFlags()
      .then(flags => {
        // sort project flag by label
        const sorted = flags.sort((a, b) => a.label.localeCompare(b.label));
        dispatch(_getFlagsSuccess(sorted));
      })
      .catch(error => dispatch(_getFlagsFailure(error)));
  };
}

export function deleteProjectFlag(id) {
  return function (dispatch) {
    return projectFlagsService.deleteProjectFlag(id);
  };
}
