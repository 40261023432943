import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  organizations: new Immutable.List(),
  adminOrganizations: new Immutable.List()
});

function accounts(state = INITIAL_STATE, action) {
  switch (action.type) {
  case actionTypes.GET_ORGANIZATIONS_START:
    return state.withMutations((map) => {
      map.delete('error');
      map.set('isActive', true);
    });

  case actionTypes.GET_ORGANIZATIONS_SUCCESS:
    return state.withMutations((map) => {
      map.set('isActive', false);
      map.set('organizations', Immutable.fromJS(action.payload.organizations));
      map.set('adminOrganizations', Immutable.fromJS(action.payload.adminOrganizations));
    });

  case actionTypes.GET_ORGANIZATIONS_FAILURE:
    return state.withMutations((map) => {
      map.set('error', action.payload);
      map.set('isActive', false);
    });

  case actionTypes.SIGN_IN_START:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}

export default accounts;
