/**
 * Filters out attributes that exist in current project.
 * @param project - the project immutable object fresh from the store
 * @param attributes - list of attributes fresh from the store
 * @returns [{ value: 'UNDUPLICATED_CODE', label: 'Unduplicated Code' }]
 */
export const getFilteredOptions = (project, attributes) => {
  const projectAttributes = project.get('attributes');
  const enabledAttributes = attributes.filter(attrib => attrib.get('isEnabled'));

  // attribute keys that's currently in the project
  const [...projectKeys] = projectAttributes?.keys() || [];

  const allOptions = enabledAttributes.map(attrib => ({
    value: attrib.get('code'),
    label: attrib.get('label'),
  }));

  // attributes w/o the existing ones in the project
  const filteredOptions = allOptions.filter(option => !projectKeys.includes(option.value));

  return filteredOptions;
};
