import styled from 'styled-components';

// --color-accent: var(--color-blue);
// --color-blue: #3d7cbf;
// --color-blue-dark: #254689;
// --color-default: var(--color-gray);
// --color-gray-very-light: #f5f5f5;
// --color-gray-light: #d7d7d7;
// --color-gray-mild: #9b9b9b;
// --color-gray: #5a6a73;
// --color-gray-dark: #4a4a4a;
// --color-outline: rgb(76, 154, 255);
// --color-red: #d0011b;

export const colors = {
  accent: '#3d7cbf',
  blue: '#3d7cbf',
  blueDark: '#254689',
  default: '#5a6a73',
  grayVeryLight: '#f5f5f5',
  grayLight: '#d7d7d7',
  grayMild: '#9b9b9b',
  gray: '#5a6a73',
  grayDark: '#4a4a4a',
  outline: '#4C9AFF',
  red: '#d0011b',
  green: '#38d414',
};

export const Gap = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;
