import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';

import { wrapWithAuthenticatedApp } from '../../containers/AuthenticatedApp';
import CollapsibleFilters from '../../containers/CollapsibleFilters';
import CollapsibleOverview from './CollapsibleOverview';
import PlaceholderProjectCard from './PlaceholderProjectCard';
import ProjectCard from './ProjectCard';
import ProjectsError from './ProjectsError';
import ProjectsPagination from './ProjectsPagination';

const propTypes = {
  currentPage: PropTypes.number,
  filters: ImmutablePropTypes.map,
  error: PropTypes.string,
  isActive: PropTypes.bool,
  projects: ImmutablePropTypes.list.isRequired,
  totalPages: PropTypes.number,
  totalProjects: PropTypes.number,
};

/**
 * A list of projects
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Projects(props) {

  const projectsWithoutImages = [];
  const projectsWithImages = [];

  for (const project of props.projects) {
    if ((project.get('imageId') || null) === null) {
      projectsWithoutImages.push(project);
    } else {
      projectsWithImages.push(project);
    }
  }

  const projects = [...projectsWithImages, ...projectsWithoutImages];

  return (
    <div className="l-projects">
      <CollapsibleOverview />

      <CollapsibleFilters {...props} />

      <ProjectsError
        error={props.error}
        isVisible={!props.isActive}
        projectCount={props.totalProjects}
        visibleProjectsCount={props.projects.size}
      />

      <ProjectCards>
        {props.isActive
          ? _.times(12, i => <PlaceholderProjectCard key={i} />)
          : projects
              .map(project => {
                return <ProjectCard key={project.get('_id')} project={project} />;
              })}
      </ProjectCards>

      {props.currentPage && props.totalPages && (
        <ProjectsPagination currentPage={props.currentPage} totalPages={props.totalPages} />
      )}
    </div>
  );
}

Projects.propTypes = propTypes;

const ProjectCards = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 2rem;

  @media (min-width: 475px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  @media (max-width: 767.98px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 577.98px) {
    grid-template-columns: 1fr;
  }
`;

export default wrapWithAuthenticatedApp()(Projects);
