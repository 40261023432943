import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import service from '../../services/projects';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getProjectFlags } from '../../actions/projectFlags';
import ProjectFlagsSelect from '../../components/base/fields/ProjectFlagsSelect';

/**
 * A HoC that shows a dropdown list of project flags available for a project.
 * This HoC supports updating a project's flag.
 * @param {Object} project - a project associated to the project flag
 * @returns {JSX.Element}
 * @constructor
 */

const ConnectedProjectFlagDropdown = ({ project }) => {
  const dispatch = useDispatch();
  const flags = useSelector(state => state.projectFlags.get('projectFlags'));

  const parsed = flags
    .toJS()
    .map(flag => {
      return {
        label: flag.label,
        color: flag.colorCode,
        value: flag._id,
        customer: flag.customer,
        isDisabled: !flag.isEnabled,
      };
    })
    .filter(flag => {
      return (
        flag.customer.toUpperCase() === 'default'.toUpperCase() ||
        flag.customer === project.getIn(['organization', 'boxId'])
      );
    });

  const defaultValueIndex = _.findIndex(parsed, p => p.value === project.get('projectFlagId'));

  /**
   * Updates the project flag Id of the project.
   * @param {String} projectFlagId - the id of the project flag
   * @returns {Promise<void>}
   */
  const updateProjectFlag = async projectFlagId => {
    try {
      await service.updateProjectById(project.get('boxId'), {
        projectFlagId: projectFlagId,
      });
      // update project flags globally (summary, chart, etc.)
      dispatch(getProjectFlags());
      toast('Project Flag updated successfully!', { type: 'success' });
    } catch (err) {
      toast(err.message || 'Error updating Project Flag!', { type: 'error' });
    }
  };

  return (
    <ProjectFlagsSelect
      onChange={updateProjectFlag}
      defaultValue={parsed[defaultValueIndex]}
      options={parsed}
    />
  );
};

ConnectedProjectFlagDropdown.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ConnectedProjectFlagDropdown;
