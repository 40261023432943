import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';
import Dropdown from 'react-dropdown';
import { List } from 'immutable';
import { FiChevronDown as ArrowDown, FiChevronUp as ArrowUp } from 'react-icons/fi';

export const vanillaDropdownPropTypes = {
  value: PropTypes.string,
  onChangeText: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    ImmutablePropTypes.listOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  ]),
  style: PropTypes.object,
  disabled: PropTypes.bool,
  initialized: PropTypes.bool,
};

const defaultProps = {
  options: [],
};

/**
 * A dropdown component that uses react-dropdown for consistency
 * @param {*} props
 * @returns {JSX.Element}
 * @constructor
 */
export const VanillaDropdown = props => {
  const initial = { value: '', label: 'Select an option' };
  const options = props.initialized ? [initial, ...props.options] : props.options;

  const longestOption = props.options?.reduce(
    (longest, current) => (current.label.length > longest.label.length ? current : longest),
    initial
  );

  const optionsLib = {};
  options.forEach(option => {
    optionsLib[option.value] = option;
  });

  return (
    <SelectContainer
      className={props.className}
      style={props.style}
      length={longestOption.label.length}>
      <Dropdown
        disabled={props.disabled}
        arrowClosed={
          <CustomArrow>
            <ArrowDown />
          </CustomArrow>
        }
        arrowOpen={
          <CustomArrow>
            <ArrowUp />
          </CustomArrow>
        }
        placeholder={initial.label}
        options={options}
        value={optionsLib[props.value]}
        onChange={data => props.onChangeText(data.value)}
      />
    </SelectContainer>
  );
};

VanillaDropdown.propTypes = vanillaDropdownPropTypes;

VanillaDropdown.defaultProps = defaultProps;

const SelectContainer = styled.div`
  .Dropdown-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const CustomArrow = styled.span`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);

  svg {
    fill: none;
  }
`;
