import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Col, Row, useScreenClass } from 'react-grid-system';
import { Field } from 'redux-form';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { Can } from '../base/Can';
import { ruleTypes } from '../../constants/rbac-rules';
import { projectInformationFields } from '../../containers/ProjectInformationList';
import { ConnectedJDatePicker, ConnectedJInput } from '../base/fields/connected-forms';
import { Regular, MetadataContainer, HiddenGap, FieldItem } from './styles';
import { checkIfSame, formatLastUpdated } from '../../utils/dates';

const propTypes = {
  details: ImmutablePropTypes.contains({
    metadata: ImmutablePropTypes.contains({
      budget: PropTypes.string,
      displayclassification: PropTypes.string,
      productcategory: PropTypes.string,
      retailchannel: PropTypes.string,
      retailer: PropTypes.string,
      typeofdisplay: PropTypes.string,
      duedate: PropTypes.string,
      customerprojectnumber: PropTypes.string,
    }),
  }).isRequired,
  /** Role of the current user */
  role: PropTypes.string.isRequired,
  /** Function to update current project */
  updateProject: PropTypes.func.isRequired,
};

export function ProjectInformationList(props) {
  const { details, role, updateProject } = props;

  const screenClass = useScreenClass();

  const isScreenSmall = _.includes(['xs', 'sm', 'md'], screenClass);
  const isAdmin = _.includes(['superAdmin', 'admin'], role);

  return (
    <MetadataContainer isAdmin={isAdmin}>
      {projectInformationFields.map((field, index) => {
        const value = details.getIn(['metadata', field.key]);
        const isLast = index === projectInformationFields.length - 1;

        return (
          <Row key={field.key} align="center">
            <Col lg={6}>
              <Regular bold>{field.title}</Regular>
              <HiddenGap height={4} />
            </Col>
            <Col lg={6}>
              <Can
                role={role}
                perform={ruleTypes.PROJECT_INFO__EDIT}
                yes={() => (
                  <FieldItem isScreenSmall={isScreenSmall}>
                    {field.key === 'duedate' ? (
                      <Field
                        name={field.key}
                        component={ConnectedJDatePicker}
                        full={isScreenSmall}
                        placeholder="-"
                        initialValue={props.initialValues[field.key]}
                        onFieldSubmit={date => {
                          if (checkIfSame(date.toJSON(), value)) return;
                          updateProject({ metadata: { [field.key]: date } });
                        }}
                      />
                    ) : (
                      <Field
                        name={field.key}
                        component={ConnectedJInput}
                        full={isScreenSmall}
                        placeholder="-"
                        initialValue={value}
                        onFieldSubmit={data => {
                          if (data === value) return;
                          updateProject({ metadata: { [field.key]: data } });
                        }}
                      />
                    )}
                  </FieldItem>
                )}
                no={() => (
                  <Regular>
                    {field.key === 'duedate'
                      ? !!value // if there is a duedate
                        ? formatLastUpdated(value)
                        : '-'
                      : value || '-'}
                  </Regular>
                )}
              />
              {!isLast && <HiddenGap height={12} />}
            </Col>
          </Row>
        );
      })}
    </MetadataContainer>
  );
}

ProjectInformationList.propTypes = propTypes;
