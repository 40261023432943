import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { parse } from 'query-string';

import { setErrors } from '../utils/formErrorHelpers';
import formValidationService from '../services/formValidation';
import FlashErrors from './base/FlashErrors';
import Header from './base/Header';
import MessageCard from './base/MessageCard';
import TextInput from './base/TextInput';

const propTypes = {
  actions: PropTypes.shape({
    authentication: PropTypes.shape({
      register: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      change: PropTypes.func.isRequired,
      initialize: PropTypes.func.isRequired,
    }),
  }),
  anyTouched: PropTypes.bool,
  error: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
    // nonexistent in ReactRouter v4+
    // query: PropTypes.shape({
    //   invitationId: PropTypes.string,
    //   email: PropTypes.string,
    // }).isRequired,
  }).isRequired,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

class Register extends Component {
  constructor() {
    super();

    this.register = this.register.bind(this);
  }

  register({ name, password }) {
    const query = parse(this.props.location.search);
    const invitationId = decodeURIComponent(query.invitationId);

    return this.props.actions.authentication
      .register(invitationId, name, password)
      .then(() => {
        this.props.history.replace({
          pathname: '/conditions-of-access',
          search: this.props.location.search,
        });
      })
      .catch(err => {
        this.props.actions.form.change('register', 'passwordConfirmation', '');
        this.props.actions.form.initialize('register', null, true);

        throw new SubmissionError({
          _error: [err.message],
        });
      });
  }

  validateFields(values) {
    return new formValidationService.Validation()
      .name(values.name)
      .password(values.password)
      .passwordMatches(values.password, values.passwordConfirmation)
      .getErrors();
  }

  render() {
    const { invitationId, email } = parse(this.props.location.search);

    return (
      <div className="l-sign-in">
        <Header />
        {(() => {
          if (!(invitationId && email)) {
            return (
              <MessageCard>
                Bad Registration Link!
                <hr />
                Please contact your administrator.
              </MessageCard>
            );
          }

          return (
            <div className="c-card">
              <h2 className="c-card__title">Register for Sonoco Platform</h2>
              <form className="c-form" onSubmit={this.props.handleSubmit(this.register)}>
                <div className="c-form__group">
                  <label className="c-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="c-form__control"
                    id="email"
                    type="email"
                    defaultValue={decodeURIComponent(email)}
                    disabled
                  />
                </div>
                <Field component={TextInput} label="Name" name="name" type="text" />
                <Field component={TextInput} label="Password" name="password" type="password" />
                <Field
                  component={TextInput}
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  type="password"
                />
                <input
                  className="c-button c-button--block"
                  disabled={this.props.isActive}
                  type="submit"
                  value="Complete Registration"
                />
                <FlashErrors errors={setErrors(this.props)} />
              </form>
            </div>
          );
        })()}
      </div>
    );
  }
}

Register.propTypes = propTypes;

export { Register };

export default reduxForm({ form: 'register', validate: Register.prototype.validateFields })(
  Register
);
