import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

function LineItemError({ subtitle, title }) {
  return (
    <li className="c-line-item">
      <div className="c-line-item__label">
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </li>
  );
}

LineItemError.propTypes = propTypes;

export default LineItemError;
