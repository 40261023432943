import classNames from 'classnames';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import { ITEM_TYPES } from 'react-ultimate-pagination';
import { formatNumberByThousands } from '../../utils/numbers';

const propTypes = {
  isActive: PropTypes.bool,
  query: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.number,
};

class ProjectsPaginationItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  buildLinkContent(type, value) {
    switch (type) {
      case ITEM_TYPES.ELLIPSIS:
        return '\u2026';
      case ITEM_TYPES.NEXT_PAGE_LINK:
        return 'Next';
      case ITEM_TYPES.PREVIOUS_PAGE_LINK:
        return 'Prev';
      default:
        return formatNumberByThousands(value);
    }
  }

  buildNextPath(props) {
    const { type, value, history } = props;

    if (type === ITEM_TYPES.ELLIPSIS) return;

    // { attribute: 'foo,bar', page: 1 }
    const params = { ...parse(history.location.search), page: value };
    // '?attribute=foo,bar&page=1'
    const search = Object.entries(params)
      .map(([field, keyword]) => `${field}=${encodeURIComponent(keyword)}`)
      .join('&');

    return {
      pathname: '/',
      search: `?${search}`,
    };
  }

  handleClick() {
    findDOMNode(this.link).blur();
  }

  render() {
    const className = classNames({
      'c-projects-pagination__item': true,
      'c-projects-pagination__item--active':
        this.props.type === ITEM_TYPES.PAGE && this.props.isActive,
      'c-projects-pagination__item--disabled':
        (this.props.type === ITEM_TYPES.NEXT_PAGE_LINK ||
          this.props.type === ITEM_TYPES.PREVIOUS_PAGE_LINK) &&
        this.props.isActive,
    });

    return (
      <Link
        ref={link => {
          this.link = link;
        }}
        className={className}
        onClick={this.handleClick}
        to={this.buildNextPath(this.props) || ''}>
        {this.buildLinkContent(this.props.type, this.props.value)}
      </Link>
    );
  }
}

ProjectsPaginationItem.propTypes = propTypes;

export default withRouter(ProjectsPaginationItem);
