import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string
};

function XIcon({ className }) {
  const classes = classNames('c-icon c-icon--x', { [className]: className });

  return (
    <svg className={classes} viewBox="0 0 12 12">
      <g transform="rotate(45 6 6)">
        <rect height="2" width="12" x="0" y="5" />
        <rect height="12" width="2" x="5" y="0" />
      </g>
    </svg>
  );
}

XIcon.propTypes = propTypes;

export default XIcon;
