import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as filterActionCreators from '../actions/filters';
import * as projectActionCreators from '../actions/projects';
import Projects from '../components/Projects';
import { transformParams } from '../utils/filterHelpers';


const propTypes = {
  actions: PropTypes.shape({
    projects: PropTypes.shape({
      getProjectFilters: PropTypes.func.isRequired,
      getProjects: PropTypes.func.isRequired,
    }),
    filters: PropTypes.shape({
      filter: PropTypes.func.isRequired,
      setAppliedFilters: PropTypes.func.isRequired,
    }),
  }),
};

class ConnectedProjects extends Component {
  componentDidMount() {
    this.filterProjects(this.props.location.search);
  }

  componentWillReceiveProps(nextProps) {
    const oldQueryParams = parse(this.props.location.search);
    const newQueryParams = parse(nextProps.location.search);
    const isNewPage = oldQueryParams.page !== newQueryParams.page;

    if (isNewPage) {
      this.filterProjects(nextProps.location.search);
    }
  }

  filterProjects(searchParams) {
    const params = parse(searchParams);
    const transformedParams = transformParams(params);

    this.props.actions.filters.setAppliedFilters(searchParams);
    this.props.actions.filters.filter({
      page: params.page || 1,
      search: params.q,
      ...transformedParams,
    });
  }

  render() {
    return <Projects {...this.props} />;
  }
}

ConnectedProjects.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    currentPage: state.projects.get('currentPage'),
    error: state.projects.get('error'),
    filters: state.projects.getIn(['filters', 'items']),
    isActive: state.projects.get('isActive'),
    isFiltersActive: state.projects.getIn(['filters', 'isActive']),
    projects: state.projects.get('items'),
    totalPages: state.projects.get('totalPages'),
    totalProjects: state.projects.get('totalProjects'),
    previousSearch: state.filters.get('appliedFilters'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      projects: bindActionCreators(projectActionCreators, dispatch),
      filters: bindActionCreators(filterActionCreators, dispatch),
    },
  };
}

export { ConnectedProjects as Projects, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedProjects);
