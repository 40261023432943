import PropTypes from 'prop-types';

import { JiraInput, jiraInputProps } from './JiraInput';
import { JiraSelect, jiraSelectProps } from './JiraSelect';
import { JiraDatePicker, jiraDatePickerProps } from './JiraDatePicker';
import { VanillaDropdown, vanillaDropdownPropTypes } from './VanilaDropdown';
import { VanillaInput, vanillaInputPropTypes } from './VanillaInput';

/**
 * Wraps fields to be usable as redux-form components
 * @param JiraFieldComponent
 * @example
 * ```
 * const ConnectedJInput = FormWrapper(JiraInput);
 * ...
 * <ConnectedJInput />
 * ```
 * @returns {function(*)}
 * @private
 */
const _FormWrapper = JiraFieldComponent => props => {
  const { input, meta, ...rest } = props;

  return (
    <JiraFieldComponent
      {...rest}
      type="controlled"
      value={input.value}
      onChangeText={input.onChange}
    />
  );
};

const formWrapperProps = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    form: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
  }).isRequired,
};

/**
 * @example Usage of the ff component
 * ```
 * import { Field } from 'redux-form';
 * ...
 * <Field
 *  name="field"
 *  component={ConnectedJInput}
 *  initialValue={props.initialValues.field}
 * />
 * ```
 *
 * see: ConnectedForm.example.jsx
 */

export const ConnectedJInput = _FormWrapper(JiraInput);
ConnectedJInput.propTypes = { ...formWrapperProps, ...jiraInputProps };

export const ConnectedJSelect = _FormWrapper(JiraSelect);
ConnectedJSelect.propTypes = { ...formWrapperProps, ...jiraSelectProps };

export const ConnectedJDatePicker = _FormWrapper(JiraDatePicker);
ConnectedJDatePicker.propTypes = { ...formWrapperProps, ...jiraDatePickerProps };

export const ConnectedVanillaDropdown = _FormWrapper(VanillaDropdown);
ConnectedVanillaDropdown.propTypes = { ...formWrapperProps, ...vanillaDropdownPropTypes };

export const ConnectedVanillaInput = _FormWrapper(VanillaInput);
ConnectedVanillaInput.propTypes = { ...formWrapperProps, ...vanillaInputPropTypes };
