import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FileExclusionForm } from '../../components/AccountPanel/FileExclusionRules/FileExclusionForm';
import { states } from '../../constants/route-states';
import fileExclusionRulesService from '../../services/file-exclusion-rules';

const ConnectedAddFileExclusionForm = props => {
  // states
  const [loading, setLoading] = useState(false);

  // store selectors
  const users = useSelector(({ users }) => users.get('users'));
  const loadingUsers = useSelector(({ users }) => users.get('isActive'));

  const userOptions = useMemo(() => {
    return users
      .filter(user => user.get('boxId'))
      .map(user => ({
        boxId: user.get('boxId'),
        email: user.get('email'),
        name: user.get('name'),
      }))
      .toJS();
  }, [users]);

  const handleSubmit = values => {
    setLoading(true);
    const payload = {
      term: values.term.trim(),
      bannedUserIds: Object.keys(values.usersToBan),
    };
    fileExclusionRulesService
      .addFileExclusionRule(payload)
      .then(fileExclusionRule => {
        props.history.push('/admin/file-exclusion-rules', {
          status: states.TRIGGER_REFRESH,
        });
        toast.success('File exclusion rule created');
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  return (
    <FileExclusionForm
      title="Add a new File Exclusion Rule"
      subtitle="Set a term to be identified in the document titles and select users who should not see documents with that term"
      submitText="Create"
      initialValues={{ term: '', usersToBan: {} }}
      handleSubmit={handleSubmit}
      loading={loading || loadingUsers}
      userOptions={userOptions}
    />
  );
};

export default ConnectedAddFileExclusionForm;
