import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    touched: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string,
  style: PropTypes.object,
};

const defaultProps = {
  type: 'text',
  disabled: false,
};

function TextInput(props) {
  const {
    input: { name, onChange, value },
    label,
    meta: { error, touched },
    type,
    disabled,
  } = props;
  const classes = classNames({
    'c-form__control': true,
    'c-form__control--error': touched && error,
  });

  return (
    <div className="c-form__group" style={props.style}>
      <label className="c-label" htmlFor={name}>
        {label}
      </label>
      <input
        name={name}
        className={classes}
        id={name}
        onChange={onChange}
        type={type}
        value={value}
        disabled={disabled}
      />
    </div>
  );
}

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default TextInput;
