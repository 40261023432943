import React, { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

/**
 * Refer to: https://reactrouter.com/web/guides/scroll-restoration
 */

function _scrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(_scrollToTop);
