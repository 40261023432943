import _ from 'lodash';
import { parse } from 'query-string';
import { filter, setAppliedFilters } from '../actions/filters';

/**
 * Sets a filter tag on the search params
 * @param {{
 *    location: object,
 *    history: object,
 *    key: string,
 *    keyword: string
 *  }} args
 */
export const setFilterTag = ({ location, history, field, keyword }) => {
  const params = parse(location.search);
  const keys = Object.keys(params); // ['attribute', 'flag'...]
  const newKeys = !_.includes(keys, field) ? [...keys, field] : keys;
  const currentFilters = params[field] || ''; // output: "foo,bar"

  // append keyword if not yet present in search params
  const filters = !_.includes(currentFilters, keyword)
    ? `${currentFilters}${currentFilters ? ',' : ''}${keyword}`
    : currentFilters;

  // output: ["attribute=hello,world", "flag=foo,bar"]
  const search = newKeys.map(key => {
    return `${key}=${
      key === field ? encodeURIComponent(filters) : encodeURIComponent(params[key])
    }`;
  });

  history.push({ search: search.join('&') });
};

/**
 * Removes tag from the search param
 * @param location - the location object (from react-router)
 * @param history - the history object (from react-router)
 * @param field - the field to filter e.g. attribute
 * @param keyword - the keyword to filter e.g. foo
 */
export const removeFilterTag = ({ location, history, field, keyword }) => {
  const params = parse(location.search);

  // transformedParams
  // end format: { attribute: 'foo,bar', flag: 'foo' }
  // 1. ignore non strings
  // 2. transform "foo,bar" to ['foo', 'bar']
  // 3. filter out [keyword]
  // 4. revert back to "foo,bar" format

  const transformedParams = {};
  Object.keys(params).forEach(key => {
    if (!_.isString(params[key])) return;
    const filters = params[key].split(',');
    const newFilters = key === field ? filters.filter(i => i !== keyword) : filters;
    const value = newFilters.join(',');
    if (value) transformedParams[key] = encodeURIComponent(value);
  });

  // format: ["attribute=foobar", "flag=foo,bar"]
  const search = Object.keys(transformedParams).map(k => `${k}=${transformedParams[k]}`);
  history.push({ search: `?${search.join('&')}` });
};

/**
 * A helper function that transforms parsed search params to API digestible request body.
 * input: { metadata.budget: "foo,bar"  }
 * output: { fixedAttributesFilter: { budget: ["foo", "bar"]  } }
 *
 * @param params
 * @param options
 * @returns {{dynamicAttributesFilter: {}, organizationsFilter: {}, fixedAttributesFilter: {}}}
 */
export const transformParams = params => {
  const transformedParams = {};

  Object.keys(params).forEach(key => {
    transformedParams[key] = params[key].split(',');
  });

  const dynamicAttributesFilter = {};
  const fixedAttributesFilter = {};
  let organizationsFilter = [];
  let projectFlagsFilter = [];

  Object.entries(transformedParams).forEach(([key, value]) => {
    const dotIndex = key.indexOf('.');
    const type = key.substring(0, dotIndex);
    const field = key.substring(dotIndex + 1, key.length);

    if (type === 'attributes') {
      dynamicAttributesFilter[field] = value;
    } else if (type === 'metadata') {
      fixedAttributesFilter[field] = value;
    } else if (type === 'organization') {
      organizationsFilter = [...organizationsFilter, ...value];
    } else if (type === 'projectFlags') {
      projectFlagsFilter = [...projectFlagsFilter, ...value];
    }
  });

  return {
    dynamicAttributesFilter,
    fixedAttributesFilter,
    organizationsFilter,
    projectFlagsFilter,
  };
};

/**
 * Clears filters
 * @param location
 * @param history
 * @param dispatch
 * @param previousSearch
 */
export const clearFilters = ({ location, history, dispatch, previousSearch }) => {
  const params = parse(location.search);

  history.push({ search: '' });

  if (previousSearch !== '') {
    dispatch(filter({ page: params.page }));
    dispatch(setAppliedFilters(''));
  }
};

/**
 * Applies filters
 * @param location
 * @param dispatch
 */
export const applyFilters = ({ location, dispatch }) => {
  const params = parse(location.search);

  const transformedParams = transformParams(params);
  const payload = { page: params.page || 1, search: params.q, ...transformedParams };

  dispatch(filter(payload));
  dispatch(setAppliedFilters(location.search));
};
