import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  isAgreeingToConditionsOfAccess: false
});

function authentication(state = INITIAL_STATE, action) {
  switch (action.type) {
  case actionTypes.ACCEPT_CONDITIONS_OF_ACCESS_START:
    return state.set('isAgreeingToConditionsOfAccess', true);

  case actionTypes.ACCEPT_CONDITIONS_OF_ACCESS_FAILURE:
    return state.set('isAgreeingToConditionsOfAccess', false);

  case actionTypes.ACCEPT_CONDITIONS_OF_ACCESS_SUCCESS:
  case actionTypes.UPDATE_CURRENT_USER:
    return state.withMutations((map) => {
      map.set('_id', action.payload.user._id);
      map.set('email', action.payload.user.email);
      map.set('hasAgreedToConditionsOfAccess', action.payload.user.hasAgreedToConditionsOfAccess);
      map.set('isAgreeingToConditionsOfAccess', false);
      map.set('name', action.payload.user.name);
      map.set('role', action.payload.user.role);
    });

  case actionTypes.SIGN_IN_START:
    return state.withMutations((map) => {
      map.set('isActive', true);
    });

  case actionTypes.SIGN_IN:
    return state.withMutations((map) => {
      map.set('_id', action.payload.authenticationInfo._id);
      map.set('email', action.payload.authenticationInfo.email);
      map.set('hasAgreedToConditionsOfAccess', action.payload.authenticationInfo.hasAgreedToConditionsOfAccess);
      map.set('headers', Immutable.fromJS(action.payload.authenticationInfo.headers));
      map.set('name', action.payload.authenticationInfo.name);
      map.set('role', action.payload.authenticationInfo.role);
      map.set('isActive', false);
    });

  case actionTypes.SIGN_IN_FAILURE:
    return state.withMutations((map) => {
      map.set('isActive', false);
    });

  case actionTypes.SIGN_OUT:
    return state.withMutations((map) => {
      map.delete('_id');
      map.delete('email');
      map.delete('hasAgreedToConditionsOfAccess');
      map.delete('headers');
      map.delete('name');
      map.delete('role');
    });

  case actionTypes.REQUEST_RESET_TOKEN_START:
    return state.withMutations((map) => {
      map.set('isActive', true);
    });

  case actionTypes.REQUEST_RESET_TOKEN_SUCCESS:
    return state.withMutations((map) => {
      map.set('isActive', false);
    });

  case actionTypes.REQUEST_RESET_TOKEN_FAILURE:
    return state.withMutations((map) => {
      map.set('isActive', false);
    });

  case actionTypes.REGISTER_START:
  case actionTypes.RESET_PASSWORD_START:
    return state.withMutations((map) => {
      map.set('isActive', true);
    });

  case actionTypes.REGISTER_SUCCESS:
  case actionTypes.RESET_PASSWORD_SUCCESS:
    return state.withMutations((map) => {
      map.set('_id', action.payload.authenticationInfo._id);
      map.set('email', action.payload.authenticationInfo.email);
      map.set('hasAgreedToConditionsOfAccess', action.payload.authenticationInfo.hasAgreedToConditionsOfAccess);
      map.set('headers', Immutable.fromJS(action.payload.authenticationInfo.headers));
      map.set('name', action.payload.authenticationInfo.name);
      map.set('role', action.payload.authenticationInfo.role);
      map.set('isActive', false);
    });

  case actionTypes.REGISTER_FAILURE:
  case actionTypes.RESET_PASSWORD_FAILURE:
    return state.withMutations((map) => {
      map.set('isActive', false);
    });

  default:
    return state;
  }
}

export default authentication;
