import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import XIcon from '../../base/icons/XIcon';
import Modal from '../../base/Modal';
import TextInput from '../../base/TextInput';
import FlashErrors from '../../base/FlashErrors';
import { regex } from '../../../constants/regex';
import { getErrors, isAnyTouched } from '../../../utils/formikHelpers';
import { CenteredContent } from '../styles';
import { ModalFooter } from '../../base/styled';

const propTypes = {
  type: PropTypes.oneOf(['edit', 'add']),
  /** Title of this form */
  title: PropTypes.string,
  /** Subtitle of this form */
  subtitle: PropTypes.string,
  /** Submit button text */
  submitText: PropTypes.string,
  /** states of the connected component */
  state: PropTypes.shape({
    parentRoute: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  /** fired if user closes the modal */
  userClickedClose: PropTypes.func.isRequired,
  /** fired if user clicks submit */
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  type: 'add',
  title: 'Add New Attribute',
  submitText: 'Create',
};

const AttributeSchema = Yup.object().shape({
  code: Yup.string()
    .max(50, 'Code must not exceed 50 characters')
    .matches(regex.CODE, 'Code must be uppercase in snake case (e.g. - HELLO_WORLD)')
    .required('Code is required'),
  label: Yup.string().max(50, 'Label must not exceed 50 characters').required('Label is required'),
});

const requiredFields = [
  { label: 'Code', name: 'code' },
  { label: 'Label', name: 'label' },
];

const AttributeForm = props => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.initialValues,
    onSubmit: props.handleSubmit,
    validationSchema: AttributeSchema,
  });

  return (
    <Modal handleClose={() => props.userClickedClose()}>
      <div className="c-island">
        <Link to={props.state.parentRoute} className="c-island__exit">
          <XIcon />
        </Link>

        <div className="c-island__header">
          <h3 id="invite-title">{props.title}</h3>
          <span id="invite-subtitle">{props.subtitle}</span>
        </div>

        <div className="c-island__body">
          <form className="c-form c-form--with-sticky-footer" onSubmit={formik.handleSubmit}>
            <div className="c-well">
              {requiredFields.map(({ name, label }) => (
                <TextInput
                  key={name}
                  label={label}
                  disabled={name === 'code' && props.type === 'edit'}
                  meta={{ error: formik.errors[name], touched: formik.touched[name] }}
                  input={{
                    name,
                    value: formik.values[name],
                    onChange: formik.handleChange,
                  }}
                />
              ))}

              <div className="c-attrib-checkbox-row">
                <input
                  className="c-attrib-checkbox-row__check"
                  id="enabled"
                  type="checkbox"
                  name="isEnabled"
                  checked={formik.values.isEnabled}
                  onChange={formik.handleChange}
                />
                <label className="c-label c-attrib-checkbox-row__label" htmlFor="enabled">
                  Do you want to enable this attribute?
                </label>
              </div>

              {/* form errors */}
              <FlashErrors errors={isAnyTouched(formik.touched) ? getErrors(formik.errors) : []} />
            </div>

            <ModalFooter className="c-button-row c-button-row--modal">
              <Link
                to={props.state.parentRoute}
                className="c-button c-button--small c-button--hollow">
                Cancel
              </Link>
              <button
                className="c-button c-button--small"
                type="submit"
                disabled={
                  // disable if mode is `edit` and there are no initial values
                  (props.type === 'edit' && !props.initialValues.code) || props.state.isActive
                }>
                {props.state.isActive ? (
                  <CenteredContent>
                    <BeatLoader color="#fff" size={8} />
                  </CenteredContent>
                ) : (
                  props.submitText
                )}
              </button>
            </ModalFooter>
          </form>
        </div>
      </div>
    </Modal>
  );
};

AttributeForm.propTypes = propTypes;

AttributeForm.defaultProps = defaultProps;

export default AttributeForm;
