import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import DynamicAttributeList from '../components/ProjectDetail/DynamicAttributes/DynamicAttributeList.jsx';
import { updateProjectById } from '../actions/projects';

const ConnectedForm = reduxForm({
  form: 'editDynamicAttributes',
  enableReinitialize: true,
})(DynamicAttributeList);

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  options: ImmutablePropTypes.list.isRequired,
  project: ImmutablePropTypes.contains({
    boxId: PropTypes.string,
  }).isRequired,
  updateProject: PropTypes.func.isRequired,
};

class ConnectedDynamicAttributesList extends React.Component {
  constructor(props) {
    super(props);

    this.getDictionary = this.getDictionary.bind(this);
    this.userUpdatedAttribute = this.userUpdatedAttribute.bind(this);
  }

  /**
   * Gets dictionary of options
   * @returns {{ EXAMPLE_CODE: "Label" }}
   */
  getDictionary() {
    const dictionary = {};

    this.props.options?.forEach(option => {
      const code = option.get('code');
      const label = option.get('label');

      dictionary[code] = label;
    });

    return dictionary;
  }

  /**
   * Update attribute inline
   * @param payload - shape: { EXAMPLE_CODE: 'value' }
   */
  userUpdatedAttribute(payload) {
    const boxId = this.props.project.get('boxId');

    this.props.updateProject(boxId, { attributes: payload });
  }

  render() {
    return (
      <ConnectedForm
        {...this.props}
        dictionary={this.getDictionary()}
        userUpdatedAttribute={this.userUpdatedAttribute}
      />
    );
  }
}

ConnectedDynamicAttributesList.propTypes = propTypes;

/**
 * Gets initial values of the edit form*
 * @param state
 * @returns {{ EXAMPLE_CODE: 'example' }}
 * @private
 */
const _getInitialValues = state => {
  const project = state.projectDetails.getIn(['details', 'values']);
  const attributes = project.get('attributes');
  const [...fields] = attributes?.keys() || [];

  const values = {};
  fields.forEach(field => {
    values[field] = attributes.get(field);
  });

  return values;
};

function mapStateToProps(state) {
  return {
    initialValues: _getInitialValues(state),
    isActive: state.attributes.get('isActive'),
    options: state.attributes.get('attributes'),
    project: state.projectDetails.getIn(['details', 'values']),
    role: state.authentication.get('role'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateProject: (boxId, payload) => dispatch(updateProjectById(boxId, payload)),
  };
}

export {
  ConnectedDynamicAttributesList as DynamicAttributesList,
  mapStateToProps,
  mapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedDynamicAttributesList);
