import React from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem, Wrapper } from 'react-aria-menubutton';

import DropdownCarat from '../DropdownCarat';
import { settingsIcon } from '../../../assets/images';
import SignOutModal from '../SignOutModal';

const propTypes = {
  actions: PropTypes.shape({
    authentication: PropTypes.shape({
      signOut: PropTypes.func.isRequired,
    }),
  }),
  // Nonexistent in ReactRouter v4+, but we'll pass the prop in this shape
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

function Dropdown({ actions, router, userInfo }) {
  const [signOutConfirmationOpen, setSignOutConfirmationOpen] = React.useState(false);

  function onSelection(options) {
    const nextPath = typeof options === 'string' ? options : options.nextPath;

    if (options.onSelect) {
      options.onSelect();
    }

    router.push(nextPath);
  }

  function adminPanelLink() {
    if (['superAdmin', 'admin'].indexOf(userInfo.role) > -1) {
      return (
        <MenuItem className="c-dropdown__link" value={{ nextPath: '/admin' }}>
          Admin Panel
        </MenuItem>
      );
    }
  }

  return (
    <>
      {signOutConfirmationOpen && (
        <SignOutModal
          cancelHandler={() => setSignOutConfirmationOpen(false)}
          nextHandler={actions.authentication.signOut}
        />
      )}

      <Wrapper className="c-header-actions__action c-dropdown__container" onSelection={onSelection}>
        <Button className="c-dropdown-trigger c-dropdown-trigger--header">
          <img
            alt="User Settings Dropdown"
            className="c-dropdown-trigger__icon"
            src={settingsIcon}
          />
          <span className="c-dropdown-trigger__label">{userInfo.name}</span>
          <DropdownCarat />
        </Button>

        <Menu className="c-dropdown c-dropdown--header">
          {adminPanelLink()}
          <MenuItem
            onClick={() => {
              setSignOutConfirmationOpen(true);
            }}
            className="c-button c-button--block c-button--hollow c-button--small">
            Sign Out
          </MenuItem>
        </Menu>
      </Wrapper>
    </>
  );
}

Dropdown.propTypes = propTypes;

export default Dropdown;
