import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as projectsActionCreators from '../actions/projects';
import ProjectDetail from '../components/ProjectDetail';

const propTypes = {
  actions: PropTypes.shape({
    projects: PropTypes.shape({
      getProjectDetailsById: PropTypes.func.isRequired,
      getProjectFiles: PropTypes.func.isRequired,
      getProjectRenderingById: PropTypes.func.isRequired,
      resetProjectDetails: PropTypes.func.isRequired,
      resetProjectFiles: PropTypes.func.isRequired,
      resetProjectRendering: PropTypes.func.isRequired,
      updateProjectById: PropTypes.func.isRequired,
    }),
  }),
  // Nonexistent in ReactRouter v4+
  // routeParams: PropTypes.shape({
  //   boxId: PropTypes.string.isRequired,
  // }),
};

class ConnectedProjectDetail extends Component {
  componentWillMount() {
    this.props.actions.projects.getProjectDetailsById(this.props.match.params.boxId);
    this.props.actions.projects.getProjectFiles(this.props.match.params.boxId);
    this.props.actions.projects.getProjectRenderingById(this.props.match.params.boxId);
  }

  componentWillUnmount() {
    this.props.actions.projects.resetProjectDetails();
    this.props.actions.projects.resetProjectFiles();
    this.props.actions.projects.resetProjectRendering();
  }

  render() {
    return <ProjectDetail {...this.props} />;
  }
}

ConnectedProjectDetail.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    details: state.projectDetails.getIn(['details', 'values']),
    detailsError: state.projectDetails.getIn(['details', 'error']),
    files: state.projectDetails.getIn(['files', 'items']),
    filesError: state.projectDetails.getIn(['files', 'error']),
    isDetailsActive: state.projectDetails.getIn(['details', 'isActive']),
    isFilesActive: state.projectDetails.getIn(['files', 'isActive']),
    isRenderingActive: state.projectDetails.getIn(['rendering', 'isActive']),
    renderingEmbedUrl: state.projectDetails.getIn(['rendering', 'embedUrl']),
    renderingError: state.projectDetails.getIn(['rendering', 'error']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      projects: bindActionCreators(projectsActionCreators, dispatch),
    },
  };
}

export { ConnectedProjectDetail as ProjectDetail, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedProjectDetail);
