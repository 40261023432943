import styled from 'styled-components';

export const DataTableContainer = styled.div`
  svg {
    fill: none;
  }
`;

export const HexContainer = styled.div`
  display: flex;
  line-height: 15px;
  flex-wrap: nowrap;
`;

export const HexBlock = styled.div`
  flex-shrink: 0;
  background-color: ${({ hex }) => hex};
  width: 14px;
  height: 14px;
  margin-right: 4px;
`;

export const Ellipsis = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
