import React, {
  Component
} from 'react';
import PropTypes from 'prop-types';
import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import * as accountsActionCreators from '../actions/accounts';
import * as invitationsActionCreators from '../actions/invitations';
import * as userDetailsActionCreators from '../actions/userDetails';
import * as usersActionCreators from '../actions/users';
import Invitation from '../components/AccountPanel/Permissions/Invitation';

const propTypes = {
  actions: PropTypes.shape({
    accounts: PropTypes.shape({
      getOrganizations: PropTypes.func.isRequired
    })
  })
};

class ConnectedInvitation extends Component {
  componentDidMount() {
    this.props.actions.accounts.getOrganizations();
  }

  render() {
    return <Invitation {...this.props} />;
  }
}

ConnectedInvitation.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    adminOrganizations: state.accounts.get('adminOrganizations'),
    invitation: state.invitation,
    organizations: state.accounts.get('organizations'),
    role: state.authentication.get('role')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      accounts: bindActionCreators(accountsActionCreators, dispatch),
      invitations: bindActionCreators(invitationsActionCreators, dispatch),
      userDetails: bindActionCreators(userDetailsActionCreators, dispatch),
      users: bindActionCreators(usersActionCreators, dispatch)
    }
  };
}

export {
  ConnectedInvitation as Invitation,
  mapStateToProps,
  mapDispatchToProps
};
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedInvitation);
