import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';

// colors
export const c = {
  HOVER: '#ebecf0',
  INPUT_BUTTON: '#f5f6f8',
  INPUT_BUTTON_ACTIVE: '#ebecf0',
  ACTIVE_BORDER: '#4c9aff',
};

// dimensions
export const d = {
  padding: 8,
  minHeight: 32,
  radius: 3,
  gap: 4,
};

export const Container = styled.div`
  svg {
    fill: none;
  }
`;

export const ButtonText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  text-align: left;
`;

export const Button = styled.button`
  width: 100%;
  outline: none;
  min-height: ${d.minHeight + 4}px; // + 4 is from 2px border
  padding: 0 ${d.padding}px;
  border-radius: ${d.radius}px;
  font-weight: ${({ weight = 'unset' }) => weight};
  font-size: 1rem;
  cursor: text;
  &:hover {
    background-color: ${c.HOVER};
  }
`;

export const ActiveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${c.ACTIVE_BORDER};
  border-radius: ${d.radius}px;

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  svg {
    fill: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${d.padding - 2}px; // 2px border

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

export const Input = styled.input`
  all: unset;
  margin: unset !important;
  min-height: ${d.minHeight}px;
  font-weight: ${({ weight = 'unset' }) => weight};
  font-size: 1rem;
  text-align: ${({ align = 'left' }) => align};

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;

export const InputButton = styled.button`
  min-height: ${d.minHeight}px;
  background-color: ${c.INPUT_BUTTON};
  padding: 0 ${d.padding}px;
  border-radius: ${d.radius}px;
  margin-left: ${d.gap}px;
  outline: none;

  &:hover {
    background-color: ${c.INPUT_BUTTON_ACTIVE};
  }
`;

export const IconButton = styled.button`
  outline: none;
  margin-left: ${d.padding}px;
`;

export const Select = styled.select`
  outline: none;
  margin: unset !important;
  font-family: 'proxima-nova', sans-serif;
  font-weight: ${({ weight = 'unset' }) => weight};
  font-size: 1rem;
  padding: 0 ${d.padding - 4}px; // 4 from 2px border
`;

export const Picker = styled(DatePicker)`
  outline: none;
  min-height: ${d.minHeight}px;
  font-family: 'proxima-nova', sans-serif;
  font-weight: ${({ weight = 'unset' }) => weight};
  font-size: 1rem;
  padding: 0 ${d.padding - 2}px; // 2px border
`;
