import _ from 'lodash';

const defaultErrorObj = {};

class Validation {
  constructor(errorObj = defaultErrorObj) {
    this.errorObj = { ...errorObj };

    if (errorObj._error && errorObj._error.slice) {
      this.errorObj._error = errorObj._error.slice();
    }
  }

  decorateErrorObject(type, message) {
    if (message) {
      this.errorObj._error = this.errorObj._error || [];
      this.errorObj._error.push(message);
      this.errorObj[type] = true;
    }

    return this;
  }

  email(value) {
    let errorMsg = null;

    if (!(value && value.trim())) {
      errorMsg = 'Email is required';
    } else if (!/^\S+@\S+$/.test(value.trim())) {
      errorMsg = 'Your email must contain a username and a domain (i.e. user@example.com)';
    }

    return this.decorateErrorObject('email', errorMsg);
  }

  getErrors() {
    return this.errorObj;
  }

  password(value) {
    let errorMsg = null;

    if (!(value && value.trim())) {
      errorMsg = 'Password is required';
    }

    return this.decorateErrorObject('password', errorMsg);
  }

  passwordMatches(password, passwordConfirmation) {
    let errorMsg = null;

    if (password !== passwordConfirmation) {
      errorMsg = 'Passwords do not match';
    }

    return this.decorateErrorObject('passwordConfirmation', errorMsg);
  }

  name(value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = 'Name is required';
    }

    return this.decorateErrorObject('name', errorMsg);
  }

  code(value) {
    let errorMsg = null;

    if (!(value && value.trim())) {
      errorMsg = 'Code is required';
    } else if (!/^[A-Z]+(?:_[A-Z]+)*$/.test(value)) {
      errorMsg = 'Code must be uppercase in snake case (e.g. - HELLO_WORLD)';
    }

    return this.decorateErrorObject('code', errorMsg);
  }

  required(name = 'field', value) {
    let errorMsg = null;

    if (!value) {
      errorMsg = `${_.startCase(name)} is required`;
    }

    return this.decorateErrorObject(name, errorMsg);
  }
}

export default {
  Validation,
};
