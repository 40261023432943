import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  error: PropTypes.string,
  isVisible: PropTypes.bool,
  projectCount: PropTypes.number,
  visibleProjectsCount: PropTypes.number,
};

function buildErrorMessage(error, projectCount, visibleProjectsCount) {
  if (error) {
    return error;
  } else if (projectCount > 0 && visibleProjectsCount === 0) {
    return 'Sorry, there are no projects on this page.';
  }

  return 'Sorry, no projects are available to view at the moment.';
}

function ProjectsError({ error, isVisible, projectCount, visibleProjectsCount }) {
  if ((!error && visibleProjectsCount > 0) || !isVisible) {
    return null;
  }

  return (
    <div className="c-projects__error">
      <h2>{buildErrorMessage(error, projectCount, visibleProjectsCount)}</h2>
    </div>
  );
}

ProjectsError.propTypes = propTypes;

export default ProjectsError;
