import styled from 'styled-components';

export const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.active ? 'space-between' : 'flex-end')};
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;

  & > :last-child {
    margin-left: 8px;
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;

  svg {
    fill: none;
  }

  table {
    border: 2px solid #d7d7d7;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 2px solid #d7d7d7;
      border-right: 2px solid #d7d7d7;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export const Header = styled.div`
  flex-wrap: nowrap;
`;
