import React from 'react';
import { Link } from 'react-router-dom';

import { backIcon } from '../../assets/images';

function ProjectDetailHeader() {
  return (
    <Link to="/" className="c-header-breadcrumbs__link">
      <img src={backIcon} alt="Back Arrow" />
      <h4>Home</h4>
    </Link>
  );
}

export default ProjectDetailHeader;
