import { getRequestError } from '../utils/errorHelpers';
import { responseStatusIs } from '../utils/formErrorHelpers';
import apiService from './api';

const service = 'attribute';

function getAttributes() {
  return apiService.get('/attributes').then(({ data }) => data.attributes);
}

function addAttribute({ code, label, isEnabled }) {
  return apiService
    .post('/attributes', { code, label, isEnabled })
    .then(({ data }) => data.attribute)
    .catch(error => {
      if (responseStatusIs(error.response, 500)) {
        if (error.response.data.includes('E11000')) {
          throw new Error(`Attribute with code: ${code} already exists!`);
        }
      }
      const err = getRequestError({ error, type: 'add', service });
      throw new Error(err);
    });
}

function deleteAttribute({ id }) {
  return apiService.delete(`/attributes/${id}`).catch(error => {
    const err = getRequestError({ error, type: 'delete', service });
    throw new Error(err);
  });
}

function updateAttribute(id, { label, isEnabled }) {
  return apiService.patch(`/attributes/${id}`, { label, isEnabled }).catch(error => {
    const err = getRequestError({ error, type: 'update', service });
    throw new Error(err);
  });
}

function getSingleAttribute(id) {
  return apiService
    .get(`/attributes/${id}`)
    .then(({ data }) => data.attribute)
    .catch(error => {
      const err = getRequestError({ error, type: 'get', service });
      throw new Error(err);
    });
}

export default {
  getAttributes,
  addAttribute,
  deleteAttribute,
  updateAttribute,
  getSingleAttribute,
};
