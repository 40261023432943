import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutableProptypes from 'react-immutable-proptypes';
import { Link, Route, Switch } from 'react-router-dom';

import LineItemError from './LineItemError.jsx';
import LineItemPlaceholder from './LineItemPlaceholder';
import PlusIcon from '../../base/icons/PlusIcon.jsx';
import UserLineItem from './UserLineItem';
import Invitation from '../../../containers/Invitation';
import DeleteUser from '../../../containers/DeleteUser';

const propTypes = {
  actions: PropTypes.shape({
    userDetails: PropTypes.shape({
      setSelectedUser: PropTypes.func.isRequired,
    }),
  }),
  currentUserRole: PropTypes.string,
  isActive: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  users: ImmutableProptypes.list.isRequired,
};

function Admins(props) {
  const {
    actions: {
      userDetails: { setSelectedUser },
    },
    currentUserRole,
    isActive,
    match,
    location: { pathname },
    history,
    users,
  } = props;

  return (
    <div>
      <div className="c-button-row">
        <Link
          to="/admin/permissions/admins/invitation"
          className="c-button c-button--hollow c-button--small c-button--wide">
          <PlusIcon className="c-button__icon" />
          <span>Add New Adminstrator</span>
        </Link>
      </div>

      <ul>
        {(() => {
          if (isActive) {
            return _.times(12, i => <LineItemPlaceholder hasManageLink key={i} />);
          } else if (!users.filter(u => u.get('role') === 'admin').size) {
            return (
              <LineItemError
                subtitle="Have you tried adding a new administrator?"
                title="No one here"
              />
            );
          }

          return users.map((user, i) => {
            if (user.get('role') !== 'admin') {
              return null;
            }

            return (
              <UserLineItem
                currentUserRole={currentUserRole}
                key={i}
                location={pathname}
                pushRoute={history.push}
                setSelectedUser={setSelectedUser}
                user={user}
              />
            );
          });
        })()}
      </ul>

      <Switch>
        <Route exact path={`${match.url}/invitation`} component={Invitation} />
        <Route path={`${match.url}/invitation/:id/delete`} component={DeleteUser} />
      </Switch>

      {/* old react-router config */}
      {/*{children &&*/}
      {/*  Children.map(children, child => {*/}
      {/*    return cloneElement(child, { label: 'Administrator' });*/}
      {/*  })}*/}
    </div>
  );
}

Admins.propTypes = propTypes;

export default Admins;
