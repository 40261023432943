import React from 'react';
import styled, { css } from 'styled-components';
import { Hidden } from 'react-grid-system';

import { colors, Gap } from '../base/styles';
import { c } from '../base/fields/styles';

// --font-size-xs: 12px;
// --font-size-sm: 14px;
// --font-size-md: 16px;
// --font-size-lg: 24px;
// --font-size-xl: 36px;

export const H1 = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #000;
`;

export const Regular = styled.div`
  font-size: 16px;
  font-weight: ${({ bold = false }) => (bold ? 'bold' : 'regular')};
  color: ${({ bold = false }) => bold && '#000'};
`;

export const Span = styled.span`
  font-size: 16px;
  font-weight: ${({ bold = false }) => (bold ? 'bold' : 'regular')};
  color: ${({ bold = false }) => bold && '#000'};
`;

export const Hr = styled.div`
  width: ${({ percent }) => percent}%;
  height: 2px;
  background: ${colors.grayLight};
  margin-top: ${({ top }) => top}px;
  margin-bottom: ${({ bottom }) => bottom}px;
`;

export const MetadataContainer = styled.div`
  & > * {
    margin: ${({ isAdmin = false }) => (isAdmin ? '2px' : '16px')} 0px;
  }
`;

export const HiddenGap = props => (
  <Hidden lg xl xxl>
    <Gap height={props.height} />
  </Hidden>
);

export const FieldItem = styled.div`
  ${({ isScreenSmall }) =>
    isScreenSmall &&
    css`
      .jira-field-button {
        background-color: ${c.HOVER};
        width: 100%;
      }
    `}
`;
