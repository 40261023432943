import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const location = '/admin/permissions/';
const tabs = [
  { location: `${location}users`, label: 'users' },
  { location: `${location}admins`, label: 'administrators', requireRole: true },
  { location: `${location}accounts`, label: 'accounts' },
];

function requireRole(role = '', roleArray = ['superAdmin']) {
  return roleArray.indexOf(role) >= 0;
}

const propTypes = {
  role: PropTypes.string,
};

function Tabs({ role }) {
  return (
    <div className="c-tabs">
      {tabs
        .filter(tab => !tab.requireRole || requireRole(role))
        .map((tab, i) => {
          return (
            <NavLink key={i} className="c-tab" activeClassName="c-tab--active" to={tab.location}>
              <p className="c-tab__title">{tab.label}</p>
            </NavLink>
          );
        })}
    </div>
  );
}

Tabs.propTypes = propTypes;

export default Tabs;
