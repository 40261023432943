import PropTypes from 'prop-types';
import React, { useState, useRef, useCallback } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import _ from 'lodash';

import { useEventListener } from '../../../utils/hooks';
import {
  ActiveContainer,
  Container,
  FieldContainer,
  InputButton,
  Button,
  ButtonText,
} from './styles';
import { toast } from 'react-toastify';

export const fieldicons = Object.freeze({
  SUBMIT: 'submit',
  CANCEL: 'cancel',
});

/**
 * Rewrite of HoverableBase -- without dependency to redux-form
 *
 * HoverableBase wraps a children component to make it 'hoverable'
 * - 'hoverable' in this case means jira-style edit fields
 *
 * You can't use this as a standalone input field
 *
 * To override styles with classNames:
 * - [jira-field-container] -- for the overall container
 * - [jira-field-button] -- for the trigger button
 *
 * @param {'flex-start' | 'flex-end'} justify
 * @param {any} initialValue          - [clear] would reset to this value if present
 * @param {function} onFieldSubmit    - submit callback, [value] is passed as an argument
 * @param {string} value              - value maintained by the higher-order useState
 * @param {function} setValue         - function to update value maintained by the higher-order useState
 * @param {array.<string>} shownIcons - list of icons that should be shown (order does not matter)
 * @param {string} title              - overrides the displayed title, which is by default [value]
 * @param {string} placeholder        - this is what will be displayed at the button if [value] is still empty
 * @param {node} children             - the custom children to render, e.i. input, date-picker
 * @param {bool} disallowEmptyValues  - reset the field to the original value if submitted and the field is empty
 * @param {string} name               - name of the field
 * @param {object} buttonTextStyle    - style of the button text
 * @param {bool} full                 - make width 100%
 * @returns {JSX.Element}
 * @constructor
 */
export const JiraField = ({
  justify,
  initialValue,
  value,
  setValue,
  onFieldSubmit,
  shownIcons,
  title,
  placeholder,
  children,
  name,
  disallowEmptyValues,
  buttonTextStyle,
  full,
}) => {
  const [isActive, setIsActive] = useState(false);

  const nodeRef = useRef(null);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const handleSubmit = () => {
    // 1. toggle input visibility
    toggleActive();
    // 2. submit form value
    if (disallowEmptyValues && value.length === 0) {
      toast(`Empty ${name} is not allowed!`, { type: 'error' });
      setValue(initialValue);
    } else {
      onFieldSubmit(value);
    }
  };

  const handleCancel = () => {
    // 1. reset value
    setValue(initialValue);
    // 2. toggle input visibility
    toggleActive();
  };

  const handleMouseDown = useCallback(
    e => {
      // only fire if the field is active
      if (!isActive) return;

      // the click is inside, do nothing
      if (nodeRef.current?.contains(e.target)) return;

      if (!value) {
        // if the field is empty and not saved, reset
        setValue(initialValue);
      } else {
        onFieldSubmit(value);
      }

      setIsActive(!isActive);
    },
    [initialValue, isActive, onFieldSubmit, setValue, value]
  );

  const handleKeyDown = useCallback(
    e => {
      // only fire if the field is active
      if (isActive) {
        if (e.keyCode === 27) {
          // pressed esc
          setValue(initialValue);
          setIsActive(!isActive);
        } else if (e.keyCode === 13) {
          // pressed enter
          if (disallowEmptyValues && value.length === 0) {
            toast(`Empty ${name} is not allowed!`, { type: 'error' });
            setValue(initialValue);
          } else {
            onFieldSubmit(value);
          }
          setIsActive(!isActive);
        }
      }
    },
    [initialValue, isActive, onFieldSubmit, setValue, value]
  );

  useEventListener('mousedown', handleMouseDown);

  useEventListener('keydown', handleKeyDown);

  return (
    <Container className="jira-field-container" ref={nodeRef}>
      {isActive ? (
        <ActiveContainer justify={justify}>
          <FieldContainer full={full}>{children}</FieldContainer>

          {_.includes(shownIcons, fieldicons.SUBMIT) && (
            <InputButton onClick={handleSubmit}>
              <FiCheck size={18} />
            </InputButton>
          )}
          {_.includes(shownIcons, fieldicons.CANCEL) && (
            <InputButton onClick={handleCancel}>
              <FiX size={18} />
            </InputButton>
          )}
        </ActiveContainer>
      ) : (
        <Button
          className="jira-field-button"
          onClick={toggleActive}
          title={title ? title : value || placeholder}>
          <ButtonText style={buttonTextStyle}>{title ? title : value || placeholder}</ButtonText>
        </Button>
      )}
    </Container>
  );
};

JiraField.propTypes = {
  justify: PropTypes.oneOf(['flex-start', 'flex-end']),
  initialValue: PropTypes.any, // could be date or string atm
  onFieldSubmit: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired, // could be date or string atm
  shownIcons: PropTypes.arrayOf(PropTypes.string),
  buttonTextStyle: PropTypes.object,
  full: PropTypes.bool,
};

JiraField.defaultProps = {
  initialValue: '',
  onFieldSubmit: () => {},
  shownIcons: [fieldicons.SUBMIT, fieldicons.CANCEL],
  justify: 'flex-start',
};
