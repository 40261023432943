import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const propTypes = {
  actions: PropTypes.object,
  role: PropTypes.string,
};

export const sidePanelItems = [
  {
    permissions: ['superAdmin'],
    value: 'permissions',
    label: 'Permissions',
  },
  {
    permissions: ['admin', 'superAdmin'],
    value: 'attributes',
    label: 'Attributes',
  },
  {
    permissions: ['admin', 'superAdmin'],
    value: 'project-flags',
    label: 'Project Flags',
  },
  {
    permissions: ['admin', 'superAdmin'],
    value: 'file-exclusion-rules',
    label: 'File Exclusion Rules',
  },
  {
    permissions: ['user', 'admin', 'superAdmin'],
    value: 'sign-out',
    label: 'Sign out',
  },
];

function SidePanel({ actions, role }) {
  return (
    <div className="c-side-panel">
      <div>
        {sidePanelItems.slice(0, -1).map(
          ({ value, label, permissions }) =>
            permissions.includes(role) && (
              <NavLink
                key={value}
                className="c-side-panel__tab"
                activeClassName="c-side-panel__tab--active"
                to={`/admin/${value}`}>
                {label}
              </NavLink>
            )
        )}
      </div>

      <div
        className="c-button c-button--hollow c-button--block c-button--small"
        onClick={actions.authentication.signOut}>
        Sign Out
      </div>
    </div>
  );
}

SidePanel.propTypes = propTypes;

export default SidePanel;
