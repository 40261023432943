import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AttributeForm from '../../components/AccountPanel/Attributes/AttributeForm.jsx';
import * as attributesActionCreators from '../../actions/attributes';
import attributeService from '../../services/attributes';
import { states } from '../../constants/route-states';

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  actions: PropTypes.shape({
    attributes: PropTypes.shape({
      getAttributes: PropTypes.func.isRequired,
    }),
  }),
};

class ConnectedEditAttribute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      parentRoute: '/admin/attributes',
      attribute: { code: '', label: '', isEnabled: false },
    };

    this.userClickedClose = this.userClickedClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { id: attributeId } = this.props.match.params;

    this.setState({ isActive: true });

    // Load attribute based on "id" param
    attributeService
      .getSingleAttribute(attributeId)
      .then(attribute => this.setState({ attribute, isActive: false }))
      .catch(error => {
        const message = error.message || 'Failed to load attribute';
        toast(message, { type: 'error' });
        this.userClickedClose();
      });
  }

  userClickedClose(state) {
    const { parentRoute } = this.state;
    this.props.history.push(parentRoute, state);
  }

  handleSubmit(values) {
    this.setState({ isActive: true });

    attributeService
      .updateAttribute(this.state.attribute._id, values)
      .then(() => {
        toast('Attribute updated!', { type: 'success' });
        this.setState(
          () => ({ isActive: false }),
          () =>
            this.userClickedClose({
              status: states.TRIGGER_REFRESH,
            })
        );
      })
      .catch(error => {
        this.setState({ isActive: false });
        toast(error.message, { type: 'error' });
      });
  }

  render() {
    return (
      <AttributeForm
        {...this.props}
        state={this.state}
        title="Edit Attribute"
        subtitle="Use fields below to edit attribute."
        type="edit"
        submitText="Save"
        initialValues={this.state.attribute}
        userClickedClose={this.userClickedClose}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

ConnectedEditAttribute.propTypes = propTypes;

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: {
    attributes: bindActionCreators(attributesActionCreators, dispatch),
  },
});

export { ConnectedEditAttribute as EditAttribute, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedEditAttribute);
