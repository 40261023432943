import PropTypes from 'prop-types';
import React from 'react';
import { FiDelete } from 'react-icons/fi';

import { ActiveContainer, FieldContainer, IconButton, Input, InputContainer } from './styles';

export const vanillaInputPropTypes = {
  inputStyle: PropTypes.object,
  maxLength: PropTypes.number,
  onChangeText: PropTypes.func,
  value: PropTypes.any,
  bordered: PropTypes.bool,
  full: PropTypes.bool,
};

const _BorderedContainer = props => (
  <ActiveContainer>
    <FieldContainer full={props.full}>{props.children}</FieldContainer>
  </ActiveContainer>
);

export const VanillaInput = props => {
  const Wrapper = props.bordered ? _BorderedContainer : React.Fragment;
  const wrapperProps = props.bordered ? { full: props.full } : {};
  return (
    <Wrapper {...wrapperProps}>
      <InputContainer full={props.full}>
        <Input
          full={props.full}
          autoFocus
          style={props.inputStyle}
          value={props.value}
          onChange={e => {
            // trim the whitespace on the left only to allow people
            // to add spaces for multiple worded titles
            const trimmed = e.target.value.trimLeft();
            props.onChangeText(trimmed);
          }}
          maxLength={`${props.maxLength}`}
        />
        <IconButton onClick={() => props.onChangeText('')}>
          <FiDelete size={18} />
        </IconButton>
      </InputContainer>
    </Wrapper>
  );
};

VanillaInput.propTypes = vanillaInputPropTypes;

VanillaInput.defaultProps = {
  maxLength: 50,
  bordered: false,
};
