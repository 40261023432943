export const osTypes = {
  WINDOWS_PHONE: 'windows_phone',
  ANDROID: 'android',
  IOS: 'ios',
  UNKNOWN: 'unknown',
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'ios', 'android', 'windows_phone', or 'unknown'.
 * https://stackoverflow.com/a/21742107/8674390
 *
 * @returns {String}
 */
export const detectOs = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return osTypes.WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
    return osTypes.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return osTypes.IOS;
  }

  return osTypes.UNKNOWN;
};
