import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import * as attributesActionCreators from '../../actions/attributes';
import Attributes from '../../components/AccountPanel/Attributes';
import attributesService from '../../services/attributes';
import { states } from '../../constants/route-states';

const propTypes = {
  actions: PropTypes.shape({
    attributes: PropTypes.shape({
      getAttributes: PropTypes.func.isRequired,
    }),
  }),
};

class ConnectedAttributes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /** Controls the confirm dialog visibility */
      isDialogOpen: false,
      /** The current attribute being deleted */
      deleteAttrib: null,
      /** If delete is currently ongoing */
      isDeleting: false,
    };

    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteAttribute = this.deleteAttribute.bind(this);
  }

  componentDidMount() {
    this.props.actions.attributes.getAttributes();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.location.state?.status !== this.props.location.state?.status &&
      this.props.location.state?.status === states.TRIGGER_REFRESH
    ) {
      // refresh list after successful add
      this.props.actions.attributes.getAttributes();
    }
  }

  /** Opens a delete confirmation dialog for an attribute */
  confirmDelete(attrib) {
    this.setState({ deleteAttrib: attrib, isDialogOpen: true });
  }

  /** Executes if user clicks `cancel` button on confirm delete dialog */
  cancelDelete() {
    this.setState({ deleteAttrib: null, isDialogOpen: false });
  }

  /** Executes if the user chooses to confirm attribute deletion */
  deleteAttribute() {
    const id = this.state.deleteAttrib._id;
    const usage = this.state.deleteAttrib.usageCount;

    if (usage > 0) {
      toast.error("You can't delete an attribute that is being used in a project");
      return;
    }

    this.setState({ isDialogOpen: false, isDeleting: true });

    attributesService
      .deleteAttribute({ id })
      .then(() => {
        this.setState({ deleteAttrib: null, isDeleting: false });
        toast('Attribute deleted!', { type: 'success' });
        this.props.actions.attributes.getAttributes();
      })
      .catch(error => {
        this.setState({ deleteAttrib: null, isDeleting: false });
        toast(error.message, { type: 'error' });
      });
  }

  render() {
    return (
      <Attributes
        {...this.props}
        {...this.state}
        confirmDelete={this.confirmDelete}
        cancelDelete={this.cancelDelete}
        deleteAttribute={this.deleteAttribute}
      />
    );
  }
}

ConnectedAttributes.propTypes = propTypes;

const mapStateToProps = state => ({
  isActive: state.attributes.get('isActive'),
  attributes: state.attributes.get('attributes'),
  role: state.authentication.get('role'),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    attributes: bindActionCreators(attributesActionCreators, dispatch),
  },
});

export { ConnectedAttributes as Attributes, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedAttributes);
