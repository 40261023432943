import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Attributes from '../../containers/Attributes';
import { wrapWithAuthenticatedApp } from '../../containers/AuthenticatedApp';
import ProjectFlags from '../../containers/ProjectFlags';
import { VanillaDropdown } from '../base/fields/VanilaDropdown';
import SignOutModal from '../base/SignOutModal';
import FileExclusionRules from '../../containers/FileExclusionRules';
import Permissions from './Permissions';
import SidePanel, { sidePanelItems } from './SidePanel';
import Authorization from '../base/Authorization';

const propTypes = {
  actions: PropTypes.object,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.string.isRequired,
};

const SuperAdmin = Authorization(['superAdmin']);

const AccountPanel = ({ match, actions, role }) => {
  // Ephemeral states
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  // External hooks
  const location = useLocation();
  const history = useHistory();

  /** Toggle logout modal open or close */
  const toggleLogoutModal = () => {
    setIsLogoutModalOpen(!isLogoutModalOpen);
  };

  /**
   * Handle [VanillaDropdown] onChange event
   * @param {string} path
   */
  const navigate = path => {
    if (path === 'sign-out') {
      toggleLogoutModal(true);
    } else {
      history.push(`/admin/${path}`);
    }
  };

  return (
    <div className="l-panels">
      {isLogoutModalOpen && (
        <SignOutModal
          cancelHandler={toggleLogoutModal}
          nextHandler={actions.authentication.signOut}
        />
      )}

      {/* Only visible for screens > 720px */}
      <SidePanel
        role={role}
        actions={{
          authentication: {
            signOut: toggleLogoutModal,
          },
        }}
      />

      <div className="c-main-panel">
        {/* Only visible for screens < 720px */}
        <Navigator>
          <VanillaDropdown
            initialized={false}
            options={sidePanelItems.filter(item => item.permissions.includes(role))}
            value={location.pathname.split('/')[2]}
            onChangeText={navigate}
          />
        </Navigator>

        <Route exact path={`${match.url}`}>
          {role === 'superAdmin' ? (
            // Permissions is the default route for superadmins
            <Redirect to={`${match.url}/permissions`} />
          ) : (
            // Attributes is the default route for admins
            <Redirect to={`${match.url}/attributes`} />
          )}
        </Route>
        {/* Permissions contains nested routes */}
        <Route path={`${match.url}/permissions`} component={SuperAdmin(Permissions)} />
        {/* Attributes contains nested routes */}
        <Route path={`${match.url}/attributes`} component={Attributes} />
        {/* ProjectFlags contains nested routes */}
        <Route path={`${match.url}/project-flags`} component={ProjectFlags} />
        {/* FileExclusionRules contains nested routes */}
        <Route path={`${match.url}/file-exclusion-rules`} component={FileExclusionRules} />
      </div>
    </div>
  );
};

AccountPanel.propTypes = propTypes;

const Navigator = styled.div`
  margin-bottom: 2rem;

  /* don't show on 720 above */
  @media (min-width: 720px) {
    display: none;
  }
`;

export { AccountPanel };
export default wrapWithAuthenticatedApp()(AccountPanel);
