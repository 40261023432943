import _ from 'lodash';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { removeFilterTag, setFilterTag } from '../../../../utils/filterHelpers';
import { colors } from '../../../base/styles';
import { CheckboxItem } from './CheckboxItem';

/**
 * A collapsible filter item dropdown
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CollapsibleItem = props => {
  const [open, setOpen] = useState();

  const node = useRef();
  const history = useHistory();
  const location = useLocation();

  // shape: { attribute: "foo,bar" }
  const params = parse(location.search);

  // sort values alphabetically
  const sortedValues = useMemo(() => {
    return props.values.sort((a, b) => {
      const valueA = a.get('value').toLowerCase();
      const valueB = b.get('value').toLowerCase();
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    });
  }, [props.values]);

  // listen for mouse clicks
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  /**
   * Handler for mousedown listener
   * @param e
   */
  const handleClick = e => {
    // inside click
    if (node.current?.contains(e.target)) return;
    // outside click
    setOpen(false);
  };

  /** Toggle dropdown to collapse or retract */
  const toggleCollapsible = () => {
    if (!props.values.size) return;
    setOpen(!open);
  };

  /**
   * Toggle a filter on or off
   * @param e
   * @param field
   * @param keyword
   */
  const toggleFilter = (e, field, keyword) => {
    // for some reason, e.target.checked does not work
    const checked = e.target.value === 'true';
    if (checked) {
      removeFilterTag({ location, history, field, keyword });
    } else {
      setFilterTag({ location, history, field, keyword });
    }
  };

  const Caret = open ? BiCaretUp : BiCaretDown;

  return (
    <Container ref={node}>
      <Header onClick={toggleCollapsible}>
        <Title>{props.title}</Title>
        <Caret size={17} />
      </Header>

      {open && (
        <FloatingContainer>
          {!props.values.size && <span>No filters</span>}
          {sortedValues?.map((data, i) => {
            const value = data.get('value');
            const count = data.get('count');
            return (
              <CheckboxItem
                key={`${value}_${i}`}
                name={`${props.code}-${i}`}
                label={_.startCase(value)}
                count={count}
                checked={_.includes(params[props.code], value)}
                onChange={e => toggleFilter(e, props.code, value)}
              />
            );
          })}
        </FloatingContainer>
      )}
    </Container>
  );
};

CollapsibleItem.propTypes = {
  code: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  values: ImmutablePropTypes.list.isRequired,
};

const Container = styled.div``;

const Header = styled.button`
  pointer-events: ${({ disabled }) => disabled && 'none'};
  outline: none;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  justify-content: space-between;

  &:hover {
    color: ${colors.blue};
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-right: 0.25rem;
  letter-spacing: 0.036em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FloatingContainer = styled.div`
  position: absolute;
  z-index: 10;
  margin-top: 4px;
  background-color: #fff;
  padding: 14px 24px 14px 14px;
  border: 1px solid #d7d7d7;
  border-radius: 0.3125rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  max-height: 13rem;
  overflow: scroll;
  overflow-x: hidden;

  // firefox
  scrollbar-color: ${colors.grayLight} #fff;
  scrollbar-width: thin;

  // chrome, safari
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: whitesmoke;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grayLight};
    border-radius: 8px;
  }
`;
