import React from 'react';
import styled from 'styled-components';

import Header from './base/Header';

// TODO: add forbidden page
export const Forbidden = props => {
  return (
    <div className="l-sign-in">
      <Header />
      <Container>
        <h1>403</h1>
        <h2>You don't have permission to access this page.</h2>
      </Container>
    </div>
  );
};

const Container = styled.div`
  margin: 36px 16px 20%;
  text-align: center;
`;
