import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as invitationsActionCreators from '../actions/invitations';
import * as userDetailsActionCreators from '../actions/userDetails';
import * as usersActionCreators from '../actions/users';
import Delete from '../components/AccountPanel/Permissions/Delete/index';

const propTypes = {
  actions: PropTypes.shape({
    invitations: PropTypes.shape({
      deleteInvite: PropTypes.func,
    }),
    users: PropTypes.shape({
      getUsers: PropTypes.func,
    }),
    userDetails: PropTypes.shape({
      deleteUser: PropTypes.func,
      findUserById: PropTypes.func,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  selectedUser: PropTypes.shape({
    _id: PropTypes.string,
    get: PropTypes.func,
  }),
};

class ConnectedDeleteUser extends Component {
  componentDidMount() {
    if (!this.props.selectedUser.get('_id')) {
      const { id } = this.props.match.params;
      this.props.actions.userDetails.findUserById(id);
    }
  }

  render() {
    return <Delete {...this.props} />;
  }
}

ConnectedDeleteUser.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    selectedUser: state.userDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      invitations: bindActionCreators(invitationsActionCreators, dispatch),
      userDetails: bindActionCreators(userDetailsActionCreators, dispatch),
      users: bindActionCreators(usersActionCreators, dispatch),
    },
  };
}

export { ConnectedDeleteUser as DeleteUser, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedDeleteUser);
