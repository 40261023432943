import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import styled from 'styled-components';

import { CollapsibleItem } from './components/CollapsibleItem';

const propTypes = {
  /** The 3 main filters */
  specialFilters: ImmutablePropTypes.list.isRequired,
};

const dictionary = {
  'metadata.retailchannel': 'Retail Channel',
  'metadata.typeofdisplay': 'Display Type',
  'organization.name': 'Organization',
};

/**
 * Presentational component for the 3 special filters
 * on the filters panel header
 * @param {*} props
 */
const MainFilters = props => {
  return (
    <InnerContainer>
      {props.specialFilters.map(item => {
        const label = dictionary[item.get('code')];
        const code = item.get('code');
        const values = item.get('values');
        return (
          <CollapsibleItem key={`${label}_${code}`} title={label} code={code} values={values} />
        );
      })}
    </InnerContainer>
  );
};

MainFilters.propTypes = propTypes;

const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-self: center;
  justify-content: center;

  & > * {
    margin: 10px 0;
  }
  & > :not(:last-child) {
    margin-right: 0.65rem;
  }

  @media (max-width: 767.98px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 10px;

    & > * {
      margin: unset;
    }
    & > :not(:last-child) {
      margin-right: unset;
    }
    & > :last-child {
      margin-right: 3px;
    }
  }

  @media (max-width: 577.98px) {
    grid-template-columns: minmax(160px, 1fr);
  }
`;

export default MainFilters;
