import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ImmutableProptypes from 'react-immutable-proptypes';

import OrgCheckboxRow from '../OrgCheckboxRow';
import { ModalFooter } from '../../../base/styled';

const propTypes = {
  actions: PropTypes.shape({
    invitations: PropTypes.shape({
      addAllInviteAdminOrgs: PropTypes.func.isRequired,
      addAllInviteUserOrgs: PropTypes.func.isRequired,
      clearInvite: PropTypes.func.isRequired,
      removeAllInviteAdminOrgs: PropTypes.func.isRequired,
    }),
  }),
  cancelLocation: PropTypes.string.isRequired,
  invitation: ImmutableProptypes.contains({
    adminOrganizations: ImmutableProptypes.set,
    userOrganizations: ImmutableProptypes.set,
  }),
  isActive: PropTypes.bool,
  managedOrgs: ImmutableProptypes.list,
  onSubmit: PropTypes.func.isRequired,
};

class InvitationAccounts extends Component {
  constructor() {
    super();

    this.handleAdminSelectAll = this.handleAdminSelectAll.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserSelectAll = this.handleUserSelectAll.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.invitations.clearInvite();
  }

  handleAdminSelectAll(allSelected) {
    return () => {
      allSelected
        ? this.props.actions.invitations.removeAllInviteAdminOrgs(this.props.managedOrgs)
        : this.props.actions.invitations.addAllInviteAdminOrgs(this.props.managedOrgs);
    };
  }

  handleUserSelectAll(allSelected) {
    return () => {
      allSelected
        ? this.props.actions.invitations.clearInvite()
        : this.props.actions.invitations.addAllInviteUserOrgs(this.props.managedOrgs);
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(
      this.props.invitation.get('adminOrganizations').toJS(),
      this.props.invitation.get('userOrganizations').toJS()
    );
  }

  render() {
    const {
      actions: {
        invitations: {
          addInviteAdminOrg,
          addInviteUserOrg,
          removeInviteAdminOrg,
          removeInviteUserOrg,
        },
      },
      cancelLocation,
      invitation,
      managedOrgs,
    } = this.props;
    const adminOrganizations = invitation.get('adminOrganizations');
    const userOrganizations = invitation.get('userOrganizations');
    const allAdminSelected = adminOrganizations.size === managedOrgs.size;
    const allUserSelected = userOrganizations.size === managedOrgs.size;
    const adminProps = {
      addOrg: addInviteAdminOrg,
      removeOrg: removeInviteAdminOrg,
      orgs: adminOrganizations,
    };
    const userProps = {
      addOrg: addInviteUserOrg,
      removeOrg: removeInviteUserOrg,
      orgs: userOrganizations,
    };

    return (
      <form className="c-form c-form--with-sticky-footer" onSubmit={this.handleSubmit}>
        <div className="c-checkbox-row c-checkbox-row--heading">
          <label className="c-checkbox-row__label">Organizations</label>
          <div className="c-checkbox-row__check">Admin</div>
          <div className="c-checkbox-row__check">User</div>
        </div>
        <div className="c-checkbox-row c-checkbox-row--fixed">
          <label className="c-checkbox-row__label">All</label>
          <div className="c-checkbox-row__check">
            <input
              checked={allAdminSelected}
              onChange={this.handleAdminSelectAll(allAdminSelected)}
              type="checkbox"
            />
          </div>
          <div className="c-checkbox-row__check">
            <input
              checked={allUserSelected}
              onChange={this.handleUserSelectAll(allUserSelected)}
              type="checkbox"
            />
          </div>
        </div>
        <div className="c-modal__manage-overflow">
          {!managedOrgs.size && <div className="c-checkbox-row c-checkbox-row--placeholder" />}
          <ul>
            {managedOrgs.map(org => {
              return (
                <li key={org.get('id')}>
                  <OrgCheckboxRow org={org} admin={adminProps} user={userProps} />
                </li>
              );
            })}
          </ul>
        </div>
        <ModalFooter className="c-button-row c-button-row--modal">
          <Link to={cancelLocation} className="c-button c-button--small c-button--hollow">
            Cancel
          </Link>
          <button className="c-button c-button--small" disabled={this.props.isActive} type="submit">
            Send Invite
          </button>
        </ModalFooter>
      </form>
    );
  }
}

InvitationAccounts.propTypes = propTypes;

export default InvitationAccounts;
