import apiService from './api';
import { getRequestError } from '../utils/errorHelpers';

const service = 'filters';

function filter(payload) {
  return apiService
    .post('/filters', payload)
    .then(result => result.data)
    .catch(error => {
      throw new Error(getRequestError({ error, service }));
    });
}

export default {
  filter,
};
