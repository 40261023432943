import React from 'react';

function DropdownCarat() {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" xmlns="http://www.w3.org/2000/svg" className="c-dropdown-trigger__caret">
      <path d="M6 6l6-6H0z" fillRule="evenodd" />
    </svg>
  );
}

export default DropdownCarat;
