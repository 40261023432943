import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Actions from './Actions';
import Breadcrumbs from './Breadcrumbs';

const propTypes = {
  actions: PropTypes.object,
  children: PropTypes.node,
  userInfo: PropTypes.object,
};

function Header({ actions, children, userInfo }) {
  const isAuthenticated = !!userInfo;

  const containterClasses = classNames({
    'c-header__container': true,
    'c-header__container--unauthenticated': !isAuthenticated,
  });
  const headerClasses = classNames({
    'c-header': true,
    'c-header--unauthenticated': !isAuthenticated,
  });

  return (
    <div className={containterClasses}>
      <div className={headerClasses}>
        <Breadcrumbs isAuthenticated={isAuthenticated} breadcrumb={children} />
        <Actions actions={actions} isAuthenticated={isAuthenticated} userInfo={userInfo} />
      </div>
    </div>
  );
}

Header.propTypes = propTypes;

export default Header;
