import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { getFileExclusionRules } from '../../actions/fileExclusionRules';
import { getUsers } from '../../actions/users';
import { editIcon, trashIcon } from '../../assets/images';
import FileExclusionRules from '../../components/AccountPanel/FileExclusionRules';
import { Can } from '../../components/base/Can';
import { IconButton } from '../../components/base/IconButton';
import { ruleTypes } from '../../constants/rbac-rules';
import { states } from '../../constants/route-states';
import service from '../../services/file-exclusion-rules';

const ConnectedFileExclusionRules = props => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState(null);

  const role = useSelector(({ authentication }) => authentication.get('role'));
  const rules = useSelector(({ fileExclusionRules }) => fileExclusionRules.get('rules'));
  const loadingRules = useSelector(({ fileExclusionRules }) => fileExclusionRules.get('isActive'));

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getFileExclusionRules());
  }, []);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  // trigger a refresh of file exclusion rules
  useEffect(() => {
    if (props.location.state?.status === states.TRIGGER_REFRESH) {
      dispatch(getFileExclusionRules());
      props.history.replace();
    }
  }, [props.location.state?.status]);

  const columns = useMemo(
    () => [
      {
        name: 'Term',
        selector: 'term',
        id: 'term',
        sortable: true,
        minWidth: '150px',
        maxWidth: '200px',
        cell: rule => <Item>{rule.term}</Item>,
      },
      {
        name: 'Banned Users',
        id: 'bannedUserIds',
        cell: rule => (
          <Users>
            {rule.bannedUsers.map(user => {
              const value = `${user.name} | ${user.email}`;
              return <div key={user._id}>{value}</div>;
            })}
          </Users>
        ),
      },
      {
        name: 'Actions',
        center: true,
        width: '100px',
        cell: rule => (
          <>
            <Can
              role={role}
              perform={ruleTypes.FILE_EXCLUSIION_RULES__EDIT}
              yes={() => (
                <IconButton
                  alt="Edit"
                  src={editIcon}
                  onClick={() => {
                    const link = `/admin/file-exclusion-rules/${rule._id}/edit`;
                    history.push(link);
                  }}
                />
              )}
            />
            <Can
              role={role}
              perform={ruleTypes.FILE_EXCLUSIION_RULES__DELETE}
              yes={() => (
                <IconButton
                  disabled={loadingRules || isDeleting}
                  alt="Delete"
                  src={trashIcon}
                  onClick={() => confirmDelete(rule)}
                />
              )}
            />
          </>
        ),
      },
    ],
    [isDeleting, loadingRules, role]
  );

  const confirmDelete = rule => {
    setIsDeleteDialogOpen(true);
    setRuleToDelete(rule);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setIsDeleteDialogOpen(false);
    setRuleToDelete(null);
    service
      .deleteFileExclusionRule(ruleToDelete._id)
      .then(() => {
        setIsDeleting(false);
        toast.success('Rule deleted!');
        dispatch(getFileExclusionRules());
      })
      .catch(error => {
        setIsDeleting(false);
        toast.error(error.message);
      });
  };

  const cancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setRuleToDelete(null);
  };

  return (
    <FileExclusionRules
      {...props}
      role={role}
      rules={rules}
      columns={columns}
      isDeleteDialogOpen={isDeleteDialogOpen}
      handleDelete={handleDelete}
      cancelDelete={cancelDelete}
      loading={loadingRules}
      deleting={isDeleting}
      ruleToDelete={ruleToDelete}
    />
  );
};

const Users = styled.div`
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding-left: 16px;
  padding-right: 16px;

  & > * {
    margin: 12px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Item = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export default ConnectedFileExclusionRules;
