import React, { useEffect } from 'react';
import { getProjectFlags } from '../../actions/projectFlags';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSummary from '../../components/Projects/CollapsibleOverview/ProjectFlagsSummary.jsx';

const ConnectedProjectFlagsSummary = () => {
  const dispatch = useDispatch();

  const filters = useSelector(state => state.filters.get('fields'));
  const flags = filters.get('projectFlags');

  let parsedFlags = [];
  if (flags) {
    parsedFlags = flags.toJS().map(flag => {
      return {
        _id: flag._id,
        title: flag.label,
        value: flag.count,
        color: flag.colorCode,
      };
    });
  }

  // load project flags
  useEffect(() => {
    dispatch(getProjectFlags());
  }, []);

  return <ProjectsSummary flags={parsedFlags} />;
};

export default ConnectedProjectFlagsSummary;
