import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PuffLoader } from 'react-spinners';
import React from 'react';

const PreviewContainer = styled.iframe`
  border: none;
  position: absolute;
  height: 500px;
  width: inherit;
  top: -50px; /* corresponds to the height of the header in the box embed */
`;

const RenderingContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 450px;
  margin-bottom: 10px;
  border-radius: 3px;
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 450px;
  text-align: center;
  font-size: 14px;
`;

/**
 * A small presentational component that renders a 3d preview from Box
 * @param rendering
 * @returns {JSX.Element}
 * @constructor
 */

const RenderedPreview = ({ rendering }) => {
  if (rendering.get('error')) {
    return (
      <Centered>
        There was an error loading the preview. <br />
        Please try again.
      </Centered>
    );
  }
  return (
    <>
      {rendering.get('isActive') ? (
        <Centered>
          <PuffLoader color="#3d7cbf" size={24} />
        </Centered>
      ) : (
        <RenderingContainer>
          <PreviewContainer allowFullScreen src={rendering.get('embedUrl')} />
        </RenderingContainer>
      )}
    </>
  );
};

RenderedPreview.propTypes = {
  rendering: PropTypes.object.isRequired,
};

export default RenderedPreview;
