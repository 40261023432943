import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import Tabs from './Tabs';
import Users from '../../../containers/Users';
import Admins from '../../../containers/Admins';
import Accounts from '../../../containers/Accounts';
import Authorization from '../../base/Authorization';
import { useSelector } from 'react-redux';

const SuperAdmin = Authorization(['superAdmin']);

const propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.string,
};

function Permissions(props) {
  const role = useSelector(({ authentication }) => authentication.get('role'));
  const roleTitle = role === 'superAdmin' ? 'Super Admin' : 'Admin';

  return (
    <>
      <div className="c-permissions-header">
        <h2 className="c-permissions-header__title">{roleTitle} Permissions</h2>
        <p className="c-permissions-header__description">
          You are able to determine which users have admin privileges
        </p>
      </div>
      <Tabs role={role} />

      {/* Users is the default route */}
      <Route exact path={`${props.match.url}`}>
        <Redirect to={`${props.match.url}/users`} />
      </Route>
      {/* Users contains nested routes */}
      <Route path={`${props.match.url}/users`} component={Users} />
      {/* Admins contains nested routes */}
      <Route path={`${props.match.url}/admins`} component={SuperAdmin(Admins)} />
      <Route path={`${props.match.url}/accounts`} component={Accounts} />
    </>
  );
}

Permissions.propTypes = propTypes;

export default Permissions;
