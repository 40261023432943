import apiService from './api';
import {
  responseStatusIs
} from '../utils/formErrorHelpers';

const registrationErrorMessages = {
  noMatch: 'Could not find user to match given registration id',
  default: 'There was a problem registering. Please try again.'
};

function register(invitationId, name, password) {
  return apiService
    .post('/auth/register', { invitationId, name, password })
    .then(({ data }) => {
      return {
        _id: data.user._id,
        email: data.user.email,
        hasAgreedToConditionsOfAccess: data.user.hasAgreedToConditionsOfAccess,
        name: data.user.name,
        headers: {
          Authorization: data.token
        },
        role: data.user.role
      };
    })
    .catch((err) => {
      let errorMessage = registrationErrorMessages.default;

      if (responseStatusIs(err.response, 404)) {
        errorMessage = registrationErrorMessages.noMatch;
      }

      throw new Error(errorMessage);
    });
}

function requestResetToken(email) {
  return apiService
    .post('/auth/forgot-password', { email })
    .then((response) => {
      return {
        response: response.data.msg
      };
    })
    .catch((err) => {
      let errorMessage = 'There was a problem requesting a password reset token.';

      if (responseStatusIs(err.response, 404)) {
        errorMessage = `Could not find existing user ${email}`;
      } else if (responseStatusIs(err.response, 400)) {
        const fields = err.response.data.error.pointers.map((pointer) => pointer.field);
        errorMessage = `There was a problem requesting a password reset token on the field(s): ${fields.join(', ')}.`;
      }

      throw new Error(errorMessage);
    });
}

function resetPassword(token, password) {
  return apiService
    .post(`/auth/reset-password/${token}`, { password })
    .then(({ data }) => {
      return {
        _id: data.user._id,
        email: data.user.email,
        hasAgreedToConditionsOfAccess: data.user.hasAgreedToConditionsOfAccess,
        name: data.user.name,
        headers: {
          Authorization: data.token
        },
        role: data.user.role
      };
    })
    .catch((err) => {
      let errorMessage = 'There was a problem reseting your password. Please try again.';

      if (responseStatusIs(err.response, 404)) {
        errorMessage = 'Could not find user to match given token';
      }

      throw new Error(errorMessage);
    });
}

function signIn(email, password) {
  return apiService
    .post('/auth/login', { email, password })
    .then(({ data }) => {
      return {
        _id: data.user._id,
        email: data.user.email,
        hasAgreedToConditionsOfAccess: data.user.hasAgreedToConditionsOfAccess,
        name: data.user.name,
        headers: {
          Authorization: data.token
        },
        role: data.user.role
      };
    })
    .catch((err) => {
      let errorMessage = 'There was a problem signing in. Please try again.';

      if (responseStatusIs(err.response, 401)) {
        errorMessage = 'Your credentials could not be verified. Please try again.';
      }

      throw new Error(errorMessage);
    });
}

function signOut() {
  return apiService.delete('/auth/sign_out');
}

export default {
  register,
  registrationErrorMessages,
  requestResetToken,
  resetPassword,
  signIn,
  signOut
};
