import PropTypes from 'prop-types';

import { rbacRules } from '../../constants/rbac-rules';

const _check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

/**
 * See implementing RBAC: https://bit.ly/2JJd6Tr
 * @param {string} props.role - user.role
 * @param {string} props.perform - ruleTypes.PERFORM_ME
 * @param {function} props.yes - () => <div>Yes</div>
 * @param {function} [props.no] - () => <div>No</div>
 * @returns {*}
 * @constructor
 */
export const Can = props => {
  return _check(rbacRules, props.role, props.perform, props.data) ? props.yes() : props.no();
};

Can.propTypes = {
  /** Role of the current user */
  role: PropTypes.string.isRequired,
  /** The task to perform (e.g. project-info:edit) */
  perform: PropTypes.string.isRequired,
  /** The component to be displayed if user has permission */
  yes: PropTypes.func.isRequired,
  /** The component to be displayed if user was denied permission */
  no: PropTypes.func,
  /** The data to be evaluated for dynamic permissions */
  data: PropTypes.object,
};

Can.defaultProps = {
  no: () => null,
};
