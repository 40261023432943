import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { setErrors } from '../utils/formErrorHelpers';
import formValidationService from '../services/formValidation';
import FlashErrors from './base/FlashErrors';
import Header from './base/Header';
import MessageCard from './base/MessageCard';
import TextInput from './base/TextInput';

const propTypes = {
  actions: PropTypes.shape({
    authentication: PropTypes.shape({
      requestResetToken: PropTypes.func.isRequired,
    }),
  }),
  anyTouched: PropTypes.bool,
  error: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  router: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }),
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.submitPasswordReset = this.submitPasswordReset.bind(this);
    this.state = { resetRequested: false };
  }

  submitPasswordReset({ email }) {
    return this.props.actions.authentication
      .requestResetToken(email)
      .then(() => {
        this.setState({
          resetRequested: true,
        });
      })
      .catch(err => {
        throw new SubmissionError({
          _error: [err.message],
          email: true,
        });
      });
  }

  validateFields(values) {
    return new formValidationService.Validation().email(values.email).getErrors();
  }

  render() {
    return (
      <div className="l-sign-in">
        <Header />

        {this.state.resetRequested ? (
          <MessageCard>Password Reset Email Sent!</MessageCard>
        ) : (
          <div className="c-card">
            <h2 className="c-card__title">Reset Password</h2>
            <form className="c-form" onSubmit={this.props.handleSubmit(this.submitPasswordReset)}>
              <Field component={TextInput} label="Email" name="email" />
              <input
                className="c-button c-button--block"
                disabled={this.props.isActive}
                type="submit"
                value="Reset Password"
              />
              <FlashErrors errors={setErrors(this.props)} />
            </form>
          </div>
        )}
      </div>
    );
  }
}

ForgotPassword.propTypes = propTypes;

export default reduxForm({ form: 'forgotPassword' })(ForgotPassword);
