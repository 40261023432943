import { osTypes } from './os';

export const arBaseUrl = process.env.REACT_APP_AR_BASE_URL;

export const arExtension = {
  [osTypes.WINDOWS_PHONE]: null,
  [osTypes.UNKNOWN]: null,
  [osTypes.ANDROID]: 'glb',
  [osTypes.IOS]: 'usdz',
};
