import actionTypes from '../constants/actionTypes';
import authenticationService from '../services/authentication';
import usersService from '../services/users';

function registerFailure(err) {
  return {
    type: actionTypes.REGISTER_FAILURE,
    error: true,
    payload: err,
  };
}

function registerStart() {
  return {
    type: actionTypes.REGISTER_START,
  };
}

function registerSuccess(authenticationInfo) {
  return {
    type: actionTypes.REGISTER_SUCCESS,
    payload: { authenticationInfo },
  };
}

function requestResetTokenFailure(err) {
  return {
    type: actionTypes.REQUEST_RESET_TOKEN_FAILURE,
    error: true,
    payload: err,
  };
}

function requestResetTokenStart() {
  return {
    type: actionTypes.REQUEST_RESET_TOKEN_START,
  };
}

function requestResetTokenSuccess() {
  return {
    type: actionTypes.REQUEST_RESET_TOKEN_SUCCESS,
  };
}

function resetPasswordFailure(err) {
  return {
    type: actionTypes.RESET_PASSWORD_FAILURE,
    error: true,
    payload: err,
  };
}

function resetPasswordStart() {
  return {
    type: actionTypes.RESET_PASSWORD_START,
  };
}

function resetPasswordSuccess(authenticationInfo) {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    payload: { authenticationInfo },
  };
}

function signInFailure(err) {
  return {
    type: actionTypes.SIGN_IN_FAILURE,
    error: true,
    payload: err,
  };
}

function signInStart() {
  return {
    type: actionTypes.SIGN_IN_START,
  };
}

function signInSuccess(authenticationInfo) {
  return {
    type: actionTypes.SIGN_IN,
    payload: { authenticationInfo },
  };
}

function register(invitationId, name, password) {
  return function (dispatch) {
    dispatch(registerStart());

    return authenticationService
      .register(invitationId, name, password)
      .then(authenticationInfo => dispatch(registerSuccess(authenticationInfo)))
      .catch(err => {
        dispatch(registerFailure(err));
        throw err;
      });
  };
}

function requestResetToken(email) {
  return function (dispatch) {
    dispatch(requestResetTokenStart());

    return authenticationService
      .requestResetToken(email)
      .then(() => dispatch(requestResetTokenSuccess()))
      .catch(err => {
        dispatch(requestResetTokenFailure(err));
        throw err;
      });
  };
}

function resetPassword(token, password) {
  return function (dispatch) {
    dispatch(resetPasswordStart());

    return authenticationService
      .resetPassword(token, password)
      .then(authenticationInfo =>
        dispatch(resetPasswordSuccess(authenticationInfo))
      )
      .catch(err => {
        dispatch(resetPasswordFailure(err));
        throw err;
      });
  };
}

function signIn(email, password) {
  return function (dispatch) {
    dispatch(signInStart());

    return authenticationService
      .signIn(email, password)
      .then(authenticationInfo => dispatch(signInSuccess(authenticationInfo)))
      .catch(err => {
        dispatch(signInFailure(err));
        throw err;
      });
  };
}

function signOut() {
  return {
    type: actionTypes.SIGN_OUT,
  };
}

function updateCurrentUserSuccess(user) {
  return {
    type: actionTypes.UPDATE_CURRENT_USER,
    payload: { user },
  };
}

function updateCurrentUser() {
  return function (dispatch) {
    return usersService
      .getCurrentUser()
      .then(user => dispatch(updateCurrentUserSuccess(user)));
  };
}

export {
  register,
  requestResetToken,
  resetPassword,
  signIn,
  signOut,
  updateCurrentUser,
};
