import React from 'react';
import PropTypes from 'prop-types';
import ImmutableProptypes from 'react-immutable-proptypes';

const propTypes = {
  admin: PropTypes.shape({
    addOrg: PropTypes.func.isRequired,
    removeOrg: PropTypes.func.isRequired,
    orgs: ImmutableProptypes.set.isRequired
  }),
  org: PropTypes.object,
  user: PropTypes.shape({
    addOrg: PropTypes.func.isRequired,
    removeOrg: PropTypes.func.isRequired,
    orgs: ImmutableProptypes.set.isRequired
  })
};

function adjustOrgs(userType, isChecked, id) {
  return function() {
    isChecked ? userType.removeOrg(id) : userType.addOrg(id);
  };
}

function OrgCheckboxRow({ admin, org, user }) {
  const id = org.get('id');
  const adminChecked = admin.orgs.has(id);
  const userChecked = user.orgs.has(id);

  return (
    <div className="c-checkbox-row">
      <label
        htmlFor={id}
        className="c-checkbox-row__label"
      >
        {org.get('name')}
      </label>
      <div className="c-checkbox-row__check">
        <input
          type="checkbox"
          checked={adminChecked}
          onChange={adjustOrgs(admin, adminChecked, id)}
        />
      </div>
      <div className="c-checkbox-row__check">
        <input
          id={id}
          type="checkbox"
          checked={userChecked}
          onChange={adjustOrgs(user, userChecked, id)}
        />
      </div>
    </div>
  );
}

OrgCheckboxRow.propTypes = propTypes;

export {
  adjustOrgs
};

export default OrgCheckboxRow;
