import PropTypes from 'prop-types';
import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { setFilterTag } from '../../../utils/filterHelpers';
import { colors } from '../../base/styles';

/**
 * A presentational component that shows a list of Project Flags and their corresponding number of uses.
 * This component also shows a donut chart.
 *
 * @param {Array} flags - an array of flags that contains [title], [color] and [value] properties per item
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectFlagsSummary = ({ flags }) => {
  // external hoooks
  const history = useHistory();
  const location = useLocation();

  // compute the total number of projects
  const total = flags.reduce((a, b) => a + b.value, 0);

  const setFilterKeyword = keyword => {
    setFilterTag({ location, history, field: 'projectFlags._id', keyword });
  };

  return (
    <Container>
      <ReactTooltip place="top" type="dark" effect="solid" />
      <ProjectTagsContainer>
        {flags.map((flag, index) => {
          return (
            <TagContainer
              key={`${flag.label}-${index}`}
              onClick={() => {
                setFilterKeyword(flag._id);
              }}>
              <TagColor color={flag.color} />
              <TagTitle data-tip={flag.title}>
                <p>{flag.title}</p>
              </TagTitle>
              <TagCount>
                <p>{flag.value}</p>
              </TagCount>
            </TagContainer>
          );
        })}
      </ProjectTagsContainer>
      <ChartContainer>
        <PieContainer>
          <PieChart lineWidth={12} animate={true} data={flags} totalValue={total} />
        </PieContainer>
        <ProjectCount>{total} Projects</ProjectCount>
      </ChartContainer>
    </Container>
  );
};

ProjectFlagsSummary.propTypes = {
  flags: PropTypes.array.isRequired,
};

const PieContainer = styled.div`
  height: 260px;
  margin: 0.5rem 2rem;
  @media (max-width: 991.98px) {
    margin: 0.5rem 1rem;
  }
`;

const Container = styled.div`
  margin-right: 10px;
  height: inherit;
  background-color: white;
  flex: 2;
  min-width: 336px;
  border: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 405.98px) {
    min-width: unset;
    width: 100%;
  }

  @media (max-width: 725.98px) {
    margin-right: unset;
  }
`;

const ProjectTagsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: 260px;

  // firefox
  scrollbar-color: ${colors.grayLight} #fff;
  scrollbar-width: thin;

  // chrome, safari
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: whitesmoke;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grayLight};
    border-radius: 8px;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;

  &:hover {
    background-color: #e6e6e6;
    cursor: pointer;
  }
`;

const TagColor = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 10px;
  flex-shrink: 0;
`;

const TagTitle = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
`;

const TagCount = styled.div`
  margin-right: 10px;
  font-weight: bold;
`;

const ChartContainer = styled.div`
  flex: 1;
  position: relative;
`;

const ProjectCount = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 40%;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: #4a4a4a;
  transform: translate(-50%, -50%);

  @media (max-width: 991.98px) {
    font-size: 1rem;
  }
`;

export default ProjectFlagsSummary;
