import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import projectService from '../../../services/projects';
import Card from '../../base/Card';

const propTypes = {
  file: ImmutablePropTypes.contains({
    boxId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

class FileCard extends Component {
  constructor(props) {
    super(props);

    this.getPreviewURL = this.getPreviewURL.bind(this);
  }

  getPreviewURL() {
    const previewWindow = window.open('', '_blank');

    return projectService.getProjectFilePreviewURL(this.props.file.get('boxId')).then(url => {
      previewWindow.location.href = url;
    });
  }

  render() {
    return (
      <a className="c-card c-card--hoverable" onClick={this.getPreviewURL}>
        <Card item={this.props.file} imageUrl={`/files/${this.props.file.get('boxId')}/image`}>
          <h5 className="c-card__name">{this.props.file.get('name')}</h5>
        </Card>
      </a>
    );
  }
}

FileCard.propTypes = propTypes;

export default FileCard;
