import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = new Immutable.Map({
  filters: new Immutable.Map({
    isActive: false,
    items: new Immutable.Map()
  }),
  isActive: false,
  items: new Immutable.List()
});

function projects(state = INITIAL_STATE, action) {
  switch (action.type) {
  case actionTypes.GET_PROJECT_FILTERS_START:
    return state.setIn(['filters', 'isActive'], true);

  case actionTypes.GET_PROJECT_FILTERS_SUCCESS:
    return state.withMutations((map) => {
      map.setIn(['filters', 'isActive'], false);
      map.setIn(['filters', 'items'], Immutable.fromJS(action.payload.filters));
    });

  case actionTypes.GET_PROJECTS_START:
    return state.withMutations((map) => {
      map.delete('error');
      map.set('isActive', true);
    });

  case actionTypes.GET_PROJECTS_SUCCESS:
    return state.withMutations((map) => {
      map.set('currentPage', action.payload.page);
      map.set('isActive', false);
      map.set('items', Immutable.fromJS(action.payload.projects));
      map.set('totalPages', action.payload.pages);
      map.set('totalProjects', action.payload.total);
    });

  case actionTypes.GET_PROJECTS_FAILURE:
    return state.withMutations((map) => {
      map.delete('currentPage');
      map.set('error', action.payload);
      map.set('isActive', false);
      map.delete('totalPages');
      map.delete('totalProjects');
    });

  case actionTypes.SIGN_IN_START:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  default:
    return state;
  }
}

export default projects;
