import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PortalWithState } from 'react-portal';

const propTypes = {
  children: PropTypes.element.isRequired,
  handleClose: PropTypes.func.isRequired,
};

class Modal extends Component {
  componentDidMount() {
    this.content.focus();
  }

  render() {
    return (
      <PortalWithState closeOnEsc closeOnOutsideClick onClose={this.props.handleClose}>
        {({ openPortal, closePortal, isOpen, portal }) => (
          <div className="c-modal-container">
            <div className="c-modal-overlay" onClick={this.props.handleClose} />
            <div
              ref={content => {
                this.content = content;
              }}
              className="c-modal"
              tabIndex="0">
              {this.props.children}
            </div>
          </div>
        )}
      </PortalWithState>
    );
  }
}

Modal.propTypes = propTypes;

export default Modal;
