import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = Immutable.fromJS({
  details: {
    isActive: false,
    values: {},
  },
  files: {
    isActive: false,
    items: [],
  },
  rendering: {
    isActive: false,
  },
});

function projectDetails(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_PROJECT_DETAILS_START:
      return state.withMutations(map => {
        map.deleteIn(['details', 'error']);
        map.setIn(['details', 'isActive'], true);
      });

    case actionTypes.GET_PROJECT_DETAILS_SUCCESS:
      return state.withMutations(map => {
        map.setIn(['details', 'isActive'], false);
        map.setIn(['details', 'values'], Immutable.fromJS(action.payload.projectDetails));
      });

    case actionTypes.GET_PROJECT_DETAILS_FAILURE:
      return state.withMutations(map => {
        map.setIn(['details', 'error'], action.payload);
        map.setIn(['details', 'isActive'], false);
      });

    // TODO: implement START and FAILURE action types
    case actionTypes.UPDATE_PROJECT_SUCCESS:
      return state.withMutations(map => {
        map.setIn(['details', 'values'], Immutable.fromJS(action.payload.projectDetails));
      });

    case actionTypes.GET_PROJECT_FILES_START:
      return state.withMutations(map => {
        map.deleteIn(['files', 'error']);
        map.setIn(['files', 'isActive'], true);
      });

    case actionTypes.GET_PROJECT_FILES_SUCCESS:
      return state.withMutations(map => {
        map.setIn(['files', 'isActive'], false);
        map.setIn(['files', 'items'], Immutable.fromJS(action.payload.files));
      });

    case actionTypes.GET_PROJECT_FILES_FAILURE:
      return state.withMutations(map => {
        map.setIn(['files', 'error'], action.payload);
        map.setIn(['files', 'isActive'], false);
      });

    case actionTypes.GET_PROJECT_RENDERING_START:
      return state.withMutations(map => {
        map.setIn(['rendering', 'isActive'], true);
      });

    case actionTypes.GET_PROJECT_RENDERING_SUCCESS:
      return state.withMutations(map => {
        map.setIn(['rendering', 'isActive'], false);
        map.setIn(['rendering', 'embedUrl'], action.payload.embedUrl);
      });

    case actionTypes.GET_PROJECT_RENDERING_FAILURE:
      return state.withMutations(map => {
        map.setIn(['rendering', 'error'], action.payload);
        map.setIn(['rendering', 'isActive'], false);
      });

    case actionTypes.RESET_PROJECT_DETAILS:
      return state.withMutations(map => {
        map.deleteIn(['details', 'error']);
        map.setIn(['details', 'isActive'], false);
        map.setIn(['details', 'values'], new Immutable.Map());
      });

    case actionTypes.RESET_PROJECT_FILES:
      return state.setIn(['files', 'items'], new Immutable.List());

    case actionTypes.RESET_PROJECT_RENDERING:
      return state.withMutations(map => {
        map.deleteIn(['rendering', 'embedUrl']);
        map.deleteIn(['rendering', 'error']);
        map.setIn(['rendering', 'isActive'], false);
      });

    case actionTypes.SIGN_IN_START:
    case actionTypes.SIGN_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default projectDetails;
