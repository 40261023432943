import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as userDetailsActionCreators from '../actions/userDetails';
import * as usersActionCreators from '../actions/users';
import Admins from '../components/AccountPanel/Permissions/Admins';

const propTypes = {
  actions: PropTypes.shape({
    users: PropTypes.shape({
      getUsers: PropTypes.func.isRequired,
    }),
  }),
};

class ConnectedAdmins extends Component {
  componentDidMount() {
    this.props.actions.users.getUsers();
  }

  render() {
    return <Admins {...this.props} />;
  }
}

ConnectedAdmins.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    isActive: state.users.get('isActive'),
    users: state.users.get('users'),
    currentUserRole: state.authentication.get('role'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      users: bindActionCreators(usersActionCreators, dispatch),
      userDetails: bindActionCreators(userDetailsActionCreators, dispatch),
    },
  };
}

export { ConnectedAdmins as Admins, mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedAdmins);
