import _ from 'lodash';
import { parse } from 'query-string';
import React, { useMemo, useState } from 'react';
import { useScreenClass } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { FilterTags } from '../../components/Projects/CollapsibleFilters/FilterTags';

/**
 * A connected component that contains the state and logic
 * for the FilterTags presentational component
 * @param {*} props
 */
const ConnectedFilterTags = props => {
  // External hooks
  const location = useLocation();
  const screenClass = useScreenClass();

  // Store selectors
  const fields = useSelector(({ filters }) => filters.get('fields'));

  // Ephemeral states
  const [isMoreShown, setIsMoreShown] = useState(false);

  /** Output: [{ code, label, values: [] }] */
  const filters = useMemo(() => {
    const attributes = fields.get('attributes')?.toJS() || [];
    const metadata = fields.get('metadata')?.toJS() || [];
    const organizations = fields.get('organization')?.toJS() || [];
    const projectFlags = [{ code: 'projectFlags._id', label: 'Project Flag', values: [] }];

    if (!_.isEmpty(fields.get('projectFlags')?.toJS() || [])) {
      const flags = fields.get('projectFlags').toJS();
      projectFlags[0].values = flags.map(({ label, count }) => ({ value: label, count }));
    }

    return [...attributes, ...metadata, ...organizations, ...projectFlags];
  }, [fields]);

  /** Output: { attribute: ['foo', 'bar'] } */
  const knownCodes = useMemo(() => {
    return filters.map(filter => filter.code);
  }, [filters]);

  /** Output: { metadata.customer: "Customer Name" } */
  const dictionary = useMemo(() => {
    const dictionary = {};

    filters.forEach(filter => {
      dictionary[filter.code] = filter.label;
    });

    return dictionary;
  }, [filters]);

  /**
   * Input (search): { attribute: 'foo,bar', metadata: 'foo,bar' }
   * Output: [{ key: 'id', field: 'metadata.xx', label: 'Retail Channel', value: 'Big Box' }]
   */
  const tags = useMemo(() => {
    // { attribute: 'foo,bar' }
    const searchParams = parse(location.search);
    // [['attribute', 'foo,bar']]
    const entries = Object.entries(searchParams);
    // the output
    const tags = [];

    entries.forEach(([field, value]) => {
      if (!_.includes(knownCodes, field)) return null;

      // ['foo', 'bar']
      const filters = _.uniq(value.split(','));
      filters.forEach(filter => {
        tags.push({
          key: `${field}_${_.snakeCase(filter)}`,
          field: field,
          label: dictionary[field],
          value: filter,
        });
      });
    });

    return tags;
  }, [location.search, knownCodes, dictionary]);

  /** Toggle between showing more content or less content */
  const toggleShowMore = () => {
    setIsMoreShown(!isMoreShown);
  };

  const isScreenSmall = _.includes(['xs', 'sm', 'md'], screenClass);
  const filterTags = isScreenSmall ? tags.slice(0, !isMoreShown ? 3 : undefined) : tags;

  return (
    <FilterTags
      {...props}
      filterTags={filterTags}
      tags={tags}
      isMoreShown={isMoreShown}
      isScreenSmall={isScreenSmall}
      toggleShowMore={toggleShowMore}
    />
  );
};

export default ConnectedFilterTags;
