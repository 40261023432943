import apiService from './api';

function getExpandedOrganizations() {
  return apiService
    .get('/accounts/organizations?expanded=true')
    .then(({ data }) => data.organizations);
}

function getOrganizations() {
  return apiService
    .get('/accounts/organizations')
    .then(({ data }) => data.organizations);
}

export default {
  getExpandedOrganizations,
  getOrganizations
};
