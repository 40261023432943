import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { fieldicons, JiraField } from './JiraField';
import { Select } from './styles';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const jiraSelectProps = {
  type: PropTypes.oneOf(['controlled', 'uncontrolled']),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    ImmutablePropTypes.listOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  ]),
  justify: PropTypes.oneOf(['flex-start', 'flex-end']),
  placeholder: PropTypes.string,
  shownIcons: PropTypes.arrayOf(PropTypes.string),
  initialValue: PropTypes.string,
  onFieldSubmit: PropTypes.func,
  value: PropTypes.string,
  onChangeText: PropTypes.func,
};

/**
 * Rewrite of HoverableSelect - without dependency to redux form
 * HoverableSelect is a text input component for jira-style editable fields.
 * @param props
 * @example
 * ```
 * // uncontrolled
 * <JiraSelect
 *  initialValue="Initial value"
 *  onFieldSubmit={value => console.log('value -', value)}
 *  options={[
 *     { label: 'hello world', value: 'HELLO_WORLD' },
 *   ]}
 *  />
 *
 * // controlled
 * const [value, setValue] = React.useState('');
 * ...
 * <JiraSelect
 *  type="controlled"
 *  value={value}
 *  onChangeText={setValue}
 *  initialValue="Initial value"
 *  onFieldSubmit={value => console.log('value -', value)}
 *  options={[
 *     { label: 'hello world', value: 'HELLO_WORLD' },
 *   ]}
 *  />
 * ```
 * @returns {JSX.Element}
 * @constructor
 */
export const JiraSelect = props => {
  const [uncontrolledValue, setUncontrolledValue] = useState(props.initialValue);

  const value = props.type === 'controlled' ? props.value : uncontrolledValue;
  const setValue = props.type === 'controlled' ? props.onChangeText : setUncontrolledValue;

  const optionsDictionary = {};
  props.options?.forEach(option => {
    optionsDictionary[option.value] = option.label;
  });

  return (
    <JiraField
      value={value}
      setValue={setValue}
      placeholder={props.placeholder}
      initialValue={props.initialValue}
      onFieldSubmit={props.onFieldSubmit}
      shownIcons={props.shownIcons}
      justify={props.justify}
      title={optionsDictionary[value]}>
      <Select autoFocus value={value} onChange={e => setValue(e.target.value)}>
        <option value="">Select one</option>
        {props.options?.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </JiraField>
  );
};

JiraSelect.propTypes = jiraSelectProps;

JiraSelect.defaultProps = {
  type: 'uncontrolled',
  options: [],
  shownIcons: [fieldicons.SUBMIT, fieldicons.CANCEL],
  value: '',
  onChangeText: () => {},
};
