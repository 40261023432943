import React, { useState } from 'react';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 6,
    height: 10,
    width: 10,
    flexShrink: 0,
  },
});

const colourStyles = {
  control: styles => ({
    ...styles,
    borderRadius: 2,
    backgroundColor: 'white',
    minHeight: 30,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot(), height: 15 }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  dropdownIndicator: styles => ({
    ...styles,
    margin: 0,
    padding: 0,
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: 6,
  }),
  indicatorsContainer: styles => ({
    ...styles,
    height: 15,
    alignSelf: 'center',
    marginRight: 3,
  }),
};

/**
 * A simple presentation component that shows a selection of project flags.
 * @param {Object} defaultValue - The default value shown to the user
 * @param {Array} options - an array of options compatible with react-select
 * @param {Function} onChange - function called whenever a change is detected within the selection
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectFlagsSelect = ({ defaultValue, options, onChange }) => {
  const [tip, setTip] = useState(defaultValue?.label || '');
  return (
    <>
      <ReactTooltip effect="solid" />
      <div data-tip={tip}>
        <Select
          styles={colourStyles}
          defaultValue={defaultValue}
          options={options}
          onChange={data => {
            onChange(data.value);
            setTip(data.label);
          }}
        />
      </div>
    </>
  );
};

ProjectFlagsSelect.propTypes = {
  defaultValue: PropTypes.object,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProjectFlagsSelect;
