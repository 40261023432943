import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Link } from 'react-router-dom';

import { setErrors } from '../utils/formErrorHelpers';
import FlashErrors from './base/FlashErrors';
import formValidationService from '../services/formValidation';
import Header from './base/Header';
import TextInput from './base/TextInput';

const propTypes = {
  actions: PropTypes.shape({
    authentication: PropTypes.shape({
      signIn: PropTypes.func.isRequired,
    }),
    form: PropTypes.shape({
      change: PropTypes.func.isRequired,
      initialize: PropTypes.func.isRequired,
    }),
  }),
  anyTouched: PropTypes.bool,
  error: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  // Nonexistent in ReactRouter v4+
  // location: PropTypes.shape({
  //   query: PropTypes.shape({
  //     next: PropTypes.string,
  //   }).isRequired,
  // }).isRequired,
  // router: PropTypes.shape({
  //   replace: PropTypes.func.isRequired,
  // }),
};

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { from } = nextProps.location?.state || { from: { pathname: '/' } };
    const { search } = nextProps.location;

    const hasFinishedSignInRequest =
      this.props.isActive && !nextProps.isActive && nextProps.headers;

    if (hasFinishedSignInRequest && nextProps.hasAgreedToConditionsOfAccess) {
      nextProps.history.replace(from);
    } else if (hasFinishedSignInRequest) {
      nextProps.history.replace({
        pathname: '/conditions-of-access',
        search,
      });
    }
  }

  signIn({ email, password }) {
    return this.props.actions.authentication.signIn(email.trim(), password).catch(err => {
      throw new SubmissionError({
        _error: [err.message],
      });
    });
  }

  validateFields(values) {
    return new formValidationService.Validation()
      .email(values.email)
      .password(values.password)
      .getErrors();
  }

  render() {
    return (
      <div className="l-sign-in">
        <Header />
        <div className="c-card">
          <h2 className="c-card__title">Sign in to Your Account</h2>
          <form className="c-form" onSubmit={this.props.handleSubmit(this.signIn)}>
            <Field component={TextInput} label="Email" name="email" />
            <Field component={TextInput} label="Password" name="password" type="password" />
            <FlashErrors errors={setErrors(this.props)} />
            <input
              className="c-button c-button--block"
              disabled={this.props.isActive}
              type="submit"
              value="Sign In"
            />
          </form>
          <div className="c-card__links">
            <Link to="/forgot-password">Reset Password</Link>
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = propTypes;

export default reduxForm({ form: 'signIn', validate: SignIn.prototype.validateFields })(SignIn);
