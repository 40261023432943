import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import MainFilters from '../../components/Projects/CollapsibleFilters/MainFilters';

const ConnectedMainFilters = props => {
  const fields = useSelector(({ filters }) => filters.get('fields'));

  // 3 special filters
  const specialFilters = useMemo(() => {
    const included = ['metadata.retailchannel', 'metadata.typeofdisplay', 'organization.name'];

    const all = [
      ...(fields.get('metadata') || []),
      ...(fields.get('attributes') || []),
      ...(fields.get('organization') || []),
    ];

    return all.filter(filter => {
      const code = filter.get('code');

      return _.includes(included, code);
    });
  }, [fields]);

  return <MainFilters {...props} specialFilters={specialFilters} />;
};

export default ConnectedMainFilters;
