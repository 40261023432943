import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  _id: '',
  name: '',
  email: '',
  role: '',
  userOrganizations: new Immutable.Set(),
  adminOrganizations: new Immutable.Set(),
  changed: false,
  updated: false
});

function userDetails(state = INITIAL_STATE, action) { // eslint-disable-line complexity
  switch (action.type) {
  case actionTypes.ADD_ALL_ADMIN_ORGS: {
    const actionOrgs = action.payload.orgs.map((org) => org.get('id'));
    return state.withMutations((map) => {
      map.set('adminOrganizations', new Immutable.Set(actionOrgs));
      map.set('userOrganizations', new Immutable.Set(actionOrgs));
      map.set('changed', true);
    });
  }

  case actionTypes.ADD_ALL_USER_ORGS:
    return state.withMutations((map) => {
      map.set('userOrganizations', new Immutable.Set(action.payload.orgs.map((org) => org.get('id'))));
      map.set('changed', true);
    });

  case actionTypes.ADD_ADMIN_ORG:
    return state.withMutations((map) => {
      map.update('adminOrganizations', (set) => set.add(action.payload.id));
      map.update('userOrganizations', (set) => set.add(action.payload.id));
      map.set('changed', true);
    });

  case actionTypes.ADD_USER_ORG:
    return state.withMutations((map) => {
      map.update('userOrganizations', (set) => set.add(action.payload.id));
      map.set('changed', true);
    });

  case actionTypes.CLEAR_SELECTED_USER:
  case actionTypes.SIGN_IN_START:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  case actionTypes.REMOVE_ADMIN_ORG:
    return state.withMutations((map) => {
      map.update('adminOrganizations', (set) => set.delete(action.payload.id));
      map.set('changed', true);
    });

  case actionTypes.REMOVE_USER_ORG:
    return state.withMutations((map) => {
      map.update('adminOrganizations', (set) => set.delete(action.payload.id));
      map.update('userOrganizations', (set) => set.delete(action.payload.id));
      map.set('changed', true);
    });

  case actionTypes.REMOVE_ALL_ADMIN_ORGS:
    return state.withMutations((map) => {
      map.update('adminOrganizations', (set) => set.clear());
      map.set('changed', true);
    });

  case actionTypes.REMOVE_ALL_USER_ORGS:
    return state.withMutations((map) => {
      map.update('adminOrganizations', (set) => set.clear());
      map.update('userOrganizations', (set) => set.clear());
      map.set('changed', true);
    });

  case actionTypes.FIND_USER_BY_ID_SUCCESS:
  case actionTypes.SET_SELECTED_USER:
    return Immutable.fromJS({
      ...action.payload.user,
      adminOrganizations: new Immutable.Set(action.payload.user.adminOrganizations),
      userOrganizations: new Immutable.Set(action.payload.user.userOrganizations),
      changed: false,
      isActive: false,
      updated: false
    });

  case actionTypes.FIND_USER_BY_ID_FAILURE:
  case actionTypes.UPDATE_USER_ORGANIZATIONS_FAILURE:
    return state.withMutations((map) => {
      map.set('error', action.payload);
      map.set('isActive', false);
    });

  case actionTypes.FIND_USER_BY_ID_START:
  case actionTypes.UPDATE_USER_ORGANIZATIONS_START:
    return state.withMutations((map) => {
      map.delete('error');
      map.set('isActive', true);
    });

  case actionTypes.UPDATE_USER_ORGANIZATIONS_SUCCESS:
    return Immutable.fromJS({
      ...action.payload.user,
      adminOrganizations: new Immutable.Set(action.payload.user.adminOrganizations),
      userOrganizations: new Immutable.Set(action.payload.user.userOrganizations),
      isActive: false,
      changed: false,
      updated: true
    });

  case actionTypes.DELETE_INVITE_FAILURE:
  case actionTypes.DELETE_USER_FAILURE:
  case actionTypes.DELETE_INVITE_SUCCESS:
  case actionTypes.DELETE_USER_SUCCESS:
    return state.withMutations((map) => {
      map.set('isActive', false);
    });

  case actionTypes.DELETE_INVITE_START:
  case actionTypes.DELETE_USER_START:
    return state.withMutations((map) => {
      map.set('isActive', true);
    });

  default:
    return state;
  }
}

export default userDetails;
