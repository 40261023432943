import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link, Route, Switch } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { editIcon, trashIcon } from '../../../assets/images';
import { ruleTypes } from '../../../constants/rbac-rules';
import AddAttribute from '../../../containers/Attributes/AddAttribute.jsx';
import EditAttribute from '../../../containers/Attributes/EditAttribute.jsx';
import Authorization from '../../base/Authorization';
import { Can } from '../../base/Can';
import { Dialog } from '../../base/Dialog';
import { IconButton } from '../../base/IconButton';
import PlusIcon from '../../base/icons/PlusIcon.jsx';
import LineItemError from '../Permissions/LineItemError.jsx';
import { DataTableContainer } from '../ProjectFlags/styles.js';
import { customStyles, getEnabledCell, getTooltipCell } from '../ProjectFlags/table-contents.js';
import { ButtonRow, Header, Loader } from '../styles';

const SuperAdmin = Authorization(['superAdmin']);

const Attributes = props => {
  const columns = useMemo(() => {
    const isAdmin = props.role === 'superAdmin';
    const initialColumns = [
      {
        id: 'code',
        name: 'Code',
        selector: 'code',
        minWidth: '150px',
        maxWidth: '240px',
        cell: _renderCode,
      },
      {
        id: 'label',
        name: 'Label',
        minWidth: '150px',
        maxWidth: '240px',
        cell: (row, index, _, id) => getTooltipCell(row.label, index, id),
      },
      {
        id: 'enabled',
        name: 'Enabled',
        selector: 'isEnabled',
        maxWidth: '100px',
        center: true,
        cell: getEnabledCell,
      },
      {
        id: 'usage',
        name: 'Usage',
        selector: 'usageCount',
        maxWidth: '100px',
        center: true,
      },
    ];
    const actionsColumn = {
      id: 'actions',
      name: 'Actions',
      width: '100px',
      center: true,
      cell: attribute => _renderActions(attribute, props),
    };
    return isAdmin ? [...initialColumns, actionsColumn] : initialColumns;
  }, [props]);

  return (
    <>
      <Header className="c-permissions-header">
        <HeaderTitle className="c-permissions-header__title">Attributes</HeaderTitle>
        <HeaderSubtitle className="c-permissions-header__description">
          Below is a list of current project attributes. Use the add new attribute button to enable
          new attributes. Existing attributes can be edited by selecting the pencil or deleted by
          selecting the trashcan. If any attributes are currently in use, they cannot be deleted. If
          pencil and/or trashcan icon are greyed out, please contact the system administrator.
        </HeaderSubtitle>
      </Header>
      <ButtonRow className="c-button-row c-button-row--notabtop" active={props.isActive}>
        {props.isActive && (
          <Loader>
            <PuffLoader color="#3d7cbf" size={24} />
            <div>Loading...</div>
          </Loader>
        )}
        <Can
          role={props.role}
          perform={ruleTypes.ATTRIBUTES__ADD}
          yes={() => (
            <Link to="/admin/attributes/add" className="c-button c-button--hollow c-button--small">
              <PlusIcon className="c-button__icon" />
              <span>Add New Attribute</span>
            </Link>
          )}
        />
      </ButtonRow>
      <DataTableContainer>
        <DataTable
          noHeader
          columns={columns}
          data={props.attributes.toJS()}
          customStyles={customStyles}
        />
      </DataTableContainer>
      {!props.isActive && !props.attributes.size && (
        <LineItemError
          title="There's nothing here"
          subtitle="Have you tried adding a new attribute?"
        />
      )}
      {props.isDialogOpen && (
        <Dialog
          title="Delete an Attribute"
          subtitle={`Attribute with code: ${props.deleteAttrib?.code}`}
          body="Are you sure you want to delete this attribute?"
          handleClose={props.cancelDelete}
          handleCancel={props.cancelDelete}
          handleConfirm={props.deleteAttribute}
        />
      )}
      <Switch>
        <Route path={`${props.match.url}/add`} component={SuperAdmin(AddAttribute)} />
        <Route path={`${props.match.url}/:id/edit`} component={SuperAdmin(EditAttribute)} />
      </Switch>
    </>
  );
};

Attributes.propTypes = {
  attributeData: PropTypes.object.isRequired,
  attributes: ImmutablePropTypes.list.isRequired,
  cancelDelete: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  confirmDelete: PropTypes.func,
  deleteAttrib: ImmutablePropTypes.map.isRequired,
  deleteAttribute: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.string.isRequired,
};

const _renderCode = attribute => (
  <CodeContainer>
    <Tooltip data-tip data-for={attribute._id}>
      <Code>{attribute.code}</Code>
    </Tooltip>
    <ReactTooltip id={attribute._id} effect="solid">
      <span>{attribute.code}</span>
    </ReactTooltip>
  </CodeContainer>
);

const _renderActions = (attribute, props) => {
  const { _id, usageCount } = attribute;
  const onEdit = () => props.history.push(`${props.match.url}/${_id}/edit`);
  const onDelete = () => props.confirmDelete(attribute);
  const dataFor = usageCount > 0 ? 'delete-tip' : '';
  const deleteId = props.deleteAttrib?._id;
  const disabled = usageCount > 0 || (deleteId === _id && props.isDeleting);

  return (
    <>
      <Can
        role={props.role}
        perform={ruleTypes.ATTRIBUTES__EDIT}
        yes={() => <IconButton alt="Edit" src={editIcon} onClick={onEdit} />}
      />
      <Can
        role={props.role}
        perform={ruleTypes.ATTRIBUTES__DELETE}
        yes={() => (
          <DeleteContainer>
            <Tooltip data-tip data-for={dataFor}>
              <IconButton alt="Delete" src={trashIcon} onClick={onDelete} disabled={disabled} />
            </Tooltip>
            <ReactTooltip id="delete-tip" effect="solid">
              <span>Attribute currently in use</span>
            </ReactTooltip>
          </DeleteContainer>
        )}
      />
    </>
  );
};

const styles = {
  actions: { textAlign: 'center' },
};

const HeaderTitle = styled.h2``;

const HeaderSubtitle = styled.p`
  max-width: unset;
`;

const DeleteContainer = styled.div``;

const Tooltip = styled.span``;

const CodeContainer = styled.div`
  width: 100%;
`;

const Code = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Attributes;
