import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ component: Component, authentication, ...rest }) {
  const token = authentication.getIn(['headers', 'Authorization']);
  return (
    <Route
      {...rest}
      render={props => {
        return token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
        );
      }}
    />
  );
}

const mapStateToProps = state => ({
  authentication: state.authentication,
});

export default connect(mapStateToProps, {})(PrivateRoute);
