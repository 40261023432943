import React from 'react';
import PropTypes from 'prop-types';
import ImmutableProptypes from 'react-immutable-proptypes';

import DeleteInvite from './DeleteInvite';
import DeleteUser from './DeleteUser';

const propTypes = {
  actions: PropTypes.shape({
    invitations: PropTypes.shape({
      deleteInvite: PropTypes.func.isRequired,
    }),
    users: PropTypes.shape({
      getUsers: PropTypes.func,
    }),
    userDetails: PropTypes.shape({
      clearSelectedUser: PropTypes.func,
      deleteUser: PropTypes.func,
      setSelectedUser: PropTypes.func,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  selectedUser: ImmutableProptypes.map.isRequired,
};

function Delete(props) {
  const { selectedUser } = props;
  const selectedUserType = selectedUser.get('type');

  return (
    <div>
      {(() => {
        switch (selectedUserType) {
          case 'invitation':
            return <DeleteInvite {...props} />;

          case 'user':
            return <DeleteUser {...props} />;

          default:
            return null;
        }
      })()}
    </div>
  );
}

Delete.propTypes = propTypes;

export default Delete;
