import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import styled from 'styled-components';

import XIcon from './icons/XIcon';

/**
 * A simple modal component to confirm user sign out
 *
 * @param {Function} cancelHandler - Called when the user wants to cancel the sign out process
 * @param {Function} nextHandler - Called when the user wants to continue with the sign out process
 *
 * @returns {JSX.Element}
 * @constructor
 */
const SignOutModal = ({ cancelHandler, nextHandler }) => {
  return (
    <Portal>
      <div className="c-modal-container">
        <div className="c-modal-overlay" />
        <div className="c-modal" tabIndex="0">
          <IslandContainer className="c-island">
            <div className="c-island__exit" onClick={cancelHandler}>
              <XIcon />
            </div>
            <div className="c-island__header">
              <h3 id="invite-title">Confirm Sign Out</h3>
              <span id="invite-subtitle">Are you sure you want to sign out?</span>
            </div>
            <div style={{}}>
              <ActionButtonsContainer className="c-button-row c-button-row--modal">
                <div className="c-button c-button--small c-button--hollow" onClick={cancelHandler}>
                  Cancel
                </div>
                <button onClick={nextHandler} className="c-button c-button--small">
                  Confirm
                </button>
              </ActionButtonsContainer>
            </div>
          </IslandContainer>
        </div>
      </div>
    </Portal>
  );
};

SignOutModal.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  nextHandler: PropTypes.func.isRequired,
};

const IslandContainer = styled.div`
  padding-bottom: 30px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default SignOutModal;
