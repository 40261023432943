import backIcon from './back-arrow.svg';
import backspaceIcon from './backspace.svg';
import checkIcon from './check.svg';
import deleteIcon from './delete.svg';
import editIcon from './edit.svg';
import filesPlaceholder from './files-placeholder.jpeg';
import logoV2 from './logo-v2.svg';
import logo from './logo.svg';
import searchIcon from './search.svg';
import settingsIcon from './settings.svg';
import trashIcon from './trash.svg';
import xIcon from './x.svg';
import hoodLogo from './hood-platform-logo.png';

// declaration file for images
export {
  backIcon,
  backspaceIcon,
  checkIcon,
  deleteIcon,
  editIcon,
  filesPlaceholder,
  logoV2,
  logo,
  searchIcon,
  settingsIcon,
  trashIcon,
  xIcon,
  hoodLogo,
};
