import _ from 'lodash';
import React, { useState } from 'react';
import { Col, Container, Hidden, Row, useScreenClass } from 'react-grid-system';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';
import QRCode from 'qrcode.react';

import { ruleTypes } from '../../constants/rbac-rules';
import DynamicAttributeForm from '../../containers/DynamicAttributeForm';
import DynamicAttributesList from '../../containers/DynamicAttributesList';
import ProjectFlagDropdown from '../../containers/ProjectFlags/ProjectFlagDropdown';
import ProjectInformationList from '../../containers/ProjectInformationList';
import { arBaseUrl, arExtension } from '../../utils/ar';
import { formatLastUpdated } from '../../utils/dates';
import { detectOs } from '../../utils/os';
import { getLink } from '../../utils/url';
import { Can } from '../base/Can';
import { JiraInput } from '../base/fields/JiraInput';
import ProtectedImage from '../base/ProtectedImage';
import { Gap } from '../base/styles';
import { ProjectLinks } from './ProjectLinks';
import RenderedPreview from './RenderedPreview';
import { H1, Hr, Regular, Span } from './styles';

const _ProjectDetailSkeleton = props => (
  <SkeletonContainer>
    <Hidden sm md lg xl xxl>
      <Gap height={32} />
    </Hidden>
    <Skeleton />
    <Skeleton width="75%" />
    <Skeleton width="50%" />
    <Gap height={12} />
    <Skeleton width="85%" />
    <Skeleton width="25%" />
    <Skeleton width="50%" />
    <Skeleton width="30%" />
    <Gap height={32} />
    {[...Array(8)].map((_, index) => (
      <Row key={`key-${index}`} style={{ marginBottom: 16 }}>
        <Col lg={6}>
          <Skeleton />
        </Col>
        <Col lg={6}>
          <Skeleton />
        </Col>
      </Row>
    ))}
  </SkeletonContainer>
);

export const ProjectInfo = props => {
  const [isQrShown, setIsQrShown] = useState(false);

  const { details } = props;
  const [...attribKeys] = details.get('attributes')?.keys() || [];

  const screenClass = useScreenClass();
  const isScreenSmall = _.includes(['xs', 'sm', 'md'], screenClass);
  const hrGap = isScreenSmall ? 18 : 8;
  const isAdmin = _.includes(['admin', 'superAdmin'], props.role);

  /**
   * Opens a new window following the link
   * Ref: https://stackoverflow.com/a/12939966/8674390
   * @param {string} link
   */
  const openLinkOnWindow = link => {
    const dimension = `width=${window.innerWidth},height=${window.innerHeight}`;
    window.open(getLink(link), 'newwindow', dimension);
    return false;
  };

  const updateProjectTitle = value => {
    props.updateProject(details.get('boxId'), {
      metadata: { projectname: value },
    });
  };

  const openArLink = () => {
    const os = detectOs();
    const ext = arExtension[os];
    const cpn = details.get('boxId');

    if (ext) {
      // open glb/usdz on android/ios
      // ios ex: https://ar-platform-ar-models-81289840.s3.us-east-1.amazonaws.com/usdz/8815984.usdz
      // android ex: https://ar-platform-ar-models-81289840.s3.us-east-1.amazonaws.com/glb/8815984.glb
      window.open(`${arBaseUrl}/${ext}/${cpn}.${ext}`);
    } else {
      // show qr
      // web ex: https://ar-platform-ar-models-81289840.s3.us-east-1.amazonaws.com/arview?projectNumber=8815984
      setIsQrShown(!isQrShown);
    }
  };

  return (
    <Parent>
      <Container fluid>
        <Row>
          <Col sm={6}>
            <RenderContainer>
              {details.has('renderingId') && <RenderedPreview rendering={props.rendering} />}
              {!details.has('renderingId') && details.has('boxId') && (
                <Image url={`/projects/${details.get('boxId')}/image`} />
              )}
              {isQrShown && (
                <QrContainer>
                  <QRCode
                    value={`${arBaseUrl}/arview.html?id=${details.get('boxId')}`}
                    renderAs={'svg'}
                  />
                  <QrText>
                    AR Requires a rear-facing camera. Use this code to view on your device
                  </QrText>
                </QrContainer>
              )}
            </RenderContainer>

            <VRButtons>
              <LinkButton
                className="c-button c-button--small c-button--block c-button--hollow"
                onClick={openArLink}
                disabled={
                  !details.get('isArEnabled')
                }>
                Live View (AR)
              </LinkButton>
              <LinkButton
                className="c-button c-button--small c-button--block c-button--hollow"
                onClick={() => openLinkOnWindow(details.get('vrLink'))}
                disabled={!details.get('vrLink')}>
                Virtual Reality (VR)
              </LinkButton>
            </VRButtons>
          </Col>
          <Col sm={6}>
            {props.loading ? (
              <_ProjectDetailSkeleton />
            ) : (
              <>
                <Hidden sm md lg xl xxl>
                  <Gap height={32} />
                </Hidden>
                <Row>
                  <Col lg={7}>
                    <H1>PROJECT INFORMATION</H1>
                    <Regular>Last updated: {formatLastUpdated(details.get('lastUpdated'))}</Regular>
                  </Col>
                  <Col lg={5}>
                    {isScreenSmall && <Gap height={12} />}
                    <ProjectFlagDropdown project={props.details} />
                  </Col>
                </Row>
                <Hr percent={100} top={16} bottom={isScreenSmall ? 16 : 8} />
                <Can
                  role={props.role}
                  perform={ruleTypes.PROJECT_INFO__EDIT}
                  no={() => (
                    <ProjectName>
                      <H1>{details.getIn(['metadata', 'projectname']) || '-'}</H1>
                    </ProjectName>
                  )}
                  yes={() => (
                    <JiraInputContainer isScreenSmall={isScreenSmall}>
                      <JiraInput
                        full={true}
                        buttonTextStyle={styles.projectTitle}
                        inputStyle={styles.projectTitle}
                        placeholder="-"
                        initialValue={details.getIn(['metadata', 'projectname']) || ''}
                        maxLength={250}
                        name={'Project Title'}
                        disallowEmptyValues
                        onFieldSubmit={updateProjectTitle}
                      />
                    </JiraInputContainer>
                  )}
                />
                <Row align="center">
                  <Col lg={6}>
                    {isScreenSmall && <Gap height={8} />}
                    <Regular>
                      Name: <Span bold>{details.get('name')}</Span>
                    </Regular>
                  </Col>
                  <Col lg={6}>
                    {isScreenSmall && <Gap height={8} />}
                    <Regular>{details.getIn(['organization', 'name'])}</Regular>
                  </Col>
                </Row>
                <Hr percent={100} top={15} bottom={10} />
                {/* Project Information */}
                <ProjectInformationList details={details} />
                {/* Dynamic Attributes */}
                <Hr percent={100} top={hrGap} bottom={hrGap} />
                <DynamicAttributesList />
                {Boolean(!attribKeys.length && !isScreenSmall) && <Gap height={3} />}
                {Boolean(attribKeys.length && isScreenSmall && isAdmin) && <Gap height={12} />}
                <DynamicAttributeForm />
                {Boolean(attribKeys.length || isAdmin) && (
                  <Hr percent={100} top={hrGap} bottom={hrGap} />
                )}
                {/* Project Links */}
                <Can
                  role={props.role}
                  perform={ruleTypes.PROJECT_LINKS__EDIT}
                  yes={() => <ProjectLinks />}
                />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </Parent>
  );
};

const styles = {
  projectTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    color: '#000',
  },
};

const ProjectName = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
`;

const JiraInputContainer = styled.div`
  margin-bottom: 4px;
  ${({ isScreenSmall }) =>
    isScreenSmall &&
    css`
      .jira-field-button {
        background-color: #ebecf0;
        width: 100%;
      }
    `}
`;

const Parent = styled.div`
  margin: 0 auto 3rem;
  max-width: 60rem;
  color: #4a4a4a;
`;

const Image = styled(ProtectedImage)`
  background-color: #fff;
  background-position: center;
  background-size: cover;

  border-radius: 5px;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

const SkeletonContainer = styled.div``;

const VRButtons = styled.div`
  display: flex;
  margin-top: 1rem;

  & > :not(:last-child) {
    margin-right: 0.65rem;
  }
`;

const LinkButton = styled.button`
  outline: none;
`;

const RenderContainer = styled.div`
  position: relative;
`;

const QrContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  background-color: #fff;
  max-width: 144px;
  border: 1px solid #3d7cbf;
  border-radius: 0.3125rem;
  -webkit-box-shadow: 0px 0px 15px -5px rgba(138, 138, 138, 0.47);
  -moz-box-shadow: 0px 0px 15px -5px rgba(138, 138, 138, 0.47);
  box-shadow: 0px 0px 15px -5px rgba(138, 138, 138, 0.47);
`;

const QrText = styled.div`
  margin-top: 8px;
  font-size: 10px;
  text-align: center;
  opacity: 0.5;
`;
