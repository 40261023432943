import _ from 'lodash';
import React, {
  Component
} from 'react';
import PropTypes from 'prop-types';
import apiService from '../../services/api';

const propTypes = {
  url: PropTypes.string.isRequired,
  style: PropTypes.object
};

class ProtectedImage extends Component {
  constructor(props) {
    super(props);
    this.hasUnmounted = false;
    this.state = {};
  }

  componentWillMount() {
    this.getImageUrl(this.props.url);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.getImageUrl(nextProps.url);
    }
  }

  componentWillUnmount() {
    this.hasUnmounted = true;
  }

  getImageUrl(url) {
    return apiService.get(url, { responseType: 'blob' })
      .then(({ data }) => {
        if (!this.hasUnmounted) {
          const createObjectURL = (global.URL || global.webkitURL).createObjectURL;
          this.setState({ url: createObjectURL(data) });
        }
      });
  }

  render() {
    const props = _.omit(this.props, ['style', 'url']);
    const combinedStyle = {
      ...this.props.style
    };

    if (this.state.url) {
      combinedStyle.backgroundImage = `url(${this.state.url})`;
    }

    return <div {...props} style={combinedStyle} />;
  }
}

ProtectedImage.propTypes = propTypes;

export default ProtectedImage;
