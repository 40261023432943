import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
const propTypes = {
  children: PropTypes.node
};

function MessageCard(props) {
  const { children } = props;

  return (
    <div className="c-card">
      <h2 className="c-card__title">{children}</h2>
      <div className="c-card__links">
        <Link to="/">Return Home</Link>
      </div>
    </div>
  );
}

MessageCard.propTypes = propTypes;

export default MessageCard;
