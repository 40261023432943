export const ruleTypes = {
  PROJECT_INFO__EDIT: 'project-info:edit', // edit project information on project details
  ATTRIBUTES__ADD: 'attributes:add', // add new attribute via admin panel
  ATTRIBUTES__EDIT: 'attributes:edit', // edit an attribute via admin panel
  ATTRIBUTES__DELETE: 'attributes:delete', // delete an attribute via admin panel
  DYNAMIC_ATTRIBUTES__ADD: 'dynamic-attributes:add', // add a new dynamic attribute on project details
  DYNAMIC_ATTRIBUTES__EDIT: 'dynamic-attributes:edit', // edit a dynamic attribute on project details
  PROJECT_FLAGS__ADD: 'project-flags:add', // add a project flag on admin panel
  PROJECT_FLAGS__EDIT: 'project-flags:edit', // edit project flag on admin panel
  PROJECT_FLAGS__DELETE: 'project-flags:delete', // delete project flag on admin panel
  PROJECT_LINKS__EDIT: 'project-links:edit', // ar, vr, 3d links
  FILE_EXCLUSIION_RULES__ADD: 'file-exclusion-rules:add',
  FILE_EXCLUSIION_RULES__EDIT: 'file-exclusion-rules:edit',
  FILE_EXCLUSIION_RULES__DELETE: 'file-exclusion-rules:delete',
  USERS__ADD: 'users:add',
};

export const rbacRules = {
  superAdmin: {
    static: [
      ruleTypes.PROJECT_INFO__EDIT,
      ruleTypes.ATTRIBUTES__ADD,
      ruleTypes.ATTRIBUTES__EDIT,
      ruleTypes.ATTRIBUTES__DELETE,
      ruleTypes.DYNAMIC_ATTRIBUTES__ADD,
      ruleTypes.DYNAMIC_ATTRIBUTES__EDIT,
      ruleTypes.PROJECT_FLAGS__ADD,
      ruleTypes.PROJECT_FLAGS__EDIT,
      ruleTypes.PROJECT_FLAGS__DELETE,
      ruleTypes.PROJECT_LINKS__EDIT,
      ruleTypes.FILE_EXCLUSIION_RULES__ADD,
      ruleTypes.FILE_EXCLUSIION_RULES__EDIT,
      ruleTypes.FILE_EXCLUSIION_RULES__DELETE,
      ruleTypes.USERS__ADD,
    ],
  },
  admin: {
    static: [
      ruleTypes.PROJECT_INFO__EDIT,
      ruleTypes.DYNAMIC_ATTRIBUTES__ADD,
      ruleTypes.DYNAMIC_ATTRIBUTES__EDIT,
      ruleTypes.PROJECT_LINKS__EDIT,
      ruleTypes.FILE_EXCLUSIION_RULES__ADD,
      ruleTypes.FILE_EXCLUSIION_RULES__EDIT,
      ruleTypes.FILE_EXCLUSIION_RULES__DELETE,
    ],
  },
};
