import { toast } from 'react-toastify';

import actionTypes from '../constants/actionTypes';
import fileExclusionRulesService from '../services/file-exclusion-rules';

function _getRulesStart() {
  return {
    type: actionTypes.GET_FILE_EXCLUSION_RULES_START,
  };
}

function _getRulesSuccess(fileExclusionRules) {
  return {
    type: actionTypes.GET_FILE_EXCLUSION_RULES_SUCCESS,
    payload: { fileExclusionRules },
  };
}

function _getRulesFailure(error) {
  return {
    type: actionTypes.GET_FILE_EXCLUSION_RULES_FAILURE,
    error: true,
    payload: error,
  };
}

export function getFileExclusionRules() {
  return function (dispatch) {
    dispatch(_getRulesStart());

    return fileExclusionRulesService
      .getFileExclusionRules()
      .then(fileExclusionRules => {
        dispatch(_getRulesSuccess(fileExclusionRules));
      })
      .catch(error => {
        toast.error(error.message);
        dispatch(_getRulesFailure(error));
      });
  };
}
