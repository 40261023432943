import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';

import XIcon from '../../base/icons/XIcon';
import Modal from '../../base/Modal';
import { ModalFooter } from '../../base/styled';
import TextInput from '../../base/TextInput';
import { CenteredContent } from '../styles';

const parentRoute = '/admin/file-exclusion-rules';

/**
 * A form for file exclusion rule
 * @param {*} props
 */
export const FileExclusionForm = props => {
  const [errors, setErrors] = useState({});
  const [term, setTerm] = useState(props.initialValues.term);
  const [usersToBan, setUsersToBan] = useState(props.initialValues.usersToBan);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedUsers, setSearchedUsers] = useState([]);

  useEffect(() => {
    if (props.initialValues.term) {
      setTerm(props.initialValues.term);
    }
  }, [props.initialValues.term]);

  useEffect(() => {
    if (props.initialValues.usersToBan) {
      setUsersToBan(props.initialValues.usersToBan);
    }
  }, [props.initialValues.usersToBan]);

  const userClickedSearch = e => {
    e.preventDefault();
    if (searchTerm) {
      const regex = new RegExp(searchTerm, 'gi');
      const foundUsers = props.userOptions.filter(user => regex.test(user.email));
      setSearchedUsers(foundUsers);
    } else {
      setSearchedUsers([]);
    }
  };

  const userChoseUser = (e, user) => {
    e.preventDefault();
    const toBan = { ...usersToBan };
    toBan[user.boxId] = user;
    setUsersToBan(toBan);
  };

  const userRemovedUser = (e, user) => {
    e.preventDefault();
    const toBan = { ...usersToBan };
    delete toBan[user.boxId];
    setUsersToBan(toBan);
  };

  const validate = e => {
    e.preventDefault();

    const errs = {};
    if (!term) errs.term = 'Term is required';
    if (term.length > 50) errs.term = 'Term must not exceed 50 characters';
    if (_.isEmpty(usersToBan)) errs.usersToBan = 'Please select users to ban';
    setErrors(errs);

    if (_.isEmpty(errs)) {
      setErrors({});
      props.handleSubmit({ term, usersToBan });
    }
  };

  const canSubmit = term && !_.isEmpty(usersToBan);

  return (
    <Modal>
      <Island className="c-island">
        <Link to={parentRoute} className="c-island__exit">
          <XIcon />
        </Link>
        <IslandHeader className="c-island__header">
          <IslandTitle id="invite-title">{props.title}</IslandTitle>
          <IslandSubtitle id="invite-subtitle">{props.subtitle}</IslandSubtitle>
        </IslandHeader>
        <IslandBody className="c-island__body">
          <Form className="c-form c-form--with-sticky-footer" onSubmit={validate}>
            <Well className="c-well">
              <TextInput
                label={'Term'}
                disabled={props.loading}
                meta={{}}
                input={{
                  value: term,
                  onChange: e => setTerm(e.target.value),
                }}
              />
              {errors.term && <Error>{errors.term}</Error>}
              <TextField>
                <TextInput
                  label={'Search for users to ban by email'}
                  disabled={props.loading}
                  style={{ width: '100%' }}
                  meta={{}}
                  input={{
                    value: searchTerm,
                    onChange: e => setSearchTerm(e.target.value),
                  }}
                />
                <Button
                  className="c-button c-button--small c-button--block"
                  onClick={userClickedSearch}>
                  Search
                </Button>
              </TextField>
              {!!searchedUsers.length && (
                <UsersFound>{searchedUsers.length} users found</UsersFound>
              )}
              <Users>
                {searchedUsers.map(user => (
                  <UserItem key={user.email}>
                    <UserTitle>
                      {user.name} | ${user.email}
                    </UserTitle>
                    <UserButton
                      className="c-button c-button--small c-button--hollow"
                      onClick={e => userChoseUser(e, user)}>
                      Choose
                    </UserButton>
                  </UserItem>
                ))}
              </Users>
              <UsersToBan>Selected Users to Ban</UsersToBan>
              {_.isEmpty(usersToBan) && <div>None selected</div>}
              <Users>
                {Object.entries(usersToBan).map(([boxId, user]) => (
                  <UserItem key={user.email}>
                    <UserTitle>
                      {user.name} | {user.email}
                    </UserTitle>
                    <UserButton
                      className="c-button c-button--small c-button--hollow"
                      onClick={e => userRemovedUser(e, user)}>
                      Remove
                    </UserButton>
                  </UserItem>
                ))}
              </Users>
              {errors.usersToBan && <Error>{errors.usersToBan}</Error>}
            </Well>
            <ModalFooter className="c-button-row c-button-row--modal">
              <Link to={parentRoute} className="c-button c-button--small c-button--hollow">
                Cancel
              </Link>
              <FooterButton
                className="c-button c-button--small"
                type="submit"
                disabled={!canSubmit}>
                {props.loading ? (
                  <CenteredContent>
                    <BeatLoader color="#fff" size={8} />
                  </CenteredContent>
                ) : (
                  props.submitText
                )}
              </FooterButton>
            </ModalFooter>
          </Form>
        </IslandBody>
      </Island>
    </Modal>
  );
};

FileExclusionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitText: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userOptions: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
};

const Island = styled.div``;

const IslandHeader = styled.div``;

const IslandTitle = styled.h3``;

const IslandSubtitle = styled.span``;

const Form = styled.form``;

const IslandBody = styled.div``;

const Well = styled.div``;

const FooterButton = styled.button``;

const Error = styled.div`
  margin-top: -8px;
  color: red;
`;

const TextField = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Button = styled.button`
  margin-bottom: 1rem;
  margin-left: 4px;
  outline: none;
  height: 54px;
  width: unset;
  padding: 0 16px;
`;

const Users = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 1rem;

  & > :nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  & > * {
    padding: 8px;
  }
`;

const UserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
`;

const UserButton = styled.button`
  outline: none;
  margin-left: 8px;
  width: unset;
  background-color: unset;
  padding: 8px;
`;

const UsersFound = styled.div`
  margin-bottom: 0.5rem;
`;

const UsersToBan = styled.div`
  font-size: 16px;
  margin-bottom: 0.5rem;
`;
