import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  formatLastUpdated
} from '../../utils/dates';
import ProtectedImage from './ProtectedImage';

const propTypes = {
  children: PropTypes.node,
  imageUrl: PropTypes.string,
  item: ImmutablePropTypes.contains({
    boxId: PropTypes.string.isRequired,
    lastUpdated: PropTypes.string.isRequired
  }).isRequired
};

function Card({
  children,
  imageUrl,
  item
}) {
  return (
    <div>
      <ProtectedImage
        className="c-card__image"
        url={imageUrl}
      />
      <div className="c-metadata c-metadata--centered">
        {children}
        <p className="c-metadata__last-updated">
          {'Last Updated: '}
          <strong className="c-metadata__date">
            {formatLastUpdated(item.get('lastUpdated'))}
          </strong>
        </p>
      </div>
    </div>
  );
}

Card.propTypes = propTypes;

export default Card;
