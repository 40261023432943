import Immutable from 'immutable';
import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = new Immutable.Map({
  isActive: false,
  users: new Immutable.List()
});

function users(state = INITIAL_STATE, action) {
  switch (action.type) {
  case actionTypes.CLEAR_USERS:
  case actionTypes.SIGN_IN_START:
  case actionTypes.SIGN_OUT:
    return INITIAL_STATE;

  case actionTypes.GET_USERS_START:
    return state.withMutations((map) => {
      map.delete('error');
      map.set('isActive', true);
    });

  case actionTypes.GET_USERS_SUCCESS: {
    return state.withMutations((map) => {
      map.set('isActive', false);
      map.set('users', new Immutable.List(Immutable.fromJS(action.payload.users)));
    });
  }

  case actionTypes.GET_USERS_FAILURE:
    return state.withMutations((map) => {
      map.set('error', action.payload);
      map.set('isActive', false);
    });

  default:
    return state;
  }
}

export default users;
