import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { hoodLogo } from '../../../assets/images';

const propTypes = {
  breadcrumb: PropTypes.node,
  isAuthenticated: PropTypes.bool,
};

function Breadcrumbs({ breadcrumb, isAuthenticated }) {
  const hasChildBreadcrumb = !isAuthenticated || breadcrumb;

  const breadcrumbClasses = classNames({
    'c-header-breadcrumbs': true,
    'c-header-breadcrumbs--unauthenticated': !isAuthenticated,
    'c-header-breadcrumbs--with-child': hasChildBreadcrumb,
  });

  return (
    <div className={breadcrumbClasses}>
      <Link to="/" className="c-header-breadcrumbs__logo">
        <Logo src={hoodLogo} alt="Hood Platform" />
      </Link>

      {isAuthenticated ? breadcrumb : null}
    </div>
  );
}

Breadcrumbs.propTypes = propTypes;

const Logo = styled.img`
  max-height: 2.5rem; // header height is 3.75rem
  object-fit: contain;
`;

export default Breadcrumbs;
