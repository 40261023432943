import moment from 'moment';

function formatLastUpdated(date) {
  return moment(date).format('MMM DD YYYY');
}

/**
 * Compares if two dates are the same
 * @param jsonDateA - date in JSON format
 * @param jsonDateB - date in JSON format
 * @returns {boolean}
 */
function checkIfSame(jsonDateA, jsonDateB) {
  const dateA = new Date(jsonDateA);
  const dateB = new Date(jsonDateB);
  return dateA.toDateString() === dateB.toDateString();
}

export { formatLastUpdated, checkIfSame };
