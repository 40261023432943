import { getRequestError } from '../utils/errorHelpers';
import apiService from './api';

const service = 'file exclusion rule';

const getFileExclusionRules = () => {
  return apiService.get('/file-exclusion-rules').then(({ data }) => data.fileExclusionRules);
};

const addFileExclusionRule = ({ term, bannedUserIds }) => {
  return apiService
    .post('/file-exclusion-rules', { term, bannedUserIds })
    .then(({ data }) => data.fileExclusionRule)
    .catch(error => {
      const err = getRequestError({ error, type: 'add', service });
      throw new Error(err);
    });
};

const deleteFileExclusionRule = id => {
  return apiService.delete(`/file-exclusion-rules/${id}`).catch(error => {
    const err = getRequestError({ error, type: 'delete', service });
    throw new Error(err);
  });
};

const updateFileExclusionRule = (id, { term, bannedUserIds }) => {
  return apiService.patch(`/file-exclusion-rules/${id}`, { term, bannedUserIds }).catch(error => {
    const err = getRequestError({ error, type: 'update', service });
    throw new Error(err);
  });
};

const getSingleFileExclusionRule = id => {
  return apiService
    .get(`/file-exclusion-rules/${id}`)
    .then(({ data }) => data.fileExclusionRule)
    .catch(error => {
      const err = getRequestError({ error, type: 'get', service });
      throw new Error(err);
    });
};

export default {
  getFileExclusionRules,
  addFileExclusionRule,
  deleteFileExclusionRule,
  updateFileExclusionRule,
  getSingleFileExclusionRule,
};
