import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonGroup = styled.div`
  margin-top: 8px;
  & > :first-child {
    margin-right: 4px;
  }
`;

export const Button = styled.button`
  width: unset;
  padding: ${({ padding }) => padding || 12}px;
`;

export const HollowButtonText = styled.div`
  padding: 1px 0;
`;

export const FormErrors = styled.div`
  margin-top: 8px;
  color: #e81123;
`;
