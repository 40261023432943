import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SketchPicker as Picker } from 'react-color';

const presetColors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
];

export const ColorPicker = props => {
  const [isOpen, setIsOpen] = useState(false);

  function togglePicker() {
    setIsOpen(!isOpen);
  }

  return (
    <Container style={props.style}>
      {isOpen && (
        <Popover>
          <Cover onClick={togglePicker} />
          <Picker
            color={props.value}
            onChangeComplete={props.handleChangeComplete}
            presetColors={presetColors}
          />
        </Popover>
      )}
      <ButtonContainer>
        <Button disabled={props.disabled} value={props.value} onClick={togglePicker} />
      </ButtonContainer>
    </Container>
  );
};

ColorPicker.propTypes = {
  style: PropTypes.object,
  value: PropTypes.string.isRequired,
  handleChangeComplete: PropTypes.func,
  disabled: PropTypes.bool,
};

const Container = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  height: 54px;
  min-width: 76px;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  box-shadow: inset 0 0 0.375rem 0 rgba(134, 178, 194, 0.5);
  padding: 8px;
`;

const Button = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: ${({ value }) => value};
  border-radius: 2px;
`;

const Popover = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: ${54 + 8}px;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
